export const craneTableheader = [
  { name: "Empresa", value: "name", sortable: false },
  { name: "Nombre de contacto", value: "contact", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Identificación", value: "identification", sortable: false },
  { name: "Estado", value: "state", sortable: false },
  { name: "Estatus", value: "status", sortable: false },
  { name: "Acción", value: "table-action", sortable: false },
];

export const saleUsersTableheader = [
  { name: "Nombres", value: "name", sortable: false },
  { name: "Email", value: "contact", sortable: false },
  { name: "Perfil", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Canal de Venta", value: "contact", sortable: false },
  { name: "Status", value: "state", sortable: false },
  { name: "Creado", value: "status", sortable: false },
  { name: "Modificado", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const saleChannelTableheader = [
  { name: "Nombre", value: "name", sortable: false },
  { name: "Correlativo de trazabilidad", value: "name", sortable: false },
  { name: "F2 Link", value: "name", sortable: false },
  { name: "F4 Link", value: "name", sortable: false },
  { name: "Retail Link", value: "name", sortable: false },
  { name: "QR F2", value: "name", sortable: false },
  { name: "QR", value: "name", sortable: false },
  { name: "QR PlusF4", value: "name", sortable: false },
  { name: "Status", value: "contact", sortable: false },
];

export const salePricesTableheader = [
  { name: "", value: "name", sortable: false },
  { name: "Classic", value: "name", sortable: false },
  { name: "Plus", value: "name", sortable: false },
  { name: "Gold", value: "name", sortable: false },
  { name: "Motorider", value: "name", sortable: false },
];

export const paymentsTableHeader = [
  { name: "Tipo", value: "name", sortable: false },
  { name: "Monto USD", value: "name", sortable: false },
  { name: "Monto BsS", value: "name", sortable: false },
  { name: "Metodo", value: "name", sortable: false },
  { name: "Estatus de pago", value: "name", sortable: false },
  { name: "Referencia", value: "name", sortable: false },
  { name: "Titular de la cuenta", value: "name", sortable: false },
  { name: "Ver comprobante", value: "name", sortable: false },
  { name: "Fecha de Pago", value: "name", sortable: false },
  { name: "Accion", value: "name", sortable: false },
];

export const casheaPaymentsTableHeader = [
  { name: "Tipo", value: "name", sortable: false },
  { name: "Monto USD", value: "name", sortable: false },
  { name: "Monto BsS", value: "name", sortable: false },
  { name: "Metodo", value: "name", sortable: false },
  { name: "Estatus de pago", value: "name", sortable: false },
  { name: "Referencia", value: "name", sortable: false },
  { name: "Titular de la cuenta", value: "name", sortable: false },
  { name: "Referencia de Cashea", value: "name", sortable: false },
  { name: "Ver comprobante", value: "name", sortable: false },
  { name: "Fecha de Pago", value: "name", sortable: false },
  { name: "Accion", value: "name", sortable: false },
];

export const ChangeTableHeader = [
  { name: "Tipo", value: "name", sortable: false },
  { name: "Monto USD", value: "name", sortable: false },
  { name: "Monto BsS", value: "name", sortable: false },
  { name: "Metodo", value: "name", sortable: false },
  { name: "Referencia", value: "name", sortable: false },
  { name: "Ver comprobante", value: "name", sortable: false },
  { name: "Fecha de Pago", value: "name", sortable: false },
  { name: "Accion", value: "name", sortable: false },
];

export const pendingPaymentsTableHeader = [
  { name: "Tipo", value: "name", sortable: false },
  { name: "Monto USD", value: "name", sortable: false },
  { name: "Monto BsS", value: "name", sortable: false },
  { name: "Estatus de pago", value: "name", sortable: false },
  { name: "Fecha de Corte", value: "name", sortable: false },
  { name: "Accion", value: "name", sortable: false },
];

export const fleetsTableheader = [
  { name: "Transporte", value: "name", sortable: false },
  { name: "Modelo", value: "contact", sortable: false },
  { name: "Marca", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Placa", value: "state", sortable: false },
  { name: "Año", value: "status", sortable: false },
  { name: "Fecha de Compra", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];
export const searchInternalProviderTableHeader = [
  { name: "", value: "plus", sortable: false },
  { name: "Nombre", value: "name", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Identificación", value: "contact", sortable: false },
  { name: "Estado", value: "state", sortable: false },
  { name: "Estatus", value: "status", sortable: false },
  { name: "Acción", value: "", sortable: false },
];
export const internalProviderTableHeader = [
  { name: "Nombre", value: "name", sortable: false },
  { name: "Tipo", value: "type", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Identificación", value: "contact", sortable: false },
  { name: "Estado", value: "state", sortable: false },
  { name: "Estatus", value: "status", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const vehicularAssetsTableHeader = [
  { name: "Transporte", value: "name", sortable: false },
  { name: "Marca", value: "contact", sortable: false },
  { name: "Modelo", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Color", value: "color", sortable: false },
  { name: "Placa", value: "state", sortable: false },
  { name: "Año", value: "status", sortable: false },
  { name: "Fecha de Compra", value: "status", sortable: false },
  { name: "Descripción", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const providersTableheader = [
  { name: "", value: "", sortable: false },
  { name: "Empresa", value: "name", sortable: false },
  { name: "Nombre de contacto", value: "contact", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false },
  { name: "Identificación", value: "identification", sortable: false },
  { name: "Estado", value: "region", sortable: false },
  { name: "Estatus", value: "status", sortable: false },
];

export const serviceHistoryTableheader = [
  { name: "Fecha", value: "date", sortable: false },
  { name: "Averia", value: "fault", sortable: false },
  { name: "Servicio", value: "", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Km", value: "KMEfectivo", sortable: false },
];

export const paymentPlanTableHeader = [
  { name: "Nro. pago", value: "date", sortable: false },
  { name: "Fecha", value: "fault", sortable: false },
  { name: "Monto (USD)", value: "", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Cumplimiento", value: "KMEfectivo", sortable: false },
  { name: "", value: "KMEfectivo", sortable: false },
];

export const exportanblesTableheader = [
  { name: "Operador", value: "name", sortable: false },
  { name: "Nro. de caso", value: "contact", sortable: false },
  { name: "Fecha de creación", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Hora de creación", value: "status", sortable: false },
  { name: "Estatus", value: "state", sortable: false },
  { name: "Nombre", value: "", sortable: false },
  { name: "Apellido", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Modelo", value: "", sortable: false },
  { name: "Año", value: "", sortable: false },
  { name: "Plan", value: "", sortable: false },
  { name: "Estatus de Afiliación", value: "", sortable: false },
  { name: "Tipo de gestión", value: "", sortable: false },
  { name: "Avería", value: "", sortable: false },
  { name: "Avería Real", value: "", sortable: false },
  { name: "Estado origen", value: "", sortable: false },
  { name: "Ciudad origen", value: "", sortable: false },
  { name: "Estado destino", value: "", sortable: false },
  { name: "Ciudad destino", value: "", sortable: false },
];

export const imageHeader = [
  {
    name: `Imagen AV`,
    value: "",
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 1`,
    value: "",
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 2`,
    value: "",
    sortable: false,
    hidePrint: true,
  },
  {
    name: `Imagen grúa 3`,
    value: "",
    sortable: false,
    hidePrint: true,
  },
];

export const stageTimeHeader = [
  {
    name: `Tiempo AV Asignado`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo AV en vía`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo AV en origen`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo en búsqueda grúa`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo grúa asginada`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo grúa en vía`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo en grúa en origen`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo cliente vía destino`,
    value: "",
    sortable: false,
  },
  {
    name: `Tiempo total gestión`,
    value: "",
    sortable: false,
  },
];

export const AVPaymentHeader = [
  { name: "Chofer", value: "name", sortable: false },
  { name: "Fecha de servicio", value: "contact", sortable: false },
  { name: "Servicio Prestado", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Tipo de gestión", value: "state", sortable: false },
  { name: "Código de servicio", value: "status", sortable: false },
  { name: "Placa", value: "state", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Estado origen", value: "", sortable: false },
  { name: "Ciudad origen", value: "", sortable: false },
  { name: "Costo del servicio", value: "", sortable: false },
  { name: "Monto pagado al chofer", value: "", sortable: false },
];

export const internalTowPaymentHeader = [
  { name: "Chofer", value: "name", sortable: false },
  { name: "Fecha de servicio", value: "contact", sortable: false },
  { name: "Servicio Prestado", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Tipo de gestión", value: "state", sortable: false },
  { name: "Código de servicio", value: "status", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Estado origen", value: "", sortable: false },
  { name: "Ciudad origen", value: "", sortable: false },
  { name: "Costo del servicio", value: "", sortable: false },
  { name: "Monto pagado al chofer", value: "", sortable: false },
];

export const externalTowPaymentHeader = [
  { name: "Chofer", value: "name", sortable: false },
  { name: "Fecha de servicio", value: "contact", sortable: false },
  { name: "Servicio Prestado", value: "phone", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Tipo de gestión", value: "state", sortable: false },
  { name: "Código de servicio", value: "status", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Estado origen", value: "", sortable: false },
  { name: "Ciudad origen", value: "", sortable: false },
  { name: "Costo del servicio", value: "", sortable: false },
  { name: "Monto pagado al chofer", value: "", sortable: false },
];

export const fleetloadTableHeader = [
  { name: "ID", value: "name", sortable: true },
  { name: "Nombre", value: "contact", sortable: true },
  { name: "Código", value: "phone", sortable: true }, //   { name: 'Región', value: 'region' },
  { name: "Status", value: "state", sortable: true },
  { name: "Cargas", value: "status", sortable: true },
  { name: "Creado", value: "", sortable: false },
  { name: "Modificado", value: "", sortable: false },
  { name: "Acciones", value: "state", sortable: false },
];

export const fleetloadCargoTableHeader = [
  { name: "Nro. Flota", value: "name", sortable: true },
  { name: "Tipo de flota", value: "contact", sortable: true },
  { name: "Modo de aplicación", value: "phone", sortable: true }, //   { name: 'Región', value: 'region' },
  { name: "Valor de la flota", value: "state", sortable: true },
  { name: "Tipo de pago", value: "status", sortable: true },
  { name: "Fecha inicio", value: "", sortable: false },
  { name: "Fecha fin", value: "", sortable: false },
  { name: "Teléfono", value: "state", sortable: false },
  { name: "Cantidad de vehículos", value: "state", sortable: false },
  { name: "Status", value: "state", sortable: false },
  { name: "", value: "state", sortable: false },
];

export const vehicleChangeHistoryHeader = [
  { name: "Fecha del cambio", sortable: true },
  { name: "Asesor Responsable", sortable: true },
  { name: "Placa Vehiculo Anterior", sortable: true }, //   { name: 'Región', value: 'region' },
  { name: "Marca Vehiculo Anterior", sortable: true },
  { name: "Modelo Vehiculo Anterior", sortable: true },
  {
    name: "Serial carroceria Vehiculo Anterior",
    sortable: false,
  },
  { name: "Color Vehiculo Anterior", sortable: true },
];

export const customerChangeHistoryHeader = [
  { name: "Fecha del cambio", sortable: true },
  { name: "Asesor Responsable", sortable: true },
  { name: "Cliente Anterior", sortable: true }, //   { name: 'Región', value: 'region' },
  { name: "CI/RIF Cliente Anterior", sortable: true },
  { name: "Correo Cliente Anterior", sortable: true },
  { name: "Telefono Cliente Anterior", sortable: false },
];

export const fleetloadCargoDetailTableHeader = [
  { name: "Linea", value: "name", sortable: true },
  { name: "Tipo", value: "contact", sortable: true },
  { name: "Nac", value: "phone", sortable: true }, //   { name: 'Región', value: 'region' },
  { name: "Cédula", value: "state", sortable: true },
  { name: "Nombre", value: "status", sortable: true },
  { name: "Apellido", value: "", sortable: false },
  { name: "Prefijo", value: "", sortable: false },
  { name: "Teléfono", value: "state", sortable: false },
  { name: "Fec. Nac", value: "state", sortable: false },
  { name: "Sexo", value: "state", sortable: false },
  { name: "Estado Cobertura", value: "state", sortable: false },
];

export const fleetloadCargoDetailMockup = [
  {
    name1: "1",
    name2: "Renovación",
    name3: "V",
    name4: "23.123.456",
    name5: "Jose",
    name6: "Perez",
    name7: "212",
    name8: "3124421",
    name9: "N/A",
    name10: "N/A",
    name11: "Miranda",
  },
  {
    name1: "1",
    name2: "Renovación",
    name3: "V",
    name4: "23.123.456",
    name5: "Jose",
    name6: "Perez",
    name7: "212",
    name8: "3124421",
    name9: "N/A",
    name10: "N/A",
    name11: "Miranda",
  },
  {
    name1: "1",
    name2: "Renovación",
    name3: "V",
    name4: "23.123.456",
    name5: "Jose",
    name6: "Perez",
    name7: "212",
    name8: "3124421",
    name9: "N/A",
    name10: "N/A",
    name11: "Miranda",
  },
  {
    name1: "1",
    name2: "Renovación",
    name3: "V",
    name4: "23.123.456",
    name5: "Jose",
    name6: "Perez",
    name7: "212",
    name8: "3124421",
    name9: "N/A",
    name10: "N/A",
    name11: "Miranda",
  },
];

export const historyPaymentTableHeader = [
  { name: "Nro. pago", value: "name", sortable: false },
  { name: "Fecha", value: "contact", sortable: false },
  { name: "Monto (USD)", value: "phone", sortable: false },
  { name: "Cumplimiento", value: "state", sortable: false },
];
export const historyPaymentMockup = [
  {
    name1: "1",
    name2: "31/03/2023",
    name3: "100",
    name4: "Pago realizado",
  },
  {
    name1: "1",
    name2: "31/03/2023",
    name3: "100",
    name4: "Pago realizado",
  },
  {
    name1: "1",
    name2: "31/03/2023",
    name3: "100",
    name4: "Pago realizado",
  },
  {
    name1: "1",
    name2: "31/03/2023",
    name3: "100",
    name4: "Pago realizado",
  },
  {
    name1: "1",
    name2: "31/03/2023",
    name3: "100",
    name4: "Pago realizado",
  },
];

export const exportableMockup = [
  {
    operator: "Manuel Hernandez",
    time: "3:20pm",
    date: "12/03/2023",
    status: "ACTIVO",
    caseId: "123467789",
    name: "Luis",
    lastName: "Perez",
  },
  {
    operator: "Manuel Hernandez",
    time: "3:20pm",
    date: "12/03/2023",
    status: "ACTIVO",
    caseId: "123467789",
    name: "Luis",
    lastName: "Perez",
  },
  {
    operator: "Manuel Hernandez",
    time: "3:20pm",
    date: "12/03/2023",
    status: "ACTIVO",
    caseId: "123467789",
    name: "Luis",
    lastName: "Perez",
  },
  {
    operator: "Manuel Hernandez",
    time: "3:20pm",
    date: "12/03/2023",
    status: "ACTIVO",
    caseId: "123467789",
    name: "Luis",
    lastName: "Perez",
  },
];

export const serviceHistoryMockup = [
  {
    date: "XXXXXXXXXXXXX",
    fault: "XXXXXXXXXXXXX",
    service: "XXXXXXXXXXXXX",
    observations: "XXXXXXXXXXXXX",
    KMEfectivo: "XXXXXXXXXXXXX",
  },
  {
    date: "XXXXXXXXXXXXX",
    fault: "XXXXXXXXXXXXX",
    service: "XXXXXXXXXXXXX",
    observations: "XXXXXXXXXXXXX",
    KMEfectivo: "XXXXXXXXXXXXX",
  },
  {
    date: "XXXXXXXXXXXXX",
    fault: "XXXXXXXXXXXXX",
    service: "XXXXXXXXXXXXX",
    observations: "XXXXXXXXXXXXX",
    KMEfectivo: "XXXXXXXXXXXXX",
  },
];

export const caseFollowingheader = [
  { name: "Fecha", value: "date", sortable: false },
  { name: "Hora", value: "fault", sortable: false },
  { name: "Status", value: "", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Tipo de seguimiento", value: "observations", sortable: false },
  { name: "Mensaje", value: "KMEfectivo", sortable: false },
  { name: "Observaciones", value: "KMEfectivo", sortable: false },
];

export const scaseFollowingMockup = [
  {
    date: "04/03/2023",
    time: "09:33:01 A.M.",
    status: "En espera de AV",
    type: "Lllamada",
    mesage: "En vía",
    observations: "N/A",
  },
];

export const trazeHeader = [
  { name: "Fecha", value: "date", sortable: false },
  { name: "Hora", value: "fault", sortable: false },
  { name: "Status", value: "", sortable: false }, //   { name: 'Región', value: 'region' },
  { name: "Duración", value: "observations", sortable: false },
];

export const trazeMockup = [
  {
    date: "04/03/2023",
    time: "10:45:01 A.M.",
    statusOrigin: "",
    statusDestiny: "en espera AV",
    estimatedTime: "min",
    observations: "N/A",
  },
  {
    date: "13/03/23",
    time: "07:36:01 A.M.",
    statusOrigin: "",
    statusDestiny: "en espera AV",
    estimatedTime: "min",
    observations: "N/A",
  },
];

export const bikeTableheader = [
  { name: "Nombre", value: "name", sortable: false },
  { name: "Cédula", value: "cedula", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false },
  { name: "Moto asignada (placa)", value: "plate", sortable: false },
  { name: "Estado", value: "state", sortable: false },
  { name: "Fecha de creación", value: "date", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const bikeProvidersMockup = [
  {
    name: "Jose Luis Perez Rodriguez",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
];

export const salesMembershipTableHeader = [
  { name: "Acción", value: "", sortable: false, hidePrint: true },
  { name: "Membresia", value: "name", sortable: false },
  { name: "Fecha Creación", value: "name", sortable: false },
  { name: "Asesor responsable", value: "", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Plan", value: "state", sortable: false },
  { name: "Tipo de venta", value: "", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Cédula", value: "", sortable: false },
  { name: "Género", value: "", sortable: false },
  { name: "Teléfono", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Clase", value: "", sortable: false },
  { name: "Año Vehículo", value: "", sortable: false },
  { name: "Código aplicado", value: "", sortable: false },
  { name: "Modalidad de pago", value: "", sortable: false },
  { name: "Tipo de pago", value: "", sortable: false },
  { name: "RCV", value: "", sortable: false },
  { name: "Facturación de planes $", value: "", sortable: false },
  { name: "Facturación de RCV $", value: "", sortable: false },
  { name: "Monto Ingresado $", value: "", sortable: false },
  { name: "Monto Ingresado Bs", value: "", sortable: false },

  { name: "Método de Pago 1", value: "", sortable: false },
  { name: "Monto Pago 1 $", value: "", sortable: false },
  { name: "Monto Recibido $", value: "", sortable: false },
  { name: "Monto Pago 1 BsS", value: "", sortable: false },
  { name: "Referencia de Pago 1", value: "", sortable: false },
  { name: "Titular de la cuenta 1", value: "", sortable: false },
  { name: "Método de Pago 2", value: "", sortable: false },
  { name: "Monto Pago 2 $", value: "", sortable: false },
  { name: "Monto Pago 2 BsS", value: "", sortable: false },
  { name: "Referencia de Pago 2", value: "", sortable: false },
  { name: "Titular de la cuenta 2", value: "", sortable: false },
  { name: "Vuelto Venta USD", value: "", sortable: false },
  { name: "Vuelto 1 USD", value: "", sortable: false },
  { name: "Método Vuelto 1", value: "", sortable: false },
  { name: "Referencia Vuelto 1", value: "", sortable: false },
  { name: "Vuelto 2 USD", value: "", sortable: false },
  { name: "Método Vuelto 2", value: "", sortable: false },
  { name: "Referencia Vuelto 2", value: "", sortable: false },

  { name: "Vuelto 3 USD", value: "", sortable: false },
  { name: "Método Vuelto 3", value: "", sortable: false },
  { name: "Referencia Vuelto 3", value: "", sortable: false },

  // Pagos financiados
  { name: "Referencia de Cashea", value: "", sortable: false },
  { name: "Pago Inicial Fecha de pago", value: "", sortable: false },

  { name: "Pago Inicial Monto pagado 1 USD", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Pago Inicial Método 1", value: "", sortable: false },
  { name: "Pago Inicial Referencia 1", value: "", sortable: false },
  { name: "Pago Inicial Titular de la cuenta 1", value: "", sortable: false },

  { name: "Pago Inicial Monto pagado 2 USD", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Pago Inicial Método 2", value: "", sortable: false },
  { name: "Pago Inicial Referencia 2", value: "", sortable: false },
  { name: "Pago Inicial Titular de la cuenta 2", value: "", sortable: false },

  { name: "Fecha de vencimiento Cuota 1", value: "", sortable: false },
  { name: "Fecha de pago Cuota 1", value: "", sortable: false },

  { name: "Cuota 1 Monto pagado 1 USD", value: "", sortable: false },
  { name: "Cuota 1 Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Cuota 1 Método 1", value: "", sortable: false },
  { name: "Cuota 1 Referencia 1", value: "", sortable: false },
  { name: "Cuota 1 Titular de la cuenta 1", value: "", sortable: false },

  { name: "Cuota 1 Monto pagado 2 USD", value: "", sortable: false },
  { name: "Cuota 1 Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Cuota 1 Método 2", value: "", sortable: false },
  { name: "Cuota 1 Referencia 2", value: "", sortable: false },
  { name: "Cuota 1 Titular de la cuenta 2", value: "", sortable: false },

  // Cashea
  { name: "Fecha vencimiento Cuota 2", value: "", sortable: false },
  { name: "Fecha de pago Cuota 2", value: "", sortable: false },
  { name: "Cuota 2 Monto pagado USD", value: "", sortable: false },
  { name: "Cuota 2 Monto pagado Bs", value: "", sortable: false },
  { name: "Cuota 2 Método", value: "", sortable: false },
  { name: "Cuota 2 Referencia", value: "", sortable: false },
  { name: "Cuota 2 Titular de la cuenta 1", value: "", sortable: false },

  { name: "Fecha vencimiento Cuota 3", value: "", sortable: false },
  { name: "Fecha de pago Cuota 3", value: "", sortable: false },
  { name: "Cuota 3 Monto pagado USD", value: "", sortable: false },
  { name: "Cuota 3 Monto pagado Bs", value: "", sortable: false },
  { name: "Cuota 3 Método", value: "", sortable: false },
  { name: "Cuota 3 Referencia", value: "", sortable: false },
  { name: "Cuota 3 Titular de la cuenta 1", value: "", sortable: false },
  { name: "Cuenta por cobrar", value: "", sortable: false },
  { name: "Estatus cuenta por cobrar", value: "", sortable: false },
  { name: "Estado", value: "", sortable: false },
  { name: "Ciudad", value: "", sortable: false },
  { name: "Contrato", value: "", sortable: false },
  { name: "Correo", value: "", sortable: false },
  { name: "Fecha Nacimiento", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Modelo", value: "", sortable: false },
  { name: "Asesor asignado", value: "", sortable: false },
  { name: "Renovaciones continuas", value: "", sortable: false },
  { name: "Fecha inicio RCV", value: "", sortable: false },
  { name: "Fecha fin RCV", value: "", sortable: false },
  { name: "Fecha inicio plan", value: "", sortable: false },
  { name: "Fecha fin plan", value: "", sortable: false },
  { name: "Nivel siniestralidad", value: "", sortable: false },
  { name: "Condición Afiliación", value: "phone", sortable: false },
  { name: "Upgrade", value: "state", sortable: false },
  { name: "Pago Upgrade $", value: "state", sortable: false },
  { name: "Pago Upgrade Bs", value: "state", sortable: false },
  // { name: 'Titular de la cuenta Upgrade', value: 'state', sortable: false },
  { name: "Monto Pago 1 RCV", value: "state", sortable: false },
  { name: "Monto Pago 1 Bs RCV", value: "state", sortable: false },
  { name: "Método Pago 1 RCV", value: "state", sortable: false },
  { name: "Referencia Pago 1 RCV", value: "state", sortable: false },
  { name: "Titular de la cuenta Pago 1 RCV", value: "state", sortable: false },
  { name: "Fecha Pago 1 RCV", value: "state", sortable: false },
  { name: "Monto Pago 2 RCV", value: "state", sortable: false },
  { name: "Monto Pago 2 Bs RCV", value: "state", sortable: false },
  { name: "Método Pago 2 RCV", value: "state", sortable: false },
  { name: "Referencia Pago 2 RCV", value: "state", sortable: false },
  { name: "Titular de la cuenta Pago 2 RCV", value: "state", sortable: false },
  { name: "Fecha Pago 2 RCV", value: "state", sortable: false },

  { name: "Empresa", value: "", sortable: false },
  { name: "Acción de Campaña", value: "", sortable: false },
];

export const casheaTableHeader = [
  { name: 'Nro Orden', value: 'name', sortable: false },
  { name: 'Ref Cashea', value: 'name', sortable: false },
  { name: 'Fecha de Creación', value: '', sortable: false },
  { name: 'Asesor Responsable', value: 'phone', sortable: false },
  { name: 'Canal', value: '', sortable: false },
  { name: 'Plan', value: '', sortable: false },
  { name: 'Cliente', value: '', sortable: false },
  { name: 'Monto Facturado USD', value: '', sortable: false },
  { name: 'Monto Ingresado USD', value: '', sortable: false },
  { name: 'Monto Deuda USD', value: '', sortable: false },
  { name: 'Deuda cuota 1 ', value: '', sortable: false },
  { name: 'Deuda cuota 2', value: '', sortable: false },
  { name: 'Deuda cuota 3', value: '', sortable: false },
];
export const CasheaPaymentsReportTableHeader = [
  { name: 'Fecha de Creación', value: '', sortable: false },
  { name: 'Descripción', value: 'phone', sortable: false },
  { name: 'Archivo con pagos no procesados', value: '', sortable: false },
];

export const casheaReportsHeader = [
  { name: 'Fecha de Transacción', value: '', sortable: false },
  { name: 'Moneda', value: 'name', sortable: false },
  { name: 'Método de Pago', value: 'name', sortable: false },
  { name: 'Cuenta', value: 'phone', sortable: false },
  { name: 'Monto pagado en VES', value: '', sortable: false },
  { name: 'Monto pagado en USD', value: '', sortable: false },
  { name: 'Fecha Tasa de Cambio', value: '', sortable: false },
  { name: 'Tasa de Cambio', value: '', sortable: false },
  { name: '# Referencia', value: '', sortable: false },
  { name: '# Cuota Pagada', value: '', sortable: false },
  { name: '# Orden', value: '', sortable: false },
  { name: '# Factura', value: '', sortable: false },
  { name: 'Sucursal', value: '', sortable: false },
];

export const RCVsTableHeader = [
  { name: "Nro Orden", value: "name", sortable: false },
  { name: "Tipo de venta", value: "name", sortable: false },
  { name: "Prima", value: "phone", sortable: false },
  { name: "Fecha inicio RCV", value: "", sortable: false },
  { name: "Fecha fin RCV", value: "", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Cédula", value: "", sortable: false },
  { name: "Teléfono", value: "", sortable: false },
  { name: "Correo", value: "", sortable: false },
  { name: "Fecha Nacimiento", value: "", sortable: false },
  { name: "Estado", value: "", sortable: false },
  { name: "Ciudad", value: "", sortable: false },
  // { name: 'Dirección', value: '', sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Clase", value: "", sortable: false },
  { name: "Tipo", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Modelo", value: "", sortable: false },
  { name: "Color", value: "", sortable: false },
  { name: "Año Vehículo", value: "", sortable: false },
  { name: "Serial de Carrocería", value: "", sortable: false },
];

export const RCV_CS_TableHeader = [
  { name: "Identificador", value: "name", sortable: false },
  { name: "Número de Identificación", value: "name", sortable: false },
  { name: "Nombre", value: "name", sortable: false },
  { name: "Apellido", value: "name", sortable: false },
  { name: "Sexo", value: "name", sortable: false },
  { name: "Estado Civil", value: "name", sortable: false },
  { name: "Fecha de Nacimiento", value: "name", sortable: false },
  { name: "Profesión", value: "name", sortable: false },
  { name: "Actividad Económica", value: "name", sortable: false },
  { name: "Teléfono", value: "name", sortable: false },
  { name: "Calle", value: "name", sortable: false },
  { name: "Edificio/Casa", value: "name", sortable: false },
  { name: "Apto", value: "name", sortable: false },
  { name: "Parroquia", value: "name", sortable: false },
  { name: "Municipio", value: "name", sortable: false },
  { name: "Ciudad", value: "name", sortable: false },
  { name: "Estado", value: "name", sortable: false },
  { name: "Correo", value: "name", sortable: false },
  { name: "Identificador del tomador", value: "name", sortable: false },
  {
    name: "Número de Identificación del tomador",
    value: "name",
    sortable: false,
  },
  { name: "Nombre del Tomador", value: "name", sortable: false },
  { name: "Apellido del Tomador", value: "name", sortable: false },
  {
    name: "Identificador del Propietario del vehículo",
    value: "name",
    sortable: false,
  },
  {
    name: "Número de Identificación del Propietario del vehículo",
    value: "name",
    sortable: false,
  },
  { name: "Nombre Propietario del vehículo", value: "name", sortable: false },
  { name: "Apellido Propietario del vehículo", value: "name", sortable: false },
  { name: "Marca", value: "name", sortable: false },
  { name: "Modelo", value: "name", sortable: false },
  { name: "Año", value: "name", sortable: false },
  { name: "Placa", value: "name", sortable: false },
  { name: "Color", value: "name", sortable: false },
  { name: "Serial de Carroceria", value: "name", sortable: false },
  { name: "Serial NIV del Vehículo", value: "name", sortable: false },
  { name: "Tipo de Vehículo", value: "name", sortable: false },
  { name: "Uso del Vehículo", value: "name", sortable: false },
  { name: "Número de puestos del vehículo", value: "name", sortable: false },
  { name: "Lugar de uso del vehículo", value: "name", sortable: false },
  { name: "N° de Licencia", value: "name", sortable: false },
  { name: "Colegio del Productor", value: "name", sortable: false },
  { name: "Sucursal Oficina", value: "name", sortable: false },
  { name: "Moneda", value: "name", sortable: false },
  { name: "Póliza", value: "name", sortable: false },
  { name: "Fecha de Emisión Póliza", value: "name", sortable: false },
  { name: "Desde Póliza", value: "name", sortable: false },
  { name: "Hasta Póliza", value: "name", sortable: false },
  { name: "Ramo", value: "name", sortable: false },
  { name: "Cobertura", value: "name", sortable: false },
  { name: "Suma Asegurada", value: "name", sortable: false },
  { name: "Prima", value: "name", sortable: false },
  { name: "Tipo de RCV", value: "name", sortable: false },
  { name: "Comisión", value: "name", sortable: false },
  { name: "Deducible", value: "name", sortable: false },
  { name: "N° de Recibo", value: "name", sortable: false },
  { name: "Tipo de Recibo", value: "name", sortable: false },
  { name: "Fecha de Emisión Recibo", value: "name", sortable: false },
  { name: "Vigencia desde Recibo", value: "name", sortable: false },
  { name: "Vigencia hasta Recibo", value: "name", sortable: false },
  { name: "Ingreso", value: "name", sortable: false },
  { name: "Descripcion del Ingreso", value: "name", sortable: false },
  { name: "Tipo de Ingreso", value: "name", sortable: false },
  { name: "Tasa", value: "name", sortable: false },
  { name: "Fecha de la tasa", value: "name", sortable: false },
  { name: "Estatus", value: "name", sortable: false },
  { name: "Motivo de Anulación", value: "name", sortable: false },
  { name: "Cédula", value: "name", sortable: false },
  { name: "Licencia de conducir", value: "name", sortable: false },
  // { name: 'Carnet de circulación', value: 'name', sortable: false },
  { name: "Título de propiedad del vehículo", value: "name", sortable: false },
  { name: "Poliza RCV", value: "name", sortable: false },
  { name: "Carnet RCV", value: "name", sortable: false },
  { name: "Documento de solicitud", value: "name", sortable: false },
  { name: "Número del Título de Propiedad", value: "name", sortable: false },
];

export const UpgradesTableHeader = [
  { name: "Fecha de Upgrade", value: "name", sortable: false },
  { name: "Asesor", value: "name", sortable: false },
  { name: "Nro, Orden", value: "name", sortable: false },
  { name: "Cliente", value: "name", sortable: false },
  { name: "Cambio de plan", value: "name", sortable: false },
  { name: "Cambio de Km", value: "name", sortable: false },
  { name: "Grúa extendido", value: "name", sortable: false },
  { name: "Placa", value: "name", sortable: false },
  { name: "Cédula", value: "name", sortable: false },
  { name: "Método de pago", value: "name", sortable: false },
  { name: "Pago Upgrade $", value: "name", sortable: false },
  { name: "Pago Upgrade Bs", value: "name", sortable: false },
  { name: "Referencia", value: "name", sortable: false },
  { name: "Estado", value: "name", sortable: false },
  { name: "Ciudad", value: "name", sortable: false },
  { name: "Año", value: "name", sortable: false },
  { name: "Blindaje", value: "name", sortable: false },
];

export const exportablesSalesTable = [
  { name: "Nro Orden", value: "name", sortable: false },
  { name: "Fecha creación", value: "name", sortable: false },
  { name: "Asesor responsable", value: "phone", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Plan", value: "", sortable: false },
  { name: "Tipo de venta", value: "", sortable: false },
  { name: "Plan Anterior", value: "", sortable: false },
  { name: "Fecha fin plan anterior", value: "", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Año Vehículo", value: "", sortable: false },
  { name: "Código aplicado", value: "", sortable: false },
  { name: "Modalidad de pago", value: "", sortable: false },
  { name: "Tipo de pago", value: "", sortable: false },
  { name: "RCV", value: "", sortable: false },
  { name: "Monto Factura $", value: "", sortable: false },
  { name: "Monto Factura BsS", value: "", sortable: false },
  // Payment section
  { name: "Método de Pago 1", value: "", sortable: false },
  { name: "Monto Pago 1 $", value: "", sortable: false },
  { name: "Referencia de Pago 1", value: "", sortable: false },
  { name: "Titular de la cuenta 1", value: "", sortable: false },
  { name: "Método de Pago 2", value: "", sortable: false },
  { name: "Monto Pago 2 $", value: "", sortable: false },
  { name: "Referencia de Pago 2", value: "", sortable: false },
  { name: "Titular de la cuenta 2", value: "", sortable: false },
  // Pagos financiados
  { name: "Referencia de Cashea", value: "", sortable: false },
  { name: "Pago Inicial Fecha de pago", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 1 USD", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Pago Inicial Método 1", value: "", sortable: false },
  { name: "Pago Inicial Referencia 1", value: "", sortable: false },
  { name: "Pago Inicial Titular de la cuenta 1", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 2 USD", value: "", sortable: false },
  { name: "Pago Inicial Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Pago Inicial Método 2", value: "", sortable: false },
  { name: "Pago Inicial Referencia 2", value: "", sortable: false },
  { name: "Pago Inicial Titular de la cuenta 2", value: "", sortable: false },
  { name: "Fecha de vencimiento Cuota 1", value: "", sortable: false },
  { name: "Fecha de pago Cuota 1", value: "", sortable: false },

  { name: "Cuota 1 Monto pagado 1 USD", value: "", sortable: false },
  { name: "Cuota 1 Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Cuota 1 Método 1", value: "", sortable: false },
  { name: "Cuota 1 Referencia 1", value: "", sortable: false },
  { name: "Cuota 1 Titular de la cuenta 1", value: "", sortable: false },

  { name: "Cuota 1 Monto pagado 2 USD", value: "", sortable: false },
  { name: "Cuota 1 Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Cuota 1 Método 2", value: "", sortable: false },
  { name: "Cuota 1 Referencia 2", value: "", sortable: false },
  { name: "Cuota 1 Titular de la cuenta 2", value: "", sortable: false },
  { name: "Fecha de vencimiento Cuota 2", value: "", sortable: false },
  { name: "Fecha de pago Cuota 2", value: "", sortable: false },

  { name: "Cuota 2 Monto pagado 1 USD", value: "", sortable: false },
  { name: "Cuota 2 Método 1", value: "", sortable: false },
  { name: "Cuota 2 Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Cuota 2 Referencia 1", value: "", sortable: false },
  { name: "Cuota 2 Titular de la cuenta 1", value: "", sortable: false },
  { name: "Cuota 2 Monto pagado 2 USD", value: "", sortable: false },
  { name: "Cuota 2 Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Cuota 2 Método 2", value: "", sortable: false },
  { name: "Cuota 2 Referencia 2", value: "", sortable: false },
  { name: "Cuota 2 Titular de la cuenta 2", value: "", sortable: false },
  { name: "Fecha de vencimiento Cuota 3", value: "", sortable: false },
  { name: "Fecha de pago Cuota 3", value: "", sortable: false },

  { name: "Cuota 3 Monto pagado 1 USD", value: "", sortable: false },
  { name: "Cuota 3 Método 1", value: "", sortable: false },
  { name: "Cuota 3 Monto pagado 1 Bs", value: "", sortable: false },
  { name: "Cuota 3 Referencia 1", value: "", sortable: false },
  { name: "Cuota 3 Titular de la cuenta 1", value: "", sortable: false },
  { name: "Cuota 3 Monto pagado 2 USD", value: "", sortable: false },
  { name: "Cuota 3 Monto pagado 2 Bs", value: "", sortable: false },
  { name: "Cuota 3 Método 2", value: "", sortable: false },
  { name: "Cuota 3 Referencia 2", value: "", sortable: false },
  { name: "Cuota 3 Titular de la cuenta 2", value: "", sortable: false },
  { name: "Cuenta por cobrar", value: "", sortable: false },
  { name: "Estatus cuenta por cobrar", value: "", sortable: false },
  // Rest
  { name: "Estado", value: "", sortable: false },
  { name: "Ciudad", value: "", sortable: false },
  { name: "Modelo", value: "", sortable: false },
  { name: "Fecha inicio plan", value: "", sortable: false },
  { name: "Fecha fin plan", value: "", sortable: false },
  { name: "Fecha inicio RCV", value: "", sortable: false },
  { name: "Fecha fin RCV", value: "", sortable: false },
  { name: "Condición afiliación", value: "", sortable: false },
  { name: "Monto Pago 1 RCV", value: "state", sortable: false },
  { name: "Monto Pago 1 Bs RCV", value: "state", sortable: false },
  { name: "Método Pago 1 RCV", value: "state", sortable: false },
  { name: "Referencia Pago 1 RCV", value: "state", sortable: false },
  { name: "Fecha Pago 1 RCV", value: "state", sortable: false },
  { name: "Monto Pago 2 RCV", value: "state", sortable: false },
  { name: "Monto Pago 2 Bs RCV", value: "state", sortable: false },
  { name: "Método Pago 2 RCV", value: "state", sortable: false },
  { name: "Referencia Pago 2 RCV", value: "state", sortable: false },
  { name: "Fecha Pago 2 RCV", value: "state", sortable: false },
  // ];
];

// OLD EXPORTABLE HEADER ADMIN

// export const exportablesAdminTable = [
//   { name: 'Nro Orden', value: 'name', sortable: false },
//   { name: 'Fecha creación', value: 'name', sortable: false },
//   { name: 'Estatus de afiliación', value: '', sortable: false },
//   { name: 'Canal', value: '', sortable: false },
//   { name: 'Plan', value: '', sortable: false },
//   { name: 'Tipo de venta', value: '', sortable: false },
//   { name: 'Modalidad de pago', value: '', sortable: false },
//   { name: 'Tipo de pago', value: '', sortable: false },
//   { name: 'RCV', value: '', sortable: false },
//   { name: 'Facturacion de planes $', value: '', sortable: false },
//   { name: 'Facturacion de planes BsS', value: '', sortable: false },
//   { name: 'Facturacion de RCV $', value: '', sortable: false },
//   { name: 'Monto ingresado $', value: '', sortable: false },
//   { name: 'Monto ingresado BsS', value: '', sortable: false },
//   // Payment section
//   { name: 'Método de Pago 1', value: '', sortable: false },
//   { name: 'Monto Pago 1 $', value: '', sortable: false },
//   { name: 'Referencia de Pago 1', value: '', sortable: false },
//   { name: 'Método de Pago 2', value: '', sortable: false },
//   { name: 'Monto Pago 2 $', value: '', sortable: false },
//   { name: 'Referencia de Pago 2', value: '', sortable: false },
//   // Pagos financiados
//   { name: 'Referencia de Cashea', value: '', sortable: false },
//   { name: 'Pago Inicial Fecha de pago', value: '', sortable: false },

//   { name: 'Pago Inicial Monto pagado 1 USD', value: '', sortable: false },
//   { name: 'Pago Inicial Monto pagado 1 Bs', value: '', sortable: false },
//   { name: 'Pago Inicial Método 1', value: '', sortable: false },
//   { name: 'Pago Inicial Referencia 1', value: '', sortable: false },

//   { name: 'Pago Inicial Monto pagado 2 USD', value: '', sortable: false },
//   { name: 'Pago Inicial Monto pagado 2 Bs', value: '', sortable: false },
//   { name: 'Pago Inicial Método 2', value: '', sortable: false },
//   { name: 'Pago Inicial Referencia 2', value: '', sortable: false },

//   { name: 'Fecha de vencimiento Cuota 1', value: '', sortable: false },
//   { name: 'Fecha de pago Cuota 1', value: '', sortable: false },

//   { name: 'Cuota 1 Monto pagado 1 USD', value: '', sortable: false },
//   { name: 'Cuota 1 Monto pagado 1 Bs', value: '', sortable: false },
//   { name: 'Cuota 1 Método 1', value: '', sortable: false },
//   { name: 'Cuota 1 Referencia 1', value: '', sortable: false },

//   { name: 'Cuota 1 Monto pagado 2 USD', value: '', sortable: false },
//   { name: 'Cuota 1 Monto pagado 2 Bs', value: '', sortable: false },
//   { name: 'Cuota 1 Método 2', value: '', sortable: false },
//   { name: 'Cuota 1 Referencia 2', value: '', sortable: false },

//   { name: 'Fecha de vencimiento Cuota 2', value: '', sortable: false },
//   { name: 'Fecha de pago Cuota 2', value: '', sortable: false },

//   { name: 'Cuota 2 Monto pagado 1 USD', value: '', sortable: false },
//   { name: 'Cuota 2 Método 1', value: '', sortable: false },
//   { name: 'Cuota 2 Monto pagado 1 Bs', value: '', sortable: false },
//   { name: 'Cuota 2 Referencia 1', value: '', sortable: false },

//   { name: 'Cuota 2 Monto pagado 2 USD', value: '', sortable: false },
//   { name: 'Cuota 2 Monto pagado 2 Bs', value: '', sortable: false },
//   { name: 'Cuota 2 Método 2', value: '', sortable: false },
//   { name: 'Cuota 2 Referencia 2', value: '', sortable: false },

//   { name: 'Fecha de vencimiento Cuota 3', value: '', sortable: false },
//   { name: 'Fecha de pago Cuota 3', value: '', sortable: false },

//   { name: 'Cuota 3 Monto pagado 1 USD', value: '', sortable: false },
//   { name: 'Cuota 3 Método 1', value: '', sortable: false },
//   { name: 'Cuota 3 Monto pagado 1 Bs', value: '', sortable: false },
//   { name: 'Cuota 3 Referencia 1', value: '', sortable: false },

//   { name: 'Cuota 3 Monto pagado 2 USD', value: '', sortable: false },
//   { name: 'Cuota 3 Monto pagado 2 Bs', value: '', sortable: false },
//   { name: 'Cuota 3 Método 2', value: '', sortable: false },
//   { name: 'Cuota 3 Referencia 2', value: '', sortable: false },

//   { name: 'Cuenta por cobrar', value: '', sortable: false },
//   { name: 'Estatus cuenta por cobrar', value: '', sortable: false },
//   { name: 'Monto Pago 1 RCV', value: 'state', sortable: false },
//   { name: 'Monto Pago 1 Bs RCV', value: 'state', sortable: false },
//   { name: 'Método Pago 1 RCV', value: 'state', sortable: false },
//   { name: 'Referencia Pago 1 RCV', value: 'state', sortable: false },
//   { name: 'Fecha Pago 1 RCV', value: 'state', sortable: false },
//   { name: 'Monto Pago 2 RCV', value: 'state', sortable: false },
//   { name: 'Monto Pago 2 Bs RCV', value: 'state', sortable: false },
//   { name: 'Método Pago 2 RCV', value: 'state', sortable: false },
//   { name: 'Referencia Pago 2 RCV', value: 'state', sortable: false },
//   { name: 'Fecha Pago 2 RCV', value: 'state', sortable: false },
//   // ];
// ]

// NEW EXPORTABLE HEADER ADMIN

export const exportablesAdminTable = [
  { name: "Nro. orden", value: "", sortable: false },
  { name: "Fecha de pago", value: "", sortable: false },
  { name: "Modalidad de pago", value: "", sortable: false },
  { name: "Tipo de pago", value: "", sortable: false },
  { name: "Cuota pagada", value: "", sortable: false },
  { name: "Total facturación", value: "", sortable: false },
  { name: "Facturación de planes $", value: "", sortable: false },
  { name: "Facturación de RCV $", value: "", sortable: false },
  { name: "Facturación Certificado Médico $", value: "", sortable: false },
  { name: "Pago upgrade $", value: "", sortable: false },
  { name: "Monto ingresado $", value: "", sortable: false },
  { name: "Monto ingresado Bs", value: "", sortable: false },
  { name: "Método de pago", value: "", sortable: false },
  { name: "Referencia", value: "", sortable: false },
  { name: "Titular de la cuenta", value: "", sortable: false },
  { name: "Cuenta por cobrar", value: "", sortable: false },
  { name: "Referencia Cashea", value: "", sortable: false },
  { name: "Fecha Creación", value: "", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Plan", value: "state", sortable: false },
  { name: "Tipo de Venta", value: "state", sortable: false },
  { name: "Código aplicado", value: "state", sortable: false },
];

export const exportablesPendingAccounts = [
  { name: "Nro Orden", value: "name", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Plan", value: "", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Tipo de venta", value: "", sortable: false },
  { name: "Fecha de Afiliacion", value: "", sortable: false },
  { name: "Modalidad de Pago", value: "", sortable: false },
  { name: "Fecha de vencimiento de la cuota", value: "", sortable: false },
  { name: "Monto pagado $", value: "", sortable: false },
  { name: "Monto pagado Bs", value: "", sortable: false },
  { name: "Fecha de pago", value: "", sortable: false },
  { name: "Mes de pago", value: "", sortable: false },
  { name: "Monto de Pago 1 $", value: "", sortable: false },
  { name: "Monto de Pago 1 Bs", value: "", sortable: false },
  { name: "Método de Pago 1", value: "", sortable: false },
  { name: "Referencia de Pago 1", value: "", sortable: false },
  { name: "Monto de Pago 2 $", value: "", sortable: false },
  { name: "Monto de Pago 2 Bs", value: "", sortable: false },
  { name: "Método de Pago 2", value: "", sortable: false },
  { name: "Referencia de Pago 2", value: "", sortable: false },
];

export const pendingForApprovalTableHeader = [
  { name: "Acción", value: "", sortable: false, hidePrint: true },
  { name: "Membresia", value: "name", sortable: false },
  { name: "Tipo de Afiliación", value: "name", sortable: false },
  { name: "Asesor", value: "name", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Cédula", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Teléfono", value: "", sortable: false },
  { name: "Documentos comprobados", value: "", sortable: false },
  { name: "Plan", value: "state", sortable: false },
  { name: "Pago comprobado", value: "", sortable: false },
  { name: "Tipo de pago", value: "", sortable: false },
  { name: "Monto factura $", value: "", sortable: false },
  { name: "Fecha Creación", value: "name", sortable: false },
];

export const lostTableHeader = [
  { name: "Acción", value: "", sortable: false, hidePrint: true },
  { name: "Membresia", value: "name", sortable: false },
  { name: "Tipo de Afiliación", value: "name", sortable: false },
  { name: "Asesor Asignado", value: "phone", sortable: false },
  { name: "Canal", value: "", sortable: false },
  { name: "Empresa", value: "", sortable: false },
  { name: "Cliente", value: "", sortable: false },
  { name: "Cédula", value: "", sortable: false },
  { name: "Género", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Teléfono", value: "", sortable: false },
  { name: "Correo", value: "", sortable: false },
  { name: "Plan", value: "state", sortable: false },
  { name: "Año Vehículo", value: "", sortable: false },
  { name: "Nivel de Siniestralidad", value: "", sortable: false },
  { name: "Monto a pagar $", value: "", sortable: false },
  { name: "Fecha Fin de plan", value: "name", sortable: false },
  { name: "Fecha Fin RCV", value: "name", sortable: false },
  { name: "Acción de Campaña", value: "", sortable: false },
];

export const fleetLoadDEtailTableHeader = [
  { name: "Orden Nº", value: "name", sortable: false },
  { name: "Estatus afiliación", value: "name", sortable: false },
  { name: "Fecha de creación", value: "state", sortable: false },
  { name: "Nombre", value: "", sortable: false },
  { name: "Apellido", value: "", sortable: false },
  { name: "Identificación", value: "", sortable: false },
  { name: "Teléfono", value: "", sortable: false },
  { name: "Correo", value: "", sortable: false },
  { name: "Estado ", value: "", sortable: false },
  { name: "Plan", value: "", sortable: false },
  { name: "Marca", value: "", sortable: false },
  { name: "Modelo", value: "", sortable: false },
  { name: "Año", value: "", sortable: false },
  { name: "Color", value: "", sortable: false },
  { name: "Placa", value: "", sortable: false },
  { name: "Fecha inicio plan", value: "", sortable: false },
  { name: "Fecha fin plan", value: "", sortable: false },
  { name: "Tipo de venta", value: "", sortable: false },
  { name: "RCV", value: "", sortable: false },
  { name: "Fecha inicio RCV", value: "", sortable: false },
  { name: "Fecha fin RCV", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const renewalTableHeader = [
  {
    name: "Acción",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Nro. Orden",
    value: "name",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  // {
  //   name: 'Fecha Creación',
  //   value: 'name',
  //   sortable: false,
  //   filterOptions: [],
  //   filterSearch: () => {},
  // },
  {
    name: "Asesor Asignado",
    value: "name",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Acción de Campaña",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  // {
  //   name: 'Condición Afiliación',
  //   value: 'phone',
  //   sortable: false,
  //   filterOptions: [],
  //   filterSearch: () => {},
  // },
  {
    name: "Plan",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Clase vehículo",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Año vehículo",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  // { name: 'Blindaje', value: 'state', sortable: false, filterOptions: [], filterSearch: () => {}  },
  {
    name: "Nivel siniestralidad",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Cantidad de Servicios de grúa",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "% recarga/descuento",
    value: "state",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Resumen",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Precio Renovación",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Cliente",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Placa",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Marca",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Modelo",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Cédula",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Teléfono",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Correo",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Canal",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  // { name: 'Contrato', value: '', sortable: false, filterOptions: [], filterSearch: () => {}  },
  {
    name: "Fecha fin plan",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Mes finalización plan",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Tipo de venta",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Fecha fin RCV",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
  {
    name: "Observación",
    value: "",
    sortable: false,
    filterOptions: [],
    filterSearch: () => {},
  },
];

export const fleetsRegisterTableHeader = [
  { name: "ID", value: "name", sortable: false },
  { name: "Nombre", value: "name", sortable: false },
  { name: "Código", value: "phone", sortable: false },
  { name: "Status", value: "state", sortable: false },
  { name: "Cargas", value: "state", sortable: false },
  { name: "Creado", value: "state", sortable: false },
  { name: "Modificado", value: "state", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const discountTableHeade = [
  { name: "Fecha de creación", value: "name", sortable: false },
  { name: "Código de descuento", value: "name", sortable: false },
  { name: "Descuento", value: "phone", sortable: false },
  { name: "Renovación", value: "phone", sortable: false },
  { name: "Nuevas Membresias", value: "phone", sortable: false },
  { name: "Planes", value: "phone", sortable: false },
  { name: "Fecha de inicio", value: "state", sortable: false },
  { name: "Fecha de fin", value: "state", sortable: false },
  { name: "Canal", value: "state", sortable: false },
  { name: "Campaña", value: "state", sortable: false },
  { name: "Status", value: "", sortable: false },
  { name: "Máximo de usos", value: "", sortable: false },
  { name: "Veces utilizado", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const giftcardSalesheader = [
  { name: "Fecha de Venta", value: "name", sortable: false },
  { name: "Plan", value: "phone", sortable: false },
  { name: "Canal", value: "phone", sortable: false },
  { name: "Precio Plan", value: "phone", sortable: false },
  { name: "Cantidad Giftcards", value: "phone", sortable: false },
  { name: "Total Ventas", value: "phone", sortable: false },
  { name: "Creado", value: "phone", sortable: false },
  { name: "Modificado", value: "phone", sortable: false },
  { name: "Acciones", value: "phone", sortable: false },
];

export const giftcardSalesCodeheader = [
  { name: "Código Público", value: "name", sortable: false },
  { name: "Código Privado", value: "phone", sortable: false },
  { name: "Plan", value: "phone", sortable: false },
  { name: "Canal", value: "phone", sortable: false },
  { name: "Status", value: "phone", sortable: false },
  { name: "Acciones", value: "phone", sortable: false },
];

export const giftcardParticularSalesheader = [
  { name: "Fecha de Venta", value: "name", sortable: false },
  { name: "Plan", value: "phone", sortable: false },
  { name: "Canal", value: "phone", sortable: false },
  { name: "Precio Plan", value: "phone", sortable: false },
  { name: "Código Público", value: "phone", sortable: false },
  { name: "Nombre y Apellido", value: "phone", sortable: false },
  { name: "Teléfono", value: "phone", sortable: false },
  { name: "Correo", value: "phone", sortable: false },
  { name: "Asesor", value: "phone", sortable: false },
  { name: "Monto pago 1 $", value: "phone", sortable: false },
  { name: "Monto pago 1 Bs", value: "phone", sortable: false },
  { name: "Método pago 1", value: "phone", sortable: false },
  { name: "Referencia pago 1", value: "phone", sortable: false },
  { name: "Comprobante pago 1", value: "phone", sortable: false },
  { name: "Monto pago 2 $", value: "phone", sortable: false },
  { name: "Monto pago 2 Bs", value: "phone", sortable: false },
  { name: "Método pago 2", value: "phone", sortable: false },
  { name: "Referencia pago 2", value: "phone", sortable: false },
  { name: "Comprobante pago 2", value: "phone", sortable: false },
  { name: "Pago recarga de antiguedad", value: "phone", sortable: false },
  { name: "Pago recarga de blindaje", value: "phone", sortable: false },
  { name: "Pago recarga de km extra", value: "phone", sortable: false },
  { name: "Pago recarga de km ilimitado", value: "phone", sortable: false },
  { name: "Pago recarga RCV", value: "phone", sortable: false },

  { name: "Acciones", value: "phone", sortable: false },
];

export const giftcardTableheader = [
  { name: "Código Público", value: "name", sortable: false },
  { name: "Código Privado", value: "name", sortable: false },
  { name: "Plan", value: "phone", sortable: false },
  { name: "Canal", value: "phone", sortable: false },
  { name: "Status", value: "phone", sortable: false },
  { name: "Acciones", value: "phone", sortable: false },
];

export const fleetDetailTableHeader = [
  { name: "Código de Carga", value: "name", sortable: false },
  { name: "Tipo de flota", value: "name", sortable: false },
  { name: "Monto de la flota USD", value: "phone", sortable: false },
  { name: "Tipo de pago", value: "state", sortable: false },
  { name: "Fecha inicio", value: "state", sortable: false },
  { name: "Fecha fin", value: "state", sortable: false },
  { name: "Cantidad de vehículos", value: "", sortable: false },
  { name: "Link afiliación", value: "", sortable: false },
  { name: "Status", value: "", sortable: false },
  { name: "Detalle", value: "", sortable: false },
  { name: "Acción", value: "", sortable: false },
];

export const vehiclesMembershipTableHeader = [
  { name: "Accion", value: "name", sortable: false },
  { name: "Canal", value: "name", sortable: false },
  { name: "N° Afiliacion", value: "name", sortable: false },
  { name: "Plan", value: "name", sortable: false },
  { name: "Estatus de Afiliación", value: "name", sortable: false },
  // { name: 'Cantida de servicios AV', value: 'name', sortable: false },
  { name: "Cantida de servicios grúa", value: "name", sortable: false },
  { name: "Nivel de Siniestralidad", value: "name", sortable: false },
  { name: "Tipo de Venta", value: "name", sortable: false },
  { name: "Asesor Responsable", value: "name", sortable: false },
  // { name: 'Fecha de Inicio plan', value: 'name', sortable: false },
  { name: "Fecha fin RCV", value: "name", sortable: false },
  { name: "Fecha de Fin plan", value: "name", sortable: false },
  { name: "RCV", value: "name", sortable: false },
  { name: "Ver Servicios", value: "name", sortable: false },
];

export const fleetLoadDetailMockup = [
  {
    name1: "CD001",
    name2: "Técnica",
    name3: "Planes",
    name4: "1.200$",
    name5: "Financiado mensual",
    name6: "31/03/2023",
    name7: "31/03/2024",
    name8: "Teléfono",
    name9: "15",
    name10: "Activa",
  },
  {
    name1: "CD001",
    name2: "Técnica",
    name3: "Planes",
    name4: "1.200$",
    name5: "Financiado mensual",
    name6: "31/03/2023",
    name7: "31/03/2024",
    name8: "Teléfono",
    name9: "15",
    name10: "Activa",
  },
  {
    name1: "CD001",
    name2: "Técnica",
    name3: "Planes",
    name4: "1.200$",
    name5: "Financiado mensual",
    name6: "31/03/2023",
    name7: "31/03/2024",
    name8: "Teléfono",
    name9: "15",
    name10: "Activa",
  },
];

export const fleetLoadMockup = [
  {
    id: "1",
    name: "MANUEL HERNANDEZ",
    code: "1234 567 789",
    status: "MIRANDA",
    load: "ACTIVO",
    modified: "21/04/23 11:01:43",
    actions: "21/04/23 11:01:43",
  },
  {
    id: "2",
    name: "MANUEL HERNANDEZ",
    code: "1234 567 789",
    status: "MIRANDA",
    load: "ACTIVO",
    modified: "21/04/23 11:01:43",
    actions: "21/04/23 11:01:43",
  },
  {
    id: "3",
    name: "MANUEL HERNANDEZ",
    code: "1234 567 789",
    status: "MIRANDA",
    load: "ACTIVO",
    modified: "21/04/23 11:01:43",
    actions: "21/04/23 11:01:43",
  },
  {
    id: "4",
    name: "MANUEL HERNANDEZ",
    code: "1234 567 789",
    status: "MIRANDA",
    load: "ACTIVO",
    modified: "21/04/23 11:01:43",
    actions: "21/04/23 11:01:43",
  },
];

export const craneProvidersMockup = [
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
  {
    name: "TRANSPORTE MACHADO 1974, C.A",
    contact: "MANUEL HERNANDEZ",
    phone: "1234 567 789",
    state: "MIRANDA",
    status: "ACTIVO",
    edit: "",
  },
];

export const bikeMockup = [
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
  {
    name: "Jose Luis Perez Rodriguez",
    cedula: "1234 567 789",
    phone: "1234 567 789",
    plate: "AH74VF",
    state: "MIRANDA",
    creationDate: "12/03/2023",
  },
];

export const usersTablesMockup = [
  {
    name: "Rubeeeen",
    email: "lorenzo@ruben.com",
    profile: "Administrador de Sistema",
    status: "ACTIVE",
    createdAt: "17/02/2020 17:02:30",
    editedAt: "17/02/2020 17:02:30",
  },
  {
    name: "Rubeeeen",
    email: "lorenzo@ruben.com",
    profile: "Administrador de Sistema",
    status: "ACTIVE",
    createdAt: "17/02/2020 17:02:30",
    editedAt: "17/02/2020 17:02:30",
  },
  {
    name: "Rubeeeen",
    email: "lorenzo@ruben.com",
    profile: "Administrador de Sistema",
    status: "ACTIVE",
    createdAt: "17/02/2020 17:02:30",
    editedAt: "17/02/2020 17:02:30",
  },
  {
    name: "Rubeeeen",
    email: "lorenzo@ruben.com",
    profile: "Administrador de Sistema",
    status: "ACTIVE",
    createdAt: "17/02/2020 17:02:30",
    editedAt: "17/02/2020 17:02:30",
  },
  {
    name: "Rubeeeen",
    email: "lorenzo@ruben.com",
    profile: "Administrador de Sistema",
    status: "ACTIVE",
    createdAt: "17/02/2020 17:02:30",
    editedAt: "17/02/2020 17:02:30",
  },
];

export const providersTablesMockup = [
  {
    name: "Rubeeeen",
    contact: "4142662382",
    region: "Caracas",
    state: "Miranda",
  },
  {
    name: "Rubeeeen",
    contact: "4142662382",
    region: "Caracas",
    state: "Miranda",
  },
  {
    name: "Rubeeeen",
    contact: "4142662382",
    region: "Caracas",
    state: "Miranda",
  },
  {
    name: "Rubeeeen",
    contact: "4142662382",
    region: "Caracas",
    state: "Miranda",
  },
  {
    name: "Rubeeeen",
    contact: "4142662382",
    region: "Caracas",
    state: "Miranda",
  },
];

export const fleetsTablesMockup = [
  {
    transport: "Grua",
    model: "Carrito",
    brand: "Encava",
    plate: "AH74VF",
    year: "2009",
    purchaseDate: "17/02/2020 17:02:30",
  },
  {
    transport: "Grua",
    model: "Carrito",
    brand: "Encava",
    plate: "AH74VF",
    year: "2009",
    purchaseDate: "17/02/2020 17:02:30",
  },
  {
    transport: "Grua",
    model: "Carrito",
    brand: "Encava",
    plate: "AH74VF",
    year: "2009",
    purchaseDate: "17/02/2020 17:02:30",
  },
  {
    transport: "Grua",
    model: "Carrito",
    brand: "Encava",
    plate: "AH74VF",
    year: "2009",
    purchaseDate: "17/02/2020 17:02:30",
  },
  {
    transport: "Grua",
    model: "Carrito",
    brand: "Encava",
    plate: "AH74VF",
    year: "2009",
    purchaseDate: "17/02/2020 17:02:30",
  },
];
