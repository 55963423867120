import { Input } from "../../../common/Forms/Input/Input";
import { FileComponent } from "../../../common/Dropzone/Dropzone";
import {
  paymentMethodsLimit,
  paymentType as paymentTypeOption,
  paymentMethods,
} from "../../../../assets/helpers/options";
import { PaymentMethodsComponent } from "./PaymentMethodsComponent";
import { PaymentMethodCard } from "../LandingComponents/LandingComponents";
import moment from "moment";
import { isPaymentMethodZelleOrPaypal } from "../../../../assets/helpers/funciones";

export const Method1Component = (props) => {
  const {
    watch,
    register,
    errors,
    getUSDFromBs,
    openStripeModal,
    paymentImage1,
    validatePayment,
    setSecondPayment,
    paymemtMethodOptions,
    selectPaymentMethod,
  } = props;

  if (
    [paymentTypeOption?.CASHEA].includes(
      watch('paymentType')
    )
  ) {
    if (watch("initial") && !watch('reference1')) {
      return <PaymentMethodCard method={{ name: paymentMethods?.CASHEA }}
        openModal={() =>
          selectPaymentMethod(paymentMethods?.CASHEA, 1)
        }
      />
    } else {
      return null
    }
  }

  if (
    parseFloat(watch("paymentMethodsQuantity")) > 0 &&
    ![paymentTypeOption?.COURTESY, paymentTypeOption?.PENDING].includes(
      watch("paymentType")
    )
  ) {
    return (
      <>
        <div className="row">
          {watch("paymentType") === paymentTypeOption?.FINANCIADO && (
            <>
              <div className="col-12">
                <h4>Fechas de pago</h4>
              </div>
              <div className="col-12">
                <h6>
                  Pago Inicial {moment(new Date()).format('DD/MM/YYYY')}
                </h6>
              </div>
            </>
          )}
          <div className="col-12">
            {parseFloat(watch("paymentMethodsQuantity")) === 2 && (
              <h5 style={{ margin: "12px 0px" }}>
                Seleccione el 1er monto a fraccionar en el pago
              </h5>
            )}
          </div>
          {parseFloat(watch("amount1IGTF")) > 0 && (
            <Input
              bootstrap={"col-12 col-md-2"}
              label={"IGTF Pago 1 USD *"}
              type={"number"}
              name={"amount1IGTF"}
              register={register}
              options={paymentMethodsLimit}
              placeholder={""}
              errors={errors?.amount1IGTF?.message}
              disabled={validatePayment(paymentImage1, 1)}
              min={"0"}
            />
          )}
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Monto Pago 1 USD *"}
            type={"number"}
            name={"amount1"}
            register={register}
            options={paymentMethodsLimit}
            placeholder={""}
            errors={errors?.amount1?.message}
            disabled={
              watch("reference1") ||
              (watch("paymentMethodsQuantity") === "1" &&
                watch("paymentType") === paymentTypeOption?.DE_CONTADO)
            }
            onChange={setSecondPayment}
            min={"0"}
          />
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Monto Pago 1 Bs *"}
            type={"number"}
            name={"amountBsS1"}
            register={register}
            options={paymentMethodsLimit}
            placeholder={""}
            errors={errors?.amountBsS1?.message}
            disabled={
              watch("reference1") ||
              (watch("paymentMethodsQuantity") === "1" &&
                watch("paymentType") === paymentTypeOption?.DE_CONTADO)
            }
            onChange={getUSDFromBs}
            min={"0"}
          />
          {/* {validateReference(paymentImage1, 1) && ( */}
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Referencia de Pago 1 *"}
            type={"text"}
            name={"reference1"}
            register={register}
            placeholder={""}
            errors={errors?.reference1?.message}
            disabled={true}
          />
          {/* )} */}
          {/* {validateReference(paymentImage1, 1) && ( */}
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Metodo de Pago 1 *"}
            type={"select"}
            name={"method1"}
            register={register}
            options={paymemtMethodOptions}
            placeholder={""}
            errors={errors?.method1?.message}
            disabled={true}
          />
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Monto Recibido USD $ *"}
            type={"number"}
            name={"paid"}
            register={register}
            options={paymemtMethodOptions}
            placeholder={""}
            errors={errors?.paid?.message}
            disabled={true}
          />
          <div className="col-12 col-md-3">
            {paymentImage1?.name && <FileComponent file={paymentImage1} />}
          </div>{" "}
          {(!watch("reference1") ||
            isPaymentMethodZelleOrPaypal(
              watch("method1"),
              paymemtMethodOptions
            )) &&
            !watch("owner1") &&
            watch("paymentMethodsQuantity") &&
            (watch("amount1") || watch("amountBsS1")) && (
              <PaymentMethodsComponent
                selectPaymentMethod={selectPaymentMethod}
                paymentNumber={1}
                openStripeModal={openStripeModal}
              />
            )}
        </div>
      </>
    );
  } else return null;
};
