import { useState, useEffect, useRef, useCallback } from 'react';
import { ActionButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import {
  CasheaTable,
  CasheaPaymentsReportTable,
} from '../../common/Tables/Tables';
import {
  casheaTableHeader,
  CasheaPaymentsReportTableHeader,
} from '../../../assets/helpers/tables';
import * as schemas from '../../../assets/schemas/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { notify } from '../../../assets/helpers/toast';
import { salesRoles } from '../../../assets/helpers/roles.js';
import { membershipService } from '../../../services/membershipService';
import { ErrorFilesService } from '../../../services/errorFilesService';
import { useParams, useHistory } from 'react-router-dom';
import { FilterDatePicker, SearchInput } from '../../common/Forms/Input/Input';
import moment from 'moment';
import { filterParams } from '../../../assets/helpers/funciones';
import './Cashea.scss';
import authService from '../../../services/authService.js';
import { useDropzone } from 'react-dropzone';
import { set, useForm } from 'react-hook-form';
import { UploadCasheaPaymentsModal } from '../../common/Modals/Modals.jsx';

const itemsPerpage = [10, 25, 50, 100, 500];

export const Cashea = () => {
  const tableRef = useRef(null);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isCasheaGrid, setIsCasheaGrid] = useState(true);
  const { statusId, statusName } = useParams();
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [isLoadingEmail, setIsLoadingEmail] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(schemas.uploadCasheaPaymentFilesSchema()),
  });

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        // Do whatever you want with the file contents
      };
      reader.readAsArrayBuffer(file);

      if (
        ![
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(file.type)
      ) {
        notify('Agregar un formato valido de imagen', 'error');
        return null;
      }
      let maxWeight = 5;
      if (file.size * 0.000001 > maxWeight) {
        notify(`La imagen no debe superar los ${maxWeight} MB`, 'error');
      } else {
        setFileUploaded(file);
      }
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const [fileUploaded, setFileUploaded] = useState('');

  const [casheaTable, setCasheaTable] = useState({
    limit: itemsPerpage[2],
    offset: 0,
    data: [],
    count: 0,
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: false,
    searchParam: '',
  });

  const [casheaReportTable, setCasheaReportTable] = useState({
    limit: itemsPerpage[2],
    offset: 0,
    data: [],
    count: 0,
    page: 1,
    dateFrom: '',
    dateTo: '',
    isLoading: false,
    searchParam: '',
  });

  const handleSendCasheaReconcile = handleSubmit(async (data) => {
    const formData = new FormData();
    if (!fileUploaded) {
      notify('Debe adjuntar un archivo', 'error');
      return;
    }
    formData.append('reconcilingFile', fileUploaded);
    formData.append('fileName', data?.description);

    if (!isLoading) {
      setIsLoadingEmail(true);
      membershipService
        .sendCasheaReconcileFile(formData)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            if (response.data?.fileErrors) {
              notify(
                `Archivo procesado con errores, Revise el archivo de pagos no procesados`,
                'info'
              );
              setIsLoadingEmail(false);
            } else {
              notify(`Archivo enviado con exito`, 'success');
            }
            setShowUploadModal(false);
            setIsLoadingEmail(false);
            getErrorFilesCashea();
            setFileUploaded('');
            reset();
          } else {
            notify(`${response.message}`, 'error');
            setIsLoadingEmail(false);
          }
        })
        .catch((error) => {
          setIsLoadingEmail(false);
        });
    }
  });

  const setFirstPage = () => {
    setCasheaTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
      };
    });
  };

  const handleSinceDateChange = (e) => {
    if (e > dateTo && dateTo) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setFirstPage();
      setDateFrom(e);
    }
  };

  const handleUntilDateChange = (e) => {
    if (e < dateFrom && dateFrom) {
      notify('Ingrese una fecha en un rago valido ', 'info');
    } else {
      setDateTo(e);
      setFirstPage();
    }
  };

  const selectitemsPerpage = (event) => {
    setCasheaTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setCasheaTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * casheaTable?.limit,
      };
    });
  };

  const handleSearchChange = (event) => {
    if (isCasheaGrid) {
      setCasheaTable((prevState) => {
        return {
          ...prevState,
          searchParam: event?.target?.value,
          page: 1,
          offset: 0,
          data: false,
        };
      });
    } else {
      setCasheaReportTable((prevState) => {
        return {
          ...prevState,
          searchParam: event?.target?.value,
          page: 1,
          offset: 0,
          data: false,
        };
      });
    }
  };

  const handleReportPageChange = (event, value) => {
    setCasheaReportTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * casheaTable?.limit,
      };
    });
  };

  const handleTableChange = () => {
    setIsCasheaGrid(!isCasheaGrid);
    setCasheaTable((prevState) => {
      return {
        ...prevState,
        searchParam: '',
        page: 1,
        offset: 0,
        data: false,
      };
    });
    setDateFrom('');
    setDateTo('');
    setCasheaReportTable((prevState) => {
      return {
        ...prevState,
        searchParam: '',
        page: 1,
        offset: 0,
        data: false,
      };
    });
    setCasheaTable((prevState) => {
      return {
        ...prevState,
        searchParam: '',
        page: 1,
        offset: 0,
        data: false,
      };
    });

    if (isCasheaGrid) {
      getErrorFilesCashea();
    } else {
      getMembershipCashea();
    }
  };

  const redirectToEditOrder = (orderId) => {
    history.push(`/afiliations/edit-order/${orderId}`);
  };

  const redirectToOrderDetail = (order) => {
    history.push(
      `/afiliations/order-details/${order?._id}/vehicle/${order?.vehicle?._id}`
    );
  };

  const renewMembership = (membership) => {
    if (!isLoading) {
      setIsLoading(true);
      membershipService
        .reativateMembership(membership?._id)
        .then((response) => {
          if ([200, 201].includes(response?.status)) {
            setIsLoading(false);
            notify(`Membresia reactivada`, 'success');
            redirectToEditOrder(membership?._id);
          } else {
            setIsLoading(false);
            notify(`${response.message}`, 'error');
          }
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    // getStatus();
  }, []);

  useEffect(() => {
    getMembershipCashea();
    getErrorFilesCashea();
  }, [
    casheaTable?.page,
    casheaTable?.offset,
    casheaTable?.limit,
    casheaTable?.type,
    casheaReportTable?.page,
    casheaReportTable?.offset,
    casheaReportTable?.limit,
    casheaReportTable?.type,
    dateFrom,
    dateTo,
  ]);

  const validateIdLogic = () => {
    let response = '';
    if (['all', 'todos'].includes(statusName)) {
      response = '';
    }
    if (statusId && statusId !== 'none') {
      return (response = statusId);
    }
    return response;
  };

  const getMembershipCashea = (byMail) => {
    let params = {
      status: validateIdLogic(),
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: casheaTable?.limit,
      offset: casheaTable?.offset,
      byMail: byMail,
      searchParam: casheaTable?.searchParam,
    };

    membershipService
      .getMembershipCashea(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          if (byMail) {
            notify(`Se han enviado el exportable a su curreo`, 'success');
          } else {
            setCasheaTable((prevState) => {
              return {
                ...prevState,
                data: response?.data?.reportData,
                count: response?.data?.total,
                isLoading: false,
              };
            });
          }
        } else {
          notify(`${response.message}` || 'error', 'error');
          setCasheaTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setCasheaTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
    // }
  };

  const getErrorFilesCashea = (byMail) => {
    let params = {
      dateFrom: dateFrom ? moment(dateFrom).format('MM-DD-YYYY') : '',
      dateTo: dateTo ? moment(dateTo).format('MM-DD-YYYY') : '',
      limit: casheaReportTable?.limit,
      offset: casheaReportTable?.offset,
      searchParam: casheaReportTable?.searchParam,
    };

    ErrorFilesService.getErrorFiles(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          setCasheaReportTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.filesData,
              count: response?.data?.total,
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setCasheaReportTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setCasheaReportTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
    // }
  };

  return (
    <>
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Cashea:{' '}
            {isCasheaGrid ? casheaTable?.count : casheaReportTable.count}
          </h1>
          {[
            salesRoles?.SALES_ADMIN,
            salesRoles?.SALES_ADMIN_AUX,
            salesRoles?.SALES_DEVELOPER,
          ].includes(authService?.getUserType()) && (
            <div
              style={{
                visibility: isCasheaGrid ? 'visible' : 'hidden',
              }}
            >
              <ActionButton
                label={'Exportar'}
                action={() => getMembershipCashea(true)}
              />
            </div>
          )}
        </div>
        <div className="breadcrumb-container">
          <div></div>
          <div className="exportables-buttons-container">
            <div style={{ width: '100%' }} className="row">
              <div className="col-12 col-md-2" style={{ marginTop: '30px' }}>
                <SearchInput
                  placeholder={'Nombre o Apellido, Cédula o Placa'}
                  searchAction={
                    isCasheaGrid ? getMembershipCashea : getErrorFilesCashea
                  }
                  value={
                    isCasheaGrid
                      ? casheaTable?.searchParam
                      : casheaReportTable?.searchParam
                  }
                  onChange={handleSearchChange}
                  width="50px"
                />
              </div>
              <div
                style={{ marginTop: '6px', marginLeft: '12px' }}
                className="col-12 col-md-2 col-lg-1"
              ></div>
              {![
                salesRoles?.SALES_HUNTER,
                salesRoles?.SALES_COUNTER,
                salesRoles?.SALES_FARMER,
                salesRoles?.SALES_ADMIN_AUX,
              ].includes(authService?.getUserType()) ? (
                <>
                  <FilterDatePicker
                    bootstrap={'col-12 col-md-2'}
                    label={'Desde'}
                    type={'date'}
                    name={'dateFrom'}
                    placeholder={''}
                    onChange={handleSinceDateChange}
                    value={dateFrom}
                    disabled={casheaTable?.isLoading}
                  />
                  <FilterDatePicker
                    bootstrap={'col-12 col-md-2'}
                    label={'Hasta'}
                    type={'date'}
                    name={'dateTo'}
                    placeholder={''}
                    onChange={handleUntilDateChange}
                    value={dateTo}
                    disabled={casheaTable?.isLoading}
                  />
                </>
              ) : null}
              <div
                style={{
                  marginTop: '24px',
                  marginLeft: 'auto',
                  width: '350px',
                }}
              >
                {![
                  salesRoles?.SALES_FARMER,
                  salesRoles?.SALES_HUNTER,
                  salesRoles?.SALES_COUNTER,
                  salesRoles?.SALES_ADMIN_AUX,
                ].includes(authService?.getUserType()) && (
                  <SelectPerPage
                    label={'Registros por página'}
                    options={itemsPerpage}
                    value={casheaTable?.limit}
                    onChange={selectitemsPerpage}
                  />
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '5px',
                }}
              >
                {[
                  salesRoles?.SALES_ADMIN,
                  salesRoles?.SALES_ADMIN_AUX,
                  salesRoles?.SALES_DEVELOPER,
                ].includes(authService?.getUserType()) && (
                  <>
                    <ActionButton
                      label={
                        isCasheaGrid ? 'Reportar pagos' : 'Volver a Cashea'
                      }
                      action={() => handleTableChange()}
                    />
                    {!isCasheaGrid && (
                      <ActionButton
                        label={'Subir Archivo'}
                        action={() => setShowUploadModal(true)}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {isCasheaGrid ? (
          <CasheaTable
            header={casheaTableHeader}
            data={casheaTable}
            handlePageChange={handlePageChange}
            redirectToEditOrder={redirectToEditOrder}
            redirectToOrderDetail={redirectToOrderDetail}
            renewMembership={renewMembership}
            tableRef={tableRef}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        ) : (
          <CasheaPaymentsReportTable
            header={CasheaPaymentsReportTableHeader}
            data={casheaReportTable}
            handlePageChange={handleReportPageChange}
            tableRef={tableRef}
            dateFrom={dateFrom}
            dateTo={dateTo}
          />
        )}

        {showUploadModal && (
          <UploadCasheaPaymentsModal
            show={showUploadModal}
            onHide={() => setShowUploadModal(false)}
            register={register}
            errors={errors}
            control={control}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            fileUploaded={fileUploaded}
            setFileUploaded={setFileUploaded}
            action={handleSendCasheaReconcile}
          />
        )}
      </div>
    </>
  );
};
