import { useState, useEffect, } from 'react';
import { ActionButton, AltButton } from '../../common/Buttons/Buttons';
import { Header } from '../../common/Header/Header.jsx';
import { SelectPerPage } from '../../common/Forms/Input/Input';
import { GiftcardCodesTables } from '../../common/Tables/Tables';
import { Select } from '../../common/Forms/Input/Input';
import { giftcardSalesCodeheader } from '../../../assets/helpers/tables';
import { notify } from '../../../assets/helpers/toast';
import { saleChannelService } from '../../../services/saleChannelService';
import { giftCardService } from '../../../services/giftCardService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import * as schemas from '../../../assets/schemas/schemas';
import { useHistory } from 'react-router-dom';
import {
  sortCardsOptions,
  filterCardByStatusOptions,
  giftCardTypeOptions,
} from '../../../assets/helpers/options';
import {
  ActivateGiftcardModal,
  CreateGiftcardLoadModal,
  BlockGiftcardModal,
} from '../../common/Modals/Modals';
import './GiftcardsCodes.scss';
import { filterParams } from '../../../assets/helpers/funciones';

const itemsPerpage = [10, 25, 50, 100, 500];

export const GiftcardsCodes = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schemas.ActivateGiftcardSchema(t)),
  });

  const [sortOrder, setSortOrder] = useState(sortCardsOptions[0]?._id);
  const [cardStatus, setCardStatus] = useState('');

  const [salesChannelOptions, setSalesChannelOptions] = useState([]);
  const [createGiftcardModal, setCreateGiftcardModal] = useState({
    isOpen: false,
  });
  const [activateGiftcardModal, setActivateGiftcardModal] = useState({
    isOpen: false,
  });
  const [codeFilter, setCodeFilter] = useState({
    codeFrom: '',
    codeTo: '',
  });

  // const [dateFrom, setDateFrom] = useState('');
  // const [dateTo, setDateTo] = useState('');

  const [blockGiftcardModal, setBlockGiftcardModal] = useState({
    isOpen: false,
  });

  const [dataTable, setDataTable] = useState({
    limit: itemsPerpage[2],
    offset: 0, //Elementos que ya pasaron la pagina
    data: [],
    count: 0, //Cantidad de elementos
    page: 1,
    sinceDate: '',
    untilDate: '',
    isLoading: true,
  });

  const [isLoading, setIsloading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    let selected = [];

    for (let i = 0; i < dataTable?.data?.length; i++) {
      if (dataTable?.data[i]?.selected) {
        selected?.push(dataTable?.data[i]?._id);
      }
    }

    let dataToPost = { ...data };
    dataToPost.giftCards = selected;

    if (activateGiftcardModal?.selected) {
      dataToPost.giftCards = [activateGiftcardModal?.selected];
    }

    if (!isLoading) {
      setIsloading(true);
      giftCardService
        .activateGiftcard(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Activacion de giftcards exitosa`, 'success');
            setIsloading(false);
            handleActivateGiftcardForm();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  });

  const blockGiftcards = () => {
    let selected = [];

    for (let i = 0; i < dataTable?.data?.length; i++) {
      if (dataTable?.data[i]?.selected) {
        selected?.push(dataTable?.data[i]?._id);
      }
    }

    let dataToPost = { giftCards: selected };

    if (blockGiftcardModal?.selected) {
      dataToPost.giftCards = [blockGiftcardModal?.selected];
    }

    if (!isLoading) {
      setIsloading(true);
      giftCardService
        .blockGiftcard(dataToPost)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Bloqueo de giftcards exitoso`, 'success');
            setIsloading(false);
            handleBlockGiftcardForm();
            getCodes();
          } else {
            notify(`${response.message}`, 'error');
            setIsloading(false);
          }
        })
        .catch((error) => {
          setIsloading(false);
        });
    }
  };

  const selectitemsPerpage = (event) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: 1,
        offset: 0,
        limit: parseInt(event.target.value),
      };
    });
  };

  const handlePageChange = (event, value) => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        page: value,
        offset: (value - 1) * dataTable?.limit,
      };
    });
  };

  const redirectToOrder = (orderId) => {
    history.push(`/afiliations/order/${orderId}`);
  };

  useEffect(() => {
    getCodes();
  }, [
    dataTable?.page,
    dataTable?.offset,
    dataTable?.limit,
    dataTable?.type,
    sortOrder,
    cardStatus,
    codeFilter?.codeFrom,
    codeFilter?.codeTo,
  ]);

  useEffect(() => {
    getCodes();
    getSalesChannel();
  }, []);

  const getSalesChannel = () => {
    saleChannelService
      .getSalesChannel()
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          setSalesChannelOptions(response?.data?.saleChannels);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => { });
  };

  const getCodes = () => {
    setDataTable((prevState) => {
      return {
        ...prevState,
        isLoading: true,
      };
    });

    let params = {
      limit: dataTable?.limit,
      offset: dataTable?.offset,
      sortField: 'publicCode',
      sortOrder: sortOrder,
      status: cardStatus,
      codeFrom: codeFilter?.codeFrom,
      codeTo: codeFilter?.codeTo,
    };

    giftCardService
      .getGiftcards(filterParams(params))
      .then((response) => {
        if (response.status === 200) {
          for (let i = 0; i < response?.data?.giftcards.length; i++) {
            response.data.giftcards[i].selected = false;
          }

          setDataTable((prevState) => {
            return {
              ...prevState,
              data: response?.data?.giftcards,
              count: response?.data?.total,
              selectedType: '',
              isLoading: false,
            };
          });
        } else {
          notify(`${response.message}` || 'error', 'error');
          setDataTable((prevState) => {
            return {
              ...prevState,
              isLoading: false,
            };
          });
        }
      })
      .catch((error) => {
        setDataTable((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      });
    return true;
  };

  const selectAllGiftcards = (value, status) => {
    let datos = [...dataTable?.data];

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].status === status) {
        datos[i].selected = value;
      } else {
        datos[i].selected = false;
      }
    }

    setDataTable((prevState) => {
      return {
        ...prevState,
        data: datos,
        selectedType: value ? status : '',
      };
    });
  };

  const selectGiftcard = (giftcard, index) => {
    let datos = [...dataTable?.data];
    datos[index].selected = !datos[index].selected;

    let none = '';

    for (let i = 0; i < datos.length; i++) {
      if (datos[i].selected) {
        none = giftcard?.status;
      }
    }

    setDataTable((prevState) => {
      return {
        ...prevState,
        data: datos,
        selectedType: none,
      };
    });
  };


  const handleCreateGiftcardForm = () => {
    setCreateGiftcardModal((prevState) => {
      return {
        ...prevState,
        isOpen: !createGiftcardModal?.isOpen,
      };
    });
  };

  const handleActivateGiftcardForm = (giftcard) => {
    reset();
    setActivateGiftcardModal((prevState) => {
      return {
        ...prevState,
        isOpen: !activateGiftcardModal?.isOpen,
        selected: giftcard ? giftcard?._id : '',
      };
    });
  };

  const handleBlockGiftcardForm = (giftcard) => {
    reset();
    setBlockGiftcardModal((prevState) => {
      return {
        ...prevState,
        isOpen: !blockGiftcardModal?.isOpen,
        selected: giftcard ? giftcard?._id : '',
      };
    });
  };

  const handleSortOrder = (e) => {
    setSortOrder(e?.target?.value);
  };

  const handleCardStatus = (e) => {
    setCardStatus(e?.target?.value);
  };

  const handleCodeFilter = (e) => {
    const { name, value } = e.target;
    setCodeFilter((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  return (
    <>
      <ActivateGiftcardModal
        modal={activateGiftcardModal}
        handleModal={handleActivateGiftcardForm}
        options={{
          salesChannelOptions: salesChannelOptions,
          giftCardTypeOptions: giftCardTypeOptions,
        }}
        register={register}
        errors={errors}
        action={onSubmit}
      />
      <BlockGiftcardModal
        modal={blockGiftcardModal}
        handleModal={handleBlockGiftcardForm}
        register={register}
        errors={errors}
        action={blockGiftcards}
      />

      <CreateGiftcardLoadModal
        modal={createGiftcardModal}
        handleModal={handleCreateGiftcardForm}
        getCodes={getCodes}
      />
      <Header />
      <div className="service-with-container container">
        <div className="buttons-container">
          <h1 className="title-component">
            Código Giftcard: {dataTable?.count}
          </h1>
          <ActionButton
            action={() => handleCreateGiftcardForm()}
            label={'+ Nueva Carga'}
          />
        </div>
        <div className="breadcrumb-container">
          {/* <div className="exportables-buttons-container"></div> */}
          <div className="row">
            <div className="col-12 col-md-2 col-lg-1">
              <Select
                options={filterCardByStatusOptions}
                label={'Status'}
                value={cardStatus}
                onChange={handleCardStatus}
              />
            </div>
            <div className="col-12 col-md-2 col-lg-1">
              <Select
                options={sortCardsOptions}
                label={'Ordenar'}
                value={sortOrder}
                onChange={handleSortOrder}
              />
            </div>
            <div
              className={`input-component-container col-12 col-md-2 col-lg-1`}
            >
              <label htmlFor="codeFrom">Desde Código</label>
              <div>
                <div className="input-sublabel-container">
                  <input
                    id={'codeFrom'}
                    type={'number'}
                    name={'codeFrom'}
                    onChange={(e) => handleCodeFilter(e)}
                    value={codeFilter?.codeFrom}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div
              className={`input-component-container col-12 col-md-2 col-lg-1`}
            >
              <label htmlFor="codeFrom">Hasta Código</label>
              <div>
                <div className="input-sublabel-container">
                  <input
                    id={'codeTo'}
                    type={'number'}
                    name={'codeTo'}
                    onChange={(e) => handleCodeFilter(e)}
                    value={codeFilter?.codeTo}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="exportables-buttons-container">
                <div style={{ marginTop: '12px' }}>
                  <SelectPerPage
                    label={'Registros por página'}
                    options={itemsPerpage}
                    value={dataTable?.limit}
                    onChange={selectitemsPerpage}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div
                style={{ paddingTop: '24px' }}
                className="buttons-center-container"
              >
                <ActionButton
                  label={'Activar'}
                  action={() => handleActivateGiftcardForm()}
                />
                <AltButton
                  label={'Bloquear'}
                  action={() => handleBlockGiftcardForm()}
                />
              </div>
            </div>
          </div>
          <span></span>
        </div>

        <GiftcardCodesTables
          header={giftcardSalesCodeheader}
          data={dataTable}
          handlePageChange={handlePageChange}
          redirectToOrder={redirectToOrder}
          selectGiftcard={selectGiftcard}
          handleActivateGiftcardForm={handleActivateGiftcardForm}
          handleBlockGiftcardForm={handleBlockGiftcardForm}
          selectAllGiftcards={selectAllGiftcards}
        />
      </div>
    </>
  );
};
