import { Input } from "../../../common/Forms/Input/Input";
import { FileComponent } from "../../../common/Dropzone/Dropzone";
import {
  paymentMethodsLimit,
  paymentType as paymentTypeOption,
} from "../../../../assets/helpers/options";
import { casheaQuotaTime } from "../../../../assets/helpers/variables";
import moment from "moment";

export const SecondQuotaComponent = (props) => {
  const {
    watch,
    casheaPayment,
    register,
    errors,
    getUSDFromBs,
    getBsfromUSD,
    options,
    secondQuotaTime,
    paymentImage,
    isZelleOrPaypalPayment
  } = props;

  const quotas = [1, 1, 1];

  if (
    watch("paymentType") === paymentTypeOption?.CASHEA &&
    (casheaPayment?.transactionId || isZelleOrPaypalPayment)
  ) {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <h6>
              Pago inicial
              {'  ' +
                moment(new Date().setDate(new Date().getDate()))
                  .format('DD/MM/YYYY')}
            </h6>
          </div>

          {Array.from({ length: watch("paymentMethodsQuantity") }).map((a, i) => <>
            <Input
              bootstrap={'col-12 col-md-2'}
              label={`Monto Pago USD ${i + 1}*`}
              type={'number'}
              name={`amount${i + 1}`}
              register={register}
              placeholder={''}
              errors={errors?.[`amount${i + 1}`]?.message}
              disabled={true}
              onChange={getBsfromUSD}
              min={'0'}
            />
            <Input
              bootstrap={'col-12 col-md-2'}
              label={`Monto Pago BsS  ${i + 1}*`}
              type={'number'}
              name={`amountBsS${i + 1}`}
              register={register}
              placeholder={''}
              errors={errors?.[`amountBsS${i + 1}`]?.message}
              disabled={true}
              onChange={getUSDFromBs}
              min={'0'}
            />
            <Input
              bootstrap={'col-12 col-md-2'}
              label={`Id de transaccion  ${i + 1}*`}
              type={'text'}
              name={`reference${i + 1}`}
              register={register}
              placeholder={''}
              errors={errors?.[`reference${i + 1}`]?.message}
              disabled={true}
              min={'0'}
            />
            <Input
              bootstrap={'col-12 col-md-2'}
              label={`Referencia Cashea  ${i + 1}*`}
              type={'text'}
              name={`referenceCashea${i + 1}`}
              register={register}
              placeholder={''}
              errors={errors?.[`reference${i + 1}`]?.message}
              disabled={true}
              min={'0'}
            />
            <Input
              bootstrap={'col-12 col-md-2'}
              label={`Metodo de pago  ${i + 1}`}
              type={'select'}
              name={`method${i + 1}`}
              register={register}
              options={options?.paymemtMethodOptions}
              placeholder={''}
              errors={errors?.[`method${i + 1}`]?.message}
              disabled={true}
              min={'0'}
            />
            <div className="col-12">
              {paymentImage[`paymentImage${i + 1}`]?.name && <FileComponent width="300px" file={paymentImage[`paymentImage${i + 1}`]} />}
            </div>{' '}
          </>)}

        </div >

        {
          quotas.map((quota, i) => (
            <div className="row">
              <div className="col-12">
                <div className="col-12">
                  <h4>Fechas de pago</h4>
                </div>
                <h6>
                  {i + 1}° Cuota
                  {'  ' +
                    moment(
                      new Date().setDate(
                        new Date().getDate() + (i + 1) * casheaQuotaTime
                      )
                    ).format("DD/MM/YYYY")
                  } {" "}
                  <span style={{ color: "red" }}></span>
                </h6 >
              </div >
              <Input
                bootstrap={"col-12 col-md-2"}
                label={"Monto Pago 2 USD *"}
                type={"number"}
                name={"financialAmount2"}
                register={register}
                options={paymentMethodsLimit}
                placeholder={""}
                errors={errors?.financialAmount2?.message}
                disabled={true}
                onChange={getBsfromUSD}
                min={"0"}
              />
              <Input
                bootstrap={"col-12 col-md-2"}
                label={"Monto Pago 2 BsS *"}
                type={"number"}
                name={"financialAmountBsS2"}
                register={register}
                options={paymentMethodsLimit}
                placeholder={""}
                errors={errors?.financialAmountBsS2?.message}
                disabled={true}
                onChange={getUSDFromBs}
                min={"0"}
              />
            </div >
          ))}
      </>
    );
  }

  if (watch("paymentType") === paymentTypeOption?.FINANCIADO) {
    return (
      <div className="row">
        <div className="col-12">
          <div className="col-12">
            <h4>Fechas de pago</h4>
          </div>
          <h6>
            1° Cuota
            {'  ' +
              moment(new Date().setDate(new Date().getDate() + secondQuotaTime))
                .format('DD/MM/YYYY')}
          </h6 >
        </div >
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Monto Pago 2 USD *"}
          type={"number"}
          name={"financialAmount2"}
          register={register}
          options={paymentMethodsLimit}
          placeholder={""}
          errors={errors?.financialAmount2?.message}
          disabled={true}
          onChange={getBsfromUSD}
          min={"0"}
        />
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Monto Pago 2 BsS *"}
          type={"number"}
          name={"financialAmountBsS2"}
          register={register}
          options={paymentMethodsLimit}
          placeholder={""}
          errors={errors?.financialAmountBsS2?.message}
          disabled={true}
          onChange={getUSDFromBs}
          min={"0"}
        />
      </div >
    );
  } else {
    return null;
  }
};
