import { Input } from '../Input/Input';
import moment from 'moment';
import {
  paymentType,
  paymentModalTypes,
  paymentMethodsLimit,
  paymentMethods,
  initialOptions
} from '../../../../assets/helpers/options';
import { returnIGTF } from '../../../../assets/helpers/funciones';
import { secondQuotaTime } from '../../../../assets/helpers/variables';
import { CashBackComponent } from '../../../views/Landing/LandingComponents/LandingModals';
import { PaymentSection1 } from './PaymentSection1';
import { PaymentSection2 } from './PaymentSection2';
import './NewPaymentForm.scss';

export const NewPaymentForm = props => {
  const {
    register,
    options,
    errors,
    control,
    onChange,
    watch,
    returnAccountData,
    fileUploaded,
    setFileUploaded,
    setValue,
    handleDrop,
    cashBackImage,
    removeFile,
    errorsDropzone,
    membership,
  } = props;

  const handleIGTF = method => {
    if (method?.name === paymentMethods?.PAGO_MÓVIL_BANCAMIGA) {
      setValue(
        'phone1',
        membership?.customer?.phonePrefix?.slice(1) +
        membership?.customer?.phoneNumber
      );
    }

    if (method?.currency !== 'BsS' && method?.name !== paymentMethods?.CASHEA) {
      setValue('ShowIGTFMoldal', true);
    }
  };

  const handleIGTF2 = method => {
    if (method?.name === paymentMethods?.PAGO_MÓVIL_BANCAMIGA) {
      setValue(
        'phone2',
        membership?.customer?.phonePrefix?.slice(1) +
        membership?.customer?.phoneNumber
      );
    }

    if (method?.currency === 'BsS') {
      setValue('amount2IGTF', 0);
    }
    if (method?.currency !== 'BsS' && watch('mix')) {
      setValue('igtf2', true);
      setValue(
        'amount2IGTF',
        ((watch('amount2') * returnIGTF()) / 100)?.toFixed(2)
      );
    }
  };

  const acceptIGTFMethod = () => {
    setValue('ShowIGTFMoldal', false);
    setValue(
      'amount1IGTF',
      ((watch('amount1') * returnIGTF()) / 100).toFixed(2)
    );
    setValue('igtf1', true);
  };

  const denyIGTFMethod = () => {
    setValue('ShowIGTFMoldal', false);
    if (watch('mix')) {
      denyIGTFMultipleMethod();
    } else {
      denyIGTFSingleMethod();
    }
  };

  const denyIGTFSingleMethod = () => {
    setValue('paymentMethods', 2);

    // El segundo metodo asume el IGTF
    setValue('igtf2', true);
    setValue('amount2', ((watch('amount1') * returnIGTF()) / 100).toFixed(2));
    setValue(
      'amountBsS2',
      ((watch('amountBsS1') * returnIGTF()) / 100).toFixed(2)
    );
    setValue(
      'amount1_2IGTF',
      ((watch('amount1') * returnIGTF()) / 100).toFixed(2)
    );
  };

  const denyIGTFMultipleMethod = () => {
    setValue('paymentMethods', '2');
    // El segundo metodo asume el IGTF
    setValue('igtf2', true);
    setValue('amountBsS2', watch('amount2') * options?.dollarRate);
    setValue(
      'amount1_2IGTF',
      ((watch('amount1') * returnIGTF()) / 100).toFixed(2)
    );
  };

  return (
    <form className="form-container">
      <div className="row">
        <div className="col-12">
          <div className="row">
            <Input
              bootstrap={'col-4'}
              label={'Tipo de pago *'}
              type={'select'}
              name={'paymentType'}
              register={register}
              options={options?.paymentTypesOptions}
              placeholder={''}
              errors={errors?.paymentType?.message}
              min={'0'}
              onChange={onChange?.handlePaymentTypeForm}
              disabled={true}
            />
            <Input
              bootstrap={'col-4'}
              label={'Monto que paga el cliente USD *'}
              type={'number'}
              name={'price'}
              register={register}
              placeholder={''}
              errors={errors?.price?.message}
              min={'0'}
              onChange={onChange?.restorePaymentMethods}
              disabled={true}
            />

            {watch('price') >= 0 && (
              <Input
                bootstrap={'col-4'}
                label={'Cantidad de Métodos de Pago'}
                type={'select'}
                name={'paymentMethods'}
                register={register}
                placeholder={''}
                errors={errors?.paymentMethods?.message}
                options={paymentMethodsLimit}
                onChange={onChange?.handlePaymentMethodsChange}
                min={'1'}
                max={'2'}
                disabled={
                  watch('amount1IGTF') ||
                  watch('amount1_2IGTF')
                  //  || watch('paymentType') === paymentType?.CASHEA
                }
              />
            )}
            {watch('paymentType') === paymentType?.CASHEA && (
              <Input
                bootstrap={'col-12 col-md-2'}
                label={'Inicial *'}
                type={'select'}
                name={'initial'}
                options={initialOptions?.filter((o, i) => i !== 0)}
                register={register}
                placeholder={''}
                onChange={e => onChange?.handleCasheaAmount()}
                errors={errors?.initial?.message}
                min={'0'}
              />
            )}

          </div>
        </div>
        <div className="col-12">
          <div className="row">
            {watch('paymentType') === paymentType?.FINANCIADO &&
              watch('modal') !== paymentModalTypes?.PENDIENTE && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago 1era cuota:{' '}
                      {moment(new Date()).format('DD/MM/YYYY')}
                    </h5>
                  </div>
                </>
              )}

            <PaymentSection1
              register={register}
              options={options}
              errors={errors}
              control={control}
              watch={watch}
              onChange={{ ...onChange, handleIGTF }}
              fileUploaded={fileUploaded}
              setFileUploaded={setFileUploaded}
              returnAccountData={returnAccountData}
              index={1}
              acceptIGTFMethod={acceptIGTFMethod}
              denyIGTFMethod={denyIGTFMethod}
              membership={membership}
            />

            {(watch('paymentMethods') == 2 || watch('mix')) && (
              <PaymentSection2
                register={register}
                options={options}
                control={control}
                errors={errors}
                watch={watch}
                onChange={{ ...onChange, handleIGTF2 }}
                fileUploaded={fileUploaded}
                setFileUploaded={setFileUploaded}
                returnAccountData={returnAccountData}
                index={2}
              />
            )}
            <CashBackComponent
              register={register}
              errors={errors}
              watch={watch}
              options={{
                paymemtMethodOptions: options?.paymentMethods,
                filteredMethods: options?.filteredMethods,
              }}
              onChange={{
                handleCashbackBs: onChange?.handleCashbackBs,
                handleCashbackMethodsQuantity:
                  onChange?.handleCashbackMethodsQuantity,
              }}
              handleDrop={handleDrop}
              cashBackImage={cashBackImage}
              removeFile={removeFile}
              errorsDropzone={errorsDropzone}
            />

            {watch('paymentType') === paymentType?.FINANCIADO &&
              watch('modal') !== paymentModalTypes?.PENDIENTE && (
                <>
                  <div className="col-12">
                    <h5>
                      Fechas de pago 2da cuota:{' '}
                      {moment(
                        new Date().setDate(
                          new Date().getDate() + secondQuotaTime
                        )
                      )
                        .format('DD/MM/YYYY')}{' '}
                    </h5>
                  </div>
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (USD) '}
                    type={'number'}
                    name={'quota2USD'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2USD?.message}
                    disabled={true}
                  />{' '}
                  <Input
                    bootstrap={'col-6'}
                    label={'Monto 2 (Bs) '}
                    type={'number'}
                    name={'quota2BsS'}
                    register={register}
                    placeholder={''}
                    errors={errors?.quota2BsS?.message}
                    disabled={true}
                  />
                </>
              )}
          </div>
        </div>
      </div>
    </form>
  );
};
