import { useEffect, useState } from 'react';
import { Input, FilterDatePicker } from '../../../common/Forms/Input/Input';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as schemas from '../../../../assets/schemas/schemas';
import { ActionButton } from '../../../common/Buttons/Buttons';
import {
  MethodValidation,
  NaNtoNumber,
  clearObject,
  getCorrectDate,
  returnIGTF,
} from '../../../../assets/helpers/funciones';
import {
  paymentTypeOptions as paymentTypesOptions,
  paymentModalTypes,
  paymentType,
  fleetLoadRCVTypes,
  LANDING,
} from '../../../../assets/helpers/options';
import { useParams } from 'react-router-dom';
import { membershipCatalogService } from '../../../../services/membershipCatalogService';
import { rateService } from '../../../../services/rateService';
import { productService } from '../../../../services/productService';
import { membershipService } from '../../../../services/membershipService';
import { notify } from '../../../../assets/helpers/toast';
import { OderCardHeader } from './OrderComponents';
import { PaymentModal } from '../../../common/Modals/Modals';
import { RCVOrderCard } from './OrderComponents';
import { useHistory } from 'react-router-dom';
import { LoadSpinner } from '../../../common/LoadSpinner/LoadSpinner.jsx';
import './OrderComponent.scss';
import moment from 'moment';
import { statesService } from '../../../../services/statesService.js';
import {
  RcvHolderForm,
  RcvHolderFormForAddRcv,
} from '../../../common/Forms/Form.jsx';

const RCV_Alliance_TYPE = {
  FULL_PRICE: 'Precio full',
  SC_PRICE: 'Precio SC',
};

export const RCVInfoCard = (props) => {
  const { label, membership, getSalesMembershipById } = props;
  const { membershipId } = useParams();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [rcvBeginDate, setRCVBeginDate] = useState('');
  const [dollarRate, setDollarRate] = useState('');
  const [bsDisabled, setbsDisabled] = useState(true);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [useRcvAlliancePrice, setUseRcvAlliancePrice] = useState(
    RCV_Alliance_TYPE?.FULL_PRICE
  );

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schemas.upgradeOrderForm()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    getValues: getValues2,
    resetField: resetField2,
    watch: watch2,
    reset: reset2,
    control: control2,
  } = useForm({
    resolver: yupResolver(schemas.upgradeMembershipSchema()),
  });

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
          setValue('dollarRate', response?.data?.rate);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const getMembershipCatalog = (membership, alliance) => {
    let params = {
      year: membership?.vehicle?.year,
      rcv: watch('rcvType'),
      class: membership?.vehicle?.classId,
      plan: membership?.membershipCode,
      landing: LANDING?.PRIVATE,
      rcvEmissionDate: moment(watch('rcvEmissionDate')).format('YYYY-MM-DD'),
    };

    if (alliance === RCV_Alliance_TYPE?.FULL_PRICE) {
      params.useRcvAlliancePrice = false;
    }

    if (alliance === RCV_Alliance_TYPE?.SC_PRICE) {
      params.useRcvAlliancePrice = true;
    }

    membershipCatalogService
      .getAvailableMemberships(params)
      .then((response) => {
        if ([200, 201].includes(response?.status)) {
          let plan = response?.data[0];

          if (plan) {
            setValue('amount', plan?.rcvSurcharge);
            setTimeout(() => {
              setValue(
                'amountBsS',
                (
                  watch('amount') * dollarRate ||
                  watch('amount') * watch('dollarRate')
                )?.toFixed(2)
              );
            }, 1200);
          } else {
            notify(
              'No hay planes disponibles para el vehiculo seleccionado',
              'error'
            );
          }
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch((error) => {});
  };

  const handleUseRcvAlliancePrice = (e) => {
    if (e?.target?.value === RCV_Alliance_TYPE?.FULL_PRICE) {
      setValue('useRcvAlliancePrice', false);
      setUseRcvAlliancePrice(e?.target?.value);
      getMembershipCatalog(membership, e?.target?.value);
    }
    if (e?.target?.value === RCV_Alliance_TYPE?.SC_PRICE) {
      setValue('useRcvAlliancePrice', true);
      setUseRcvAlliancePrice(e?.target?.value);
      getMembershipCatalog(membership, e?.target?.value);
    }
  };

  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 1; i <= parseInt(watch2('paymentMethods')); i++) {
      let data = {};

      if (i === 1 && params?.igtf1) {
        data.igtf = true;
      }
      if (i === 2 && params?.igtf2) {
        data.igtf = true;
      }
      let amount1IGTF = NaNtoNumber(parseFloat(watch2('amount1IGTF')));
      let amount1_2IGTF = NaNtoNumber(parseFloat(watch2('amount1_2IGTF')));
      let amount2IGTF = NaNtoNumber(parseFloat(watch2('amount2IGTF')));

      data.amountIGTF = parseFloat(params[`amount` + i.toString()]);

      // Add IGTF 1 and raise flag
      if (i === 1 && amount1IGTF > 0) {
        data.igtf = true;
        data.amountIGTF =
          amount1IGTF + parseFloat(params[`amount` + i.toString()]);
      }

      // Add IGTF 1 in 2 or 2 and raise flag
      if (i !== 1 && (amount1_2IGTF > 0 || amount2IGTF > 0)) {
        data.igtf = true;
        data.amountIGTF =
          amount2IGTF + parseFloat(params[`amount` + i.toString()]);

        if (watch2('mix')) {
          data.amountIGTF = data.amountIGTF + amount1_2IGTF;
        }
      }

      data.amountIGTF = data.amountIGTF?.toFixed(2);

      data.method = params[`method` + i.toString()];
      data.reference = params[`reference` + i.toString()];
      data.amount = params[`amount` + i.toString()];
      data.owner = params[`owner` + i.toString()];
      data.amountBsS = params[`amountBsS` + i.toString()];

      payments.push({
        ...data,
      });
    }

    // "REmove unwanted params from JSON"
    let words = [
      'method',
      'reference',
      'amount',
      'amountBsS',
      'paymentMethod',
      'igtf',
      'amountIGTF',
    ];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const appendFile = (data) => {
    const formData = new FormData();
    let rcvHolder = {
      isVehicleDriver: watch('isVehicleDriver') === 'false' ? false : true,
      firstName: watch('rcvHolderFirstName'),
      lastName: watch('rcvHolderLastName'),
      fullName: `${watch('rcvHolderFirstName')} ${watch('rcvHolderLastName')}`,
      identification: watch('rcvHolderIdentification'),
      identificationType: watch('rcvHolderIdentificationType'),
      phonePrefix: watch('rcvHolderPhonePrefix'),
      phoneNumber: watch('rcvHolderPhoneNumber'),
      state: watch('rcvHolderState'),
      city: watch('rcvHolderCity'),
      address: watch('rcvHolderAddress'),
    };

    rcvHolder = clearObject(rcvHolder);
    if (
      rcvHolder.firstName === '' ||
      rcvHolder.firstName === undefined ||
      rcvHolder.lastName === '' ||
      rcvHolder.lastName === undefined
    ) {
      delete rcvHolder.fullName;
    }
    const rcvHolderJson = JSON.stringify(rcvHolder);
    formData.append('rcvHolder', rcvHolderJson);
    formData.append('rcv', watch('rcvType'));
    formData.append(
      'rcvEmissionDate',
      moment(watch('rcvEmissionDate')).format('YYYY-MM-DD')
    );
    formData.append('rcvBeginDate', moment(rcvBeginDate).format('YYYY-MM-DD'));
    formData.append(
      'rcvEndDate',
      moment(watch('rcvEndDate')).format('YYYY-MM-DD')
    );
    formData.append('paymentType', watch2('paymentType'));
    formData.append(
      'paymentStrategy',
      parseFloat(watch2('paymentMethods')) === 2 ? 'Pago mixto' : 'Pago único'
    );

    if (useRcvAlliancePrice === RCV_Alliance_TYPE?.FULL_PRICE) {
      formData.append('useRcvAlliancePrice', false);
    }

    if (useRcvAlliancePrice === RCV_Alliance_TYPE?.SC_PRICE) {
      formData.append('useRcvAlliancePrice', true);
    }

    if (watch('km')) {
      formData.append('km', watch('km'));
    }

    for (var i = 0; i < data?.payments.length; i++) {
      formData.append(`payments[${i}][amount]`, data?.payments[i].amountIGTF);
      formData.append(
        `payments[${i}][amountBsS]`,
        data?.payments[i].amountIGTF * dollarRate
      );
      if (data?.payments[i]?.igtf && returnIGTF() > 0) {
        formData.append(`payments[${i}][igtf]`, data?.payments[i]?.igtf);
      }
      formData.append(`payments[${i}][method]`, data?.payments[i].method);
      formData.append(`payments[${i}][reference]`, data?.payments[i].reference);
      formData.append(
        `payments[${i}][hasEvidence]`,
        fileUploaded[i] ? true : false
      );

      if (data?.payments[i]?.method === '646955f7fccc5f07cd2b8d48') {
        // for zelle payments
        formData.append(`payments[${i}][accHolder]`, data?.payments[i]?.owner);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch2('paymentMethods')) &&
      parseFloat(watch2('paymentMethods')) > 0 &&
      MethodValidation(data?.payments, fileUploaded, paymentMethods)
    ) {
      notify('Ingrese comprobantes de pago', 'info');
      return null;
    }
    if (parseFloat(watch2('price'))) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum < parseFloat(watch2('price'))) {
        notify(
          'Monto a pagar por el cliente y suma de los pagos no coinciden',
          'info'
        );
        return null;
      }
    }

    for (let i = 0; i < parseInt(watch2('paymentMethods')); i++) {
      // Do not upload the file if does not exists
      if (fileUploaded[i]) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    return formData;
  };

  const payUpgradeMembership = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      membershipService
        .addRCV(fileToPost, membership?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`RCV añadido con exito`, 'success');
            closePaymentModal();
            history.push(`/afiliations/edit-order/${membership?._id}`);
            setIsLoading(false);
          } else {
            notify(`${response.message}`, 'error');
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  });

  const getCities = (state) => {
    statesService
      .getCities(state ? state : watch('state'))
      .then((response) => {
        if (response.status === 200) {
          setCitiesOptions(response?.data?.cities);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch((error) => {});
    return true;
  };

  const handleCity = (e) => {
    getCities(e.target.value);
  };

  const getStates = () => {
    statesService
      .getStates()
      .then((response) => {
        if (response.status === 200) {
          let states = response?.data?.states;
          setStatesOptions(states);
        } else {
          notify(`${response.message}` || 'error', 'error');
        }
      })
      .catch(() => {});
  };

  const preloadForm = () => {
    if (membership?.rcvEndDateFromLastMembership) {
      setValue(
        `rcvEndDateFromLastMembership`,
        getCorrectDate(membership?.rcvEndDateFromLastMembership)
      );
      setValue('rcvEmissionDate', new Date());
      setRCVBeginDate(getCorrectDate(membership?.rcvEndDateFromLastMembership));
      const nextYear = getCorrectDate(membership?.rcvEndDateFromLastMembership);
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      setValue('rcvEndDate', nextYear);
    } else {
      setValue('rcvEmissionDate', new Date());
      setValue('rcvBeginDate', new Date());
      setRCVBeginDate(new Date());
      const nextYear = new Date();
      nextYear.setFullYear(nextYear.getFullYear() + 1);
      setValue('rcvEndDate', nextYear);
    }
    setValue('rcvType', fleetLoadRCVTypes?.RCV_BASIC);
  };

  const handleRCVEndDate = (e) => {
    setRCVBeginDate(e);
    setValue(
      'rcvEndDate',
      new Date(e).setFullYear(new Date(e).getFullYear() + 1)
    );
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods('sales')
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, 'error');
        }
      })
      .catch(() => {});
  };

  const closePaymentModal = () => {
    setOpenPayModal(false);
    setFileUploaded([]);
    reset2();
  };

  const handleAmountChange = (e, index) => {
    const { value } = e?.target;

    setValue2(`amountBsS${index}`, (value * dollarRate)?.toFixed(2));

    if (watch2('paymentType') === paymentType?.DE_CONTADO) {
      let price = parseFloat(watch2('price'));

      let amount2 = price - NaNtoNumber(parseFloat(watch2(`amount1`)));

      let quota2USD = price - amount2;

      if (amount2 <= 0) {
        setValue2('amount1', '');
        setValue2('amountBsS1', '');
        notify('Ingrese un monto valido para el pago', 'info');
      } else {
        setValue2('amount2', amount2);
        setValue2('amountBsS2', amount2 * dollarRate);
      }

      if (quota2USD < 0) {
        setValue2('amount1', '');
        setValue2('amountBsS1', '');
        notify('La suma de los montos excede el costo de la membresia', 'info');
      }
    }
  };

  const handleMembershipValueChange = () => {
    setValue('amountBsS', (watch('amount') * dollarRate).toFixed(2));
  };

  const handlePaymentMethodsChange = () => {
    if (parseFloat(watch2('paymentMethods')) === 2) {
      setValue2('mix', true);
      setValue2('method1', '');
      setValue2('amount1', '');
      setValue2('amountBsS1', '');
      setValue2('method2', '');
      setValue2('amount2', '');
      setValue2('amountBsS2', '');
    } else {
      setValue2('mix', false);
    }
  };

  const handleSecondAmountChange = (e, index) => {
    // No logic nextWednesday, input blocked
  };

  const restorePaymentMethods = () => {
    if (parseFloat(watch2('price')) === 0) {
      for (let i = 0; i < parseInt(watch2('paymentMethods')); i++) {
        resetField2(`method${i}`);
        resetField2(`reference${i}`);
        resetField2(`paymentMethod${i}`);
        resetField2(`amount${i}`);
        resetField2(`amountBsS${i}`);
      }
      resetField2('paymentMethods');
    }
    // setValue2()
  };

  const handlePaymentTypeForm = () => {
    // No logic nextWednesday, input blocked
  };

  const openRCVModal = () => {
    setOpenPayModal(true);
    setValue2('modal', paymentModalTypes?.PENDIENTE);
    setValue2('price', parseFloat(watch('amount'))?.toFixed(2));
    setValue2('amount1', watch('amount'));
    setValue2('amountBsS1', watch('amount') * dollarRate);
    setValue2('paymentType', paymentType?.DE_CONTADO);
    setValue2('paymentMethods', 1);
  };

  useEffect(() => {
    Promise.allSettled([
      getDollarRate(),
      preloadForm(),
      getStates(),
      getPaymentMethods(),
    ]).then((r) => {
      setTimeout(() => {}, 1500);
    });
    // preloadKm()
  }, [open]);

  useEffect(() => {
    getMembershipCatalog(membership, useRcvAlliancePrice);
  }, [watch('rcvEmissionDate')]);

  return (
    <>
      <LoadSpinner show={isLoading} />
      <div className="col-12 col-lg-9">
        <PaymentModal
          show={openPayModal}
          register={register2}
          openPayModal={openPayModal}
          handleClose={() => closePaymentModal()}
          payRewenMembership={payUpgradeMembership}
          errors={errors2}
          control={control2}
          setValue={setValue2}
          options={{
            paymentMethods: paymentMethods,
            paymentTypesOptions: paymentTypesOptions,
            dollarRate: dollarRate,
          }}
          onChange={{
            handlePaymentTypeForm: handlePaymentTypeForm,
            restorePaymentMethods: restorePaymentMethods,
            handleAmountChange: handleAmountChange,
            handleSecondAmountChange: handleSecondAmountChange,
            handlePaymentMethodsChange: handlePaymentMethodsChange,
          }}
          watch={watch2}
          setFileUploaded={setFileUploaded}
          fileUploaded={fileUploaded}
          bsDisabled={bsDisabled}
        />

        <div className="order-card">
          <OderCardHeader open={open} setOpen={setOpen} label={label} />

          {open && (
            <>
              <br />

              <div style={{ width: '100%' }} className="row">
                <div className="col-12 col-md-4">
                  <div className="row">
                    <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
                    <h6 style={{ margin: '6px 12px 12px 0px' }}>
                      DATOS DEL CLIENTE
                    </h6>
                    <Input
                      bootstrap={'col-12'}
                      label={'Tipo de RCV'}
                      type={'select'}
                      options={[
                        {
                          _id: fleetLoadRCVTypes?.RCV_BASIC,
                          name: fleetLoadRCVTypes?.RCV_BASIC,
                        },
                      ]}
                      name={'rcvType'}
                      register={register}
                      placeholder={''}
                      errors={errors?.rcvType?.message}
                    />
                    {watch('rcvType') && watch('rcvType') !== 'N/A' && (
                      <div className="col-6">
                        <RcvHolderFormForAddRcv
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          watch={watch}
                          citiesOptions={citiesOptions}
                          statesOptions={statesOptions}
                          handleCity={handleCity}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
                    <h6 style={{ margin: '6px 12px 12px 0px' }}>
                      DATOS DEL PLAN{' '}
                    </h6>
                    <Input
                      bootstrap={'col-12'}
                      label={'Fecha fin anterior RCV'}
                      type={'date'}
                      name={'rcvEndDateFromLastMembership'}
                      register={register}
                      placeholder={''}
                      control={control}
                      disabled={true}
                      errors={errors?.rcvEndDateFromLastMembership?.message}
                      // minDate={determinValidDate()}
                      // onChange={handleCreationDateChange}
                    />
                    <Input
                      bootstrap={'col-12'}
                      label={'Fecha emisión de RCV'}
                      type={'date'}
                      name={'rcvEmissionDate'}
                      register={register}
                      placeholder={''}
                      control={control}
                      errors={errors?.rcvEmissionDate?.message}
                    />
                    <Input
                      bootstrap={'col-12'}
                      label={'Inicio RCV'}
                      type={'date'}
                      name={'rcvBeginDate'}
                      register={register}
                      placeholder={''}
                      control={control}
                      errors={errors?.rcvBeginDate?.message}
                      onChange={handleRCVEndDate}
                    />
                    <FilterDatePicker
                      bootstrap={'col-12'}
                      label={'Desde'}
                      type={'date'}
                      name={'rcvBeginDate'}
                      placeholder={''}
                      onChange={handleRCVEndDate}
                      value={rcvBeginDate}
                    />
                    <Input
                      bootstrap={'col-12'}
                      label={'Fin RCV'}
                      type={'date'}
                      name={'rcvEndDate'}
                      register={register}
                      placeholder={''}
                      control={control}
                      errors={errors?.rcvEndDate?.message}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="row">
                    <p style={{ margin: '0px' }}>(*) Campos obligatorios</p>
                    <h6 style={{ margin: '6px 12px 12px 0px' }}>
                      DATOS DEL PAGO{' '}
                    </h6>

                    <div className="col-12 col-md-6 checbox-input-container">
                      <div className="checbox-input-container-midle">
                        <div className="topping">
                          <input
                            type="checkbox"
                            id="topping"
                            name="useRcvAlliancePrice"
                            value={RCV_Alliance_TYPE?.FULL_PRICE}
                            checked={
                              useRcvAlliancePrice ===
                              RCV_Alliance_TYPE?.FULL_PRICE
                            }
                            onChange={handleUseRcvAlliancePrice}
                          />
                          {RCV_Alliance_TYPE?.FULL_PRICE}
                        </div>{' '}
                        <div className="topping">
                          <input
                            type="checkbox"
                            id="topping"
                            name="useRcvAlliancePrice"
                            value={RCV_Alliance_TYPE?.SC_PRICE}
                            checked={
                              useRcvAlliancePrice ===
                              RCV_Alliance_TYPE?.SC_PRICE
                            }
                            onChange={handleUseRcvAlliancePrice}
                          />
                          {RCV_Alliance_TYPE?.SC_PRICE}
                        </div>
                      </div>
                    </div>
                    {useRcvAlliancePrice && (
                      <>
                        <Input
                          bootstrap={'col-12 '}
                          label={'Monto USD *'}
                          type={'number'}
                          name={'amount'}
                          register={register}
                          placeholder={''}
                          errors={errors?.amount?.message}
                          onChange={handleMembershipValueChange}
                          min={'0'}
                          disabled={true}
                        />
                        <Input
                          bootstrap={'col-12 '}
                          label={'Monto BsS *'}
                          type={'number'}
                          name={'amountBsS'}
                          register={register}
                          placeholder={''}
                          errors={errors?.amountBsS?.message}
                          disabled={true}
                        />
                        {watch('amount') && watch('amountBsS') && (
                          <div className="buttons-center-container">
                            <ActionButton
                              label={'Pagar'}
                              action={() => openRCVModal()}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <RCVOrderCard
          membership={membership}
          refresh={() => getSalesMembershipById(membershipId)}
          hideCancelButton={true}
          amount={watch('amount')}
        />{' '}
      </div>
    </>
  );
};
