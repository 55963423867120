import React from "react";
import { Switch, Route } from "react-router-dom";
import { PageNotFound } from "./components/views/PageNotFound/PageNotFound";
import { ProtectedRoute } from "./components/common/ProtectedRoute/ProtectedRoute";
import { Login } from "./components/views/Login/Login";
import { Analytics } from "./components/views/Analytics/Analytics";
import { Giftcards } from "./components/views/Giftcards/Giftcards";
import { GiftcardsCodes } from "./components/views/GiftcardsCodes/GiftcardsCodes";
import { FleetRegister } from "./components/views/FleetRegister/FleetRegister";
import { FleetDetail } from "./components/views/FleetRegister/FleetDetail";
import { FleetLoadForm } from "./components/views/FleetRegister/FleetLoadForm";
import { FleetLoadDetails } from "./components/views/FleetRegister/FleetLoadDetails";
import { Landing } from "./components/views/Landing/Landing";
import { LandingFree } from "./components/views/LandingFree/LandingFree";
import { QRLanding } from "./components/views/QRLanding/QRLanding";
import { RetailLanding } from "./components/views/RetailLanding/RetailLanding";
import { LinkLanding } from "./components/views/LinkLanding/LinkLanding";
import { Cashea } from "./components/views/Cashea/Cashea";
import { AffiliationsDB } from "./components/views/AffiliationsDB/AffiliationsDB";
import { RenewalDB } from "./components/views/AffiliationsDB/RenewalDB";
import { Order } from "./components/views/AffiliationsDB/Order/Order";
import { UpgradeOrder } from "./components/views/AffiliationsDB/Order/UpgradeOrder";
import { RCVInfo } from "./components/views/AffiliationsDB/Order/RCVInfo";
import { OrderDetail } from "./components/views/AffiliationsDB/Order/OrderDetail";
import { SalesUsers } from "./components/views/SalesUsers/SalesUsers";
import { RateDate } from "./components/views/RateDate/RateDate";
import { Parameters } from "./components/views/Parameters/Parameters";
import { UpdateHistory } from "./components/views/UpdateHistory/UpdateHistory";
import { DiscountCode } from "./components/views/DiscountCode/DiscountCode";
import { Exportables } from "./components/views/Exportables/Exportables";
import EmailAuth from "./components/views/EmailAuth/EmailAuth";

import "react-toastify/dist/ReactToastify.css";
import { salesRoles } from "./assets/helpers/roles";
import "./App.scss";

function App() {
  const {
    SALES_DEVELOPER,
    SALES_ADMIN,
    SALES_SUPERVISOR,
    SALES_HUNTER,
    SALES_FARMER,
    SALES_COUNTER,
    SALES_MARKETING,
    SALES_ADMIN_AUX,
    RCV,
  } = salesRoles;

  return (
    <React.Fragment>
      <div className="app-content">
        <div>
          <Switch>
            <Route
              path="/login"
              render={(props) => {
                return <Login {...props} props={props} />;
              }}
            />
            {/* Modulo de Analiticas */}
            <Route
              path="/landing/:saleChannel"
              render={(props) => {
                return <Landing {...props} props={props} />;
              }}
            />
            <Route
              path="/affiliation/:linkToken"
              render={(props) => {
                return <LinkLanding {...props} props={props} />;
              }}
            />
            <Route
              path="/affiliation-plan/apply-codes"
              render={(props) => {
                return <LandingFree {...props} props={props} />;
              }}
            />
            <Route
              path="/affiliation-link/:affiliationType/:linkToken/:saleChannel"
              render={(props) => {
                return <QRLanding {...props} affiliation-link props={props} />;
              }}
            />
            <Route
              path="/retail/:saleChannel"
              render={(props) => {
                return (
                  <RetailLanding {...props} affiliation-link props={props} />
                );
              }}
            />
            <ProtectedRoute
              path="/afiliations/status/:statusName/:statusId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={AffiliationsDB}
            />
            <ProtectedRoute
              path="/dashboard/afiliations/renewal/:statusId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
              ]} // Tipos de usuario autorizados a esta ruta
              component={RenewalDB}
            />
            <ProtectedRoute
              path="/afiliations/edit-order/:membershipId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Order}
            />
            <ProtectedRoute
              path="/afiliations/upgrade-order/:membershipId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
              ]} // Tipos de usuario autorizados a esta ruta
              component={UpgradeOrder}
            />
            <ProtectedRoute
              path="/afiliations/rcv/:membershipId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
              ]} // Tipos de usuario autorizados a esta ruta
              component={RCVInfo}
            />
            <ProtectedRoute
              path="/afiliations/order-details/:membershipId/vehicle/:vehicleId"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={OrderDetail}
            />
            {/* Dashboard*/}
            <ProtectedRoute
              path="/dashboard/analytics"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Analytics}
            />
            <ProtectedRoute
              path="/dashboard/exportable"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_ADMIN_AUX,
                RCV,
                // SALES_COUNTER,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Exportables}
            />
            {/* Modulo de Giftcards */}
            <ProtectedRoute
              path="/modules/cashea"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                // Solo Venta
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Cashea}
            />
            <ProtectedRoute
              path="/modules/giftcard-sales"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_MARKETING,
                // Solo Venta
                SALES_ADMIN_AUX,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
              ]} // Tipos de usuario autorizados a esta ruta
              component={Giftcards}
            />
            <ProtectedRoute
              path="/modules/giftcard-codes"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_MARKETING,
              ]} // Tipos de usuario autorizados a esta ruta
              component={GiftcardsCodes}
            />
            {/* Modulo de Flotas */}
            <ProtectedRoute
              path="/modules/fleet-register/:fleetId/load-detail/:loadId"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={FleetLoadDetails}
            />
            <ProtectedRoute
              path="/modules/fleet-register/fleet-load-form/:fleetId"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={FleetLoadForm}
            />
            <ProtectedRoute
              path="/modules/fleet-register/detail/:fleetId"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={FleetDetail}
            />
            <ProtectedRoute
              path="/modules/fleet-register"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={FleetRegister}
            />
            {/* Configurations */}
            <ProtectedRoute
              path="/config/exchange-rate"
              level={[
                SALES_DEVELOPER,
                SALES_ADMIN,
                SALES_SUPERVISOR,
                SALES_HUNTER,
                SALES_FARMER,
                SALES_COUNTER,
                SALES_ADMIN_AUX,
              ]} // Tipos de usuario autorizados a esta ruta
              component={RateDate}
            />
            <ProtectedRoute
              path="/config/parameters"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={Parameters}
            />
            <ProtectedRoute
              path="/config/update-history"
              level={[SALES_DEVELOPER, SALES_ADMIN, SALES_SUPERVISOR]} // Tipos de usuario autorizados a esta ruta
              component={UpdateHistory}
            />
            <ProtectedRoute
              path="/config/users"
              level={[SALES_DEVELOPER, SALES_ADMIN]} // Tipos de usuario autorizados a esta ruta
              component={SalesUsers}
            />
            <ProtectedRoute
              path="/config/discounts"
              level={[SALES_DEVELOPER, SALES_ADMIN]} // Tipos de usuario autorizados a esta ruta
              component={DiscountCode}
            />
            <ProtectedRoute
              path="/config/email-Auth"
              level={[SALES_ADMIN]} // Tipos de usuario autorizados a esta ruta
              component={EmailAuth}
            />
            <Route
              path="/not-found"
              render={(props) => {
                return <PageNotFound {...props} props={props} />;
              }}
            />
            <Route
              path="/"
              render={(props) => {
                return <Landing {...props} props={props} />;
              }}
            />
            <Route component={PageNotFound} />
          </Switch>
        </div>
      </div>
    </React.Fragment>
  );
}

export default App;
