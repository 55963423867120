import { useEffect, useState } from "react";
import { Input } from "../../../common/Forms/Input/Input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as schemas from "../../../../assets/schemas/schemas";
import { ActionButton } from "../../../common/Buttons/Buttons";
import {
  capitalize,
  MethodValidation,
  NaNtoNumber,
  returnIGTF,
} from "../../../../assets/helpers/funciones";
import {
  idCodes,
  phoneCodes,
  paymentTypeOptions as paymentTypesOptions,
  paymentModalTypes,
  paymentType,
  planTypes,
  KMServiceOptions,
} from "../../../../assets/helpers/options";
import { bsBlocked } from "../../../../assets/helpers/const";
import { membershipCatalogService } from "../../../../services/membershipCatalogService";
import { rateService } from "../../../../services/rateService";
import { productService } from "../../../../services/productService";
import { membershipService } from "../../../../services/membershipService";
import authService from "../../../../services/authService";
import { notify } from "../../../../assets/helpers/toast";
import { salesRoles } from "../../../../assets/helpers/roles";
import { OderCardHeader } from "./OrderComponents";
import { PaymentModal } from "../../../common/Modals/Modals";
import { useHistory } from "react-router-dom";
import "./OrderComponent.scss";

export const UpgradeInfoCard = (props) => {
  const { label, membership } = props;
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [plansOptions, setPlansOptions] = useState([]);
  const [dollarRate, setDollarRate] = useState("");
  const [bsDisabled, setbsDisabled] = useState(true);
  const [openPayModal, setOpenPayModal] = useState(false);
  const [fileUploaded, setFileUploaded] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm({
    resolver: yupResolver(schemas.upgradeOrderForm()),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    setValue: setValue2,
    resetField: resetField2,
    watch: watch2,
    reset: reset2,
    control: control2,
  } = useForm({
    resolver: yupResolver(schemas.upgradeMembershipSchema()),
  });

  const getDollarRate = () => {
    rateService
      .getDollarRate()
      .then((response) => {
        if (response.status === 200) {
          setDollarRate(response?.data?.rate);
        } else {
          notify(`${response.message}` || "error", "error");
        }
      })
      .catch(() => {});
  };

  let loadPaymentForms = (params) => {
    let payments = [];
    // lOOP through every payment method
    for (let i = 1; i <= parseInt(watch2("paymentMethods")); i++) {
      let data = {};

      if (i === 1 && params?.igtf1) {
        data.igtf = true;
      }
      if (i === 2 && params?.igtf2) {
        data.igtf = true;
      }
      let amount1IGTF = NaNtoNumber(parseFloat(watch2("amount1IGTF")));
      let amount1_2IGTF = NaNtoNumber(parseFloat(watch2("amount1_2IGTF")));
      let amount2IGTF = NaNtoNumber(parseFloat(watch2("amount2IGTF")));

      data.amountIGTF = parseFloat(params[`amount` + i.toString()]);

      // Add IGTF 1 and raise flag
      if (i === 1 && amount1IGTF > 0) {
        data.igtf = true;
        data.amountIGTF =
          amount1IGTF + parseFloat(params[`amount` + i.toString()]);
      }

      // Add IGTF 1 in 2 or 2 and raise flag
      if (i !== 1 && (amount1_2IGTF > 0 || amount2IGTF > 0)) {
        data.igtf = true;
        data.amountIGTF =
          amount2IGTF + parseFloat(params[`amount` + i.toString()]);

        if (watch2("mix")) {
          data.amountIGTF = data.amountIGTF + amount1_2IGTF;
        }
      }

      data.amountIGTF = data.amountIGTF?.toFixed(2);

      data.method = params[`method` + i.toString()];
      data.reference = params[`reference` + i.toString()];
      data.amount = params[`amount` + i.toString()];
      data.owner = params[`owner` + i.toString()];
      data.amountBsS = params[`amountBsS` + i.toString()];

      payments.push({
        ...data,
      });
    }

    // "REmove unwanted params from JSON"
    let words = [
      "method",
      "reference",
      "amount",
      "amountBsS",
      "paymentMethod",
      "igtf",
      "amountIGTF",
    ];
    for (const key in params) {
      for (var i = 0, ln = words.length; i < ln; i++) {
        if (key.indexOf(words[i]) !== -1) {
          delete params[key];
        }
      }
    }
    params.payments = payments;
    return params;
  };

  const appendFile = (data) => {
    const formData = new FormData();
    formData.append("newCode", watch("newCode"));
    formData.append("amount", watch("amount"));
    formData.append("amountBsS", watch("amountBsS"));
    if (watch("km")) {
      formData.append("km", watch("km"));
    }
    if (watch("hasExtendedTow")) {
      formData.append("useExtendedTow", watch("hasExtendedTow"));
    }
    formData.append("paymentType", watch2("paymentType"));
    formData.append(
      "paymentStrategy",
      parseFloat(watch2("paymentMethods")) === 2 ? "Pago mixto" : "Pago único"
    );

    for (var i = 0; i < data?.payments.length; i++) {
      formData.append(`payments[${i}][amount]`, data?.payments[i].amountIGTF);
      formData.append(
        `payments[${i}][amountBsS]`,
        data?.payments[i].amountIGTF * dollarRate
      );

      formData.append(
        `payments[${i}][hasEvidence]`,
        fileUploaded[i] ? true : false
      );
      if (data?.payments[i]?.igtf && returnIGTF() > 0) {
        formData.append(`payments[${i}][igtf]`, data?.payments[i]?.igtf);
      }
      formData.append(`payments[${i}][method]`, data?.payments[i].method);
      formData.append(`payments[${i}][reference]`, data?.payments[i].reference);
      if (data?.payments[i]?.method === "646955f7fccc5f07cd2b8d48") {
        // for zelle payments
        formData.append(`payments[${i}][accHolder]`, data?.payments[i]?.owner);
      }
    }

    // Load as many files as payment methods we have
    if (
      fileUploaded.length !== parseFloat(watch2("paymentMethods")) &&
      parseFloat(watch2("paymentMethods")) > 0 &&
      MethodValidation(data?.payments, fileUploaded, paymentMethods)
    ) {
      notify("Ingrese comprobantes de pago", "info");
      return null;
    }
    if (parseFloat(watch2("price"))) {
      let totalSum = 0;
      for (let i = 0; i < data?.payments.length; i++) {
        totalSum = totalSum + parseFloat(data?.payments[i].amount);
      }
      if (totalSum < parseFloat(watch2("price"))) {
        notify(
          "Monto a pagar por el cliente y suma de los pagos no coinciden",
          "info"
        );
        return null;
      }
    }

    for (let i = 0; i < parseInt(watch2("paymentMethods")); i++) {
      // Do not upload the file if does not exists
      if (fileUploaded[i]) {
        formData.append(`paymentEvidence`, fileUploaded[i]);
      }
    }

    return formData;
  };

  const payUpgradeMembership = handleSubmit2(async (data) => {
    let dataToPost = { ...data };
    dataToPost = loadPaymentForms(dataToPost);
    let fileToPost = appendFile(dataToPost);
    if (!isLoading && fileToPost) {
      setIsLoading(true);
      membershipService
        .upgradeMembership(fileToPost, membership?._id)
        .then((response) => {
          if ([200, 201].includes(response.status)) {
            notify(`Upgrade de membresia exitoso`, "success");
            closePaymentModal();
            history.push(`/afiliations/edit-order/${membership?._id}`);
            setIsLoading(false);
          } else {
            notify(`${response.message}`, "error");
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
        });
    }
  });

  const preloadForm = () => {
    setValue("firstName", capitalize(membership?.customer?.firstName));
    setValue("lastName", capitalize(membership?.customer?.lastName));
    setValue(
      "identificationType",
      capitalize(membership?.customer?.identificationType)
    );
    setValue(
      "identification",
      capitalize(membership?.customer?.identification)
    );
    setValue("phonePrefix", membership?.customer?.phonePrefix);
    setValue("phoneNumber", membership?.customer?.phoneNumber);
    setValue("email", membership?.customer?.email?.toLowerCase());
    setValue("previousPlan", capitalize(membership?.membershipPlan));
    setValue("hasExtendedTow", membership?.hasExtendedTow);
    const user = authService?.getUser();
    setValue("seller", capitalize(user?.firstName + " " + user?.lastName));
  };

  const getUpgradeMembership = () => {
    membershipCatalogService
      .getUpgradeMembership(membership?.membershipCode)
      .then((response) => {
        if (response.status === 200) {
          // setStatesOptions(response?.data?.states);
          let data = [];

          for (let i = 0; i < response?.data?.length; i++) {
            data.push({
              name: response?.data[i]?.code,
              priceGap: response?.data[i]?.priceGap,
              _id: response?.data[i]?._id,
            });
          }
          setPlansOptions(data);

          if ([planTypes?.GOLD]?.includes(membership?.membershipPlan)) {
            const [GOLD] = response?.data?.filter(
              (r) => r?.code === planTypes?.GOLD
            );
            setValue("newCode", GOLD?._id);
          }
        } else {
          notify(`${response.message}` || "error", "error");
        }
      })
      .catch(() => {});
  };

  const validateBs = () => {
    if (
      [salesRoles.SALES_ADMIN, salesRoles?.SALES_ADMIN_AUX].includes(
        authService.getUserType()
      ) &&
      !bsBlocked
    ) {
      return false;
    } else return true;
  };

  const setBsRate = (e) => {
    const { name, value } = e?.target;

    if (!dollarRate) {
      setbsDisabled(false);
    }

    if (name.includes("amount")) {
      setValue2(
        name.replace("amount", "amountBsS"),
        (value * dollarRate).toFixed(2)
      );
    }
  };

  const getPaymentMethods = () => {
    productService
      .getPaymentMethods("sales")
      .then((response) => {
        if (response.status === 200) {
          setPaymentMethods(response?.data);
        } else {
          notify(`${response.message}`, "error");
        }
      })
      .catch(() => {});
  };

  const handlePlanChange = (plan) => {
    let [selectedPlan] = plansOptions?.filter((p) => p?._id === plan);

    setValue("km", "");
    if (selectedPlan?.name === planTypes?.GOLD && membership?.km < 200) {
      setValue("km", 200);
    }

    setValue("amount", selectedPlan?.priceGap);
    setValue("amountBsS", (selectedPlan?.priceGap * dollarRate).toFixed(2));
  };

  const closePaymentModal = () => {
    setOpenPayModal(false);
    setFileUploaded([]);
    reset2();
  };

  const openUpgradeModal = () => {
    const [GOLD] = plansOptions?.filter((r) => r?.name === planTypes?.GOLD);

    if (GOLD?._id === watch("newCode") && !watch("km")) {
      notify("Debe seleccionar los Km de servicio de grúa", "info");
      return null;
    }

    setOpenPayModal(true);
    setValue2("modal", paymentModalTypes?.PENDIENTE);
    setValue2("price", parseFloat(watch("amount"))?.toFixed(2));
    setValue2("amount1", watch("amount"));
    setValue2("amountBsS1", watch("amount") * dollarRate);
    setValue2("paymentType", paymentType?.DE_CONTADO);
    setValue2("paymentMethods", 1);
  };

  const handleAmountChange = (e, index) => {
    const { value } = e?.target;

    setValue2(`amountBsS${index}`, (value * dollarRate)?.toFixed(2));

    if (watch2("paymentType") === paymentType?.DE_CONTADO) {
      let price = parseFloat(watch2("price"));

      let amount2 = price - NaNtoNumber(parseFloat(watch2(`amount1`)));

      let quota2USD = price - amount2;

      if (amount2 <= 0) {
        setValue2("amount1", "");
        setValue2("amountBsS1", "");
        notify("Ingrese un monto valido para el pago", "info");
      } else {
        setValue2("amount2", amount2);
        setValue2("amountBsS2", amount2 * dollarRate);
      }

      if (quota2USD < 0) {
        setValue2("amount1", "");
        setValue2("amountBsS1", "");
        notify("La suma de los montos excede el costo de la membresia", "info");
      }
    }
  };

  const handleMembershipValueChange = () => {
    setValue("amountBsS", (watch("amount") * dollarRate).toFixed(2));
  };

  const handlePaymentMethodsChange = () => {
    if (parseFloat(watch2("paymentMethods")) === 2) {
      setValue2("mix", true);
      setValue2("method1", "");
      setValue2("amount1", "");
      setValue2("amountBsS1", "");
      setValue2("method2", "");
      setValue2("amount2", "");
      setValue2("amountBsS2", "");
    } else {
      setValue2("mix", false);
    }
  };

  const handleSecondAmountChange = (e, index) => {
    // No logic nextWednesday, input blocked
  };

  const restorePaymentMethods = () => {
    if (parseFloat(watch2("price")) === 0) {
      for (let i = 0; i < parseInt(watch2("paymentMethods")); i++) {
        resetField2(`method${i}`);
        resetField2(`reference${i}`);
        resetField2(`paymentMethod${i}`);
        resetField2(`amount${i}`);
        resetField2(`amountBsS${i}`);
      }
      resetField2("paymentMethods");
    }
    // setValue2()
  };

  const handlePaymentTypeForm = () => {
    // No logic nextWednesday, input blocked
  };

  // const preloadKm = () => {
  //   if ([planTypes?.GOLD]?.includes(membership?.membershipPlan) && membership?.km) {
  //     setValue('km', membership?.km)
  //   } else {
  //     setValue('km', KMServiceOptions[0]?._id)
  //   }
  // }

  const validateIfShowKM = () => {
    const [GOLD] = plansOptions?.filter((r) => r?.name === planTypes?.GOLD);
    if (GOLD?._id === watch("newCode")) {
      return true;
    } else {
      return false;
    }
  };

  const filterKMOptions = (options) => {
    if (membership?.membershipPlan === planTypes?.GOLD && !membership?.km) {
      let no150 = options.filter((r) => r?._id > 150);
      return no150;
    }

    if (membership?.km) {
      let aver = options.filter((r) => r?._id >= membership?.km);
      return aver;
    }
    return options;
  };

  useEffect(() => {
    getPaymentMethods();
    getUpgradeMembership();
    preloadForm();
    getDollarRate();
    // preloadKm()
  }, [open]);

  return (
    <>
      <PaymentModal
        show={openPayModal}
        register={register2}
        openPayModal={openPayModal}
        handleClose={() => closePaymentModal()}
        payRewenMembership={payUpgradeMembership}
        errors={errors2}
        control={control2}
        setValue={setValue2}
        options={{
          paymentMethods: paymentMethods,
          paymentTypesOptions: paymentTypesOptions,
          dollarRate: dollarRate,
        }}
        onChange={{
          handlePaymentTypeForm: handlePaymentTypeForm,
          restorePaymentMethods: restorePaymentMethods,
          handleAmountChange: handleAmountChange,
          handleSecondAmountChange: handleSecondAmountChange,
          handlePaymentMethodsChange: handlePaymentMethodsChange,
        }}
        watch={watch2}
        setFileUploaded={setFileUploaded}
        fileUploaded={fileUploaded}
        bsDisabled={bsDisabled}
      />

      <div className="order-card">
        <OderCardHeader open={open} setOpen={setOpen} label={label} />

        {open && (
          <>
            <br />

            <div style={{ width: "100%" }} className="row">
              <div className="col-12 col-md-4">
                <div className="row">
                  <p style={{ margin: "0px" }}>(*) Campos obligatorios</p>
                  <h6 style={{ margin: "6px 12px 12px 0px" }}>
                    DATOS DEL CLIENTE
                  </h6>
                  <Input
                    bootstrap={"col-12 col-md-6"}
                    label={"Nombre *"}
                    type={"text"}
                    name={"firstName"}
                    register={register}
                    placeholder={""}
                    errors={errors?.firstName?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-12 col-md-6"}
                    label={"Apellido *"}
                    type={"text"}
                    name={"lastName"}
                    register={register}
                    placeholder={""}
                    errors={errors?.lastName?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-4"}
                    label={"C.I / RIF*"}
                    type={"select"}
                    name={"identificationType"}
                    register={register}
                    placeholder={""}
                    options={idCodes}
                    errors={errors?.identificationType?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-8"}
                    label={"del cliente*"}
                    type={"number"}
                    name={"identification"}
                    register={register}
                    placeholder={""}
                    errors={errors?.identification?.message}
                    disabled={true}
                  />{" "}
                  <Input
                    bootstrap={"col-12"}
                    label={"Correo *"}
                    type={"text"}
                    name={"email"}
                    register={register}
                    placeholder={""}
                    errors={errors?.email?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-12 col-md-4"}
                    label={"Teléfono *"}
                    type={"select"}
                    name={"phonePrefix"}
                    register={register}
                    placeholder={""}
                    options={phoneCodes}
                    errors={errors?.phonePrefix?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-12 col-md-8"}
                    label={""}
                    type={"number"}
                    name={"phoneNumber"}
                    register={register}
                    placeholder={""}
                    errors={errors?.phoneNumber?.message}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <p style={{ margin: "0px" }}>(*) Campos obligatorios</p>
                  <h6 style={{ margin: "6px 12px 12px 0px" }}>
                    DATOS DEL PLAN{" "}
                  </h6>
                  <Input
                    bootstrap={"col-12 "}
                    label={"Vendedor"}
                    type={"text"}
                    name={"seller"}
                    register={register}
                    placeholder={""}
                    errors={errors?.seller?.message}
                    disabled={true}
                  />{" "}
                  <Input
                    bootstrap={"col-12 "}
                    label={"Plan anterior"}
                    type={"text"}
                    name={"previousPlan"}
                    register={register}
                    placeholder={""}
                    errors={errors?.previousPlan?.message}
                    disabled={true}
                  />
                  <Input
                    bootstrap={"col-12 "}
                    label={"Plan Nuevo"}
                    type={"select"}
                    options={plansOptions}
                    name={"newCode"}
                    register={register}
                    placeholder={""}
                    onChange={(e) => handlePlanChange(e?.target?.value)}
                    errors={errors?.newCode?.message}
                  />
                  {validateIfShowKM() && (
                    <>
                      <Input
                        bootstrap={"col-12"}
                        label={"Km de Servicio de Grúa"}
                        type={"select"}
                        name={"km"}
                        register={register}
                        placeholder={""}
                        options={KMServiceOptions}
                        errors={errors?.km?.message}
                      />
                      <Input
                        bootstrap={"col-6 col-md-6 mt-0"}
                        subLabel={"Añadir un (1) servicio de grúa ilimitado"}
                        type={"checkbox"}
                        name={"hasExtendedTow"}
                        register={register}
                        placeholder={""}
                        errors={errors?.hasExtendedTow?.message}
                        disabled={membership?.hasExtendedTow}
                      />
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="row">
                  <p style={{ margin: "0px" }}>(*) Campos obligatorios</p>
                  <h6 style={{ margin: "6px 12px 12px 0px" }}>
                    DATOS DEL PAGO{" "}
                  </h6>
                  <Input
                    bootstrap={"col-12 "}
                    label={"Monto USD *"}
                    type={"number"}
                    name={"amount"}
                    register={register}
                    placeholder={""}
                    errors={errors?.amount?.message}
                    onChange={handleMembershipValueChange}
                    min={"0"}
                  />
                  <Input
                    bootstrap={"col-12 "}
                    label={"Monto BsS *"}
                    type={"number"}
                    name={"amountBsS"}
                    register={register}
                    placeholder={""}
                    errors={errors?.amountBsS?.message}
                    disabled={validateBs()}
                  />
                  {watch("newCode") && (
                    <div className="buttons-center-container">
                      <ActionButton
                        label={"Pagar"}
                        action={() => openUpgradeModal()}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
