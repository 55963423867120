import { Input } from "../../../common/Forms/Input/Input";
import { FileComponent } from "../../../common/Dropzone/Dropzone";
import {
  paymentMethodsLimit,
  paymentType as paymentTypeOption, paymentMethods
} from '../../../../assets/helpers/options';
import { PaymentMethodsComponent } from './PaymentMethodsComponent';
import { PaymentMethodCard } from '../LandingComponents/LandingComponents';
import moment from "moment";
import { isPaymentMethodZelleOrPaypal } from "../../../../assets/helpers/funciones";

export const Method2Component = (props) => {
  const {
    watch,
    register,
    errors,
    getUSDFromBs,
    getBsfromUSD,
    openStripeModal,
    validateReference,
    paymemtMethodOptions,
    selectPaymentMethod,
    paymentImage2,
    validatePayment,
  } = props;

  const validateInput = () => {
    if (watch("paymentType") !== paymentTypeOption?.FINANCIADO) {
      return true;
    }
    if (watch("paymentType") === paymentTypeOption?.FINANCIADO) {
      if (!watch("mix") || validatePayment(paymentImage2, 2)) {
        return true;
      }
    }
  };

  if (
    [paymentTypeOption?.CASHEA].includes(
      watch('paymentType')
    ) && parseFloat(watch('paymentMethodsQuantity')) > 1) {
    if (watch("initial") && watch('reference1') && !watch('amount2')) {
      return <PaymentMethodCard method={{ name: paymentMethods?.CASHEA }}
        openModal={() =>
          selectPaymentMethod(paymentMethods?.CASHEA, 2)
        }
      />
    } else {
      return null
    }
  }

  if (parseFloat(watch('paymentMethodsQuantity')) > 1) {
    return (
      <div className="row">
        <div className="col-12">
          {watch("paymentMethodsQuantity") && (
            <h5 style={{ margin: "12px 0px" }}>
              Seleccione el 2do monto a fraccionar en el pago
            </h5>
          )}
        </div>
        {watch("amount1_2IGTF") > 0 && (
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"IGTF Pago 1 USD *"}
            type={"number"}
            name={"amount1_2IGTF"}
            register={register}
            options={paymentMethodsLimit}
            placeholder={""}
            errors={errors?.["amount1_2IGTF"]?.message}
            disabled={true}
            min={"0"}
          />
        )}
        {watch("amount2IGTF") > 0 && (
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"IGTF Pago 2 USD *"}
            type={"number"}
            name={"amount2IGTF"}
            register={register}
            options={paymentMethodsLimit}
            placeholder={""}
            errors={errors?.amount2IGTF?.message}
            disabled={true}
            min={"0"}
          />
        )}
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Monto Pago 2 USD  *"}
          type={"number"}
          name={"amount2"}
          register={register}
          options={paymentMethodsLimit}
          placeholder={""}
          errors={errors?.amount2?.message}
          onChange={getBsfromUSD}
          min={"0"}
          disabled={validateInput()}
        />
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Monto Pago 2 Bs *"}
          type={"number"}
          name={"amountBsS2"}
          register={register}
          options={paymentMethodsLimit}
          placeholder={""}
          errors={errors?.amountBsS2?.message}
          onChange={getUSDFromBs}
          min={"0"}
          disabled={validateInput()}
        />
        {/* {validateReference(paymentImage2, 2) && ( */}
        <Input
          bootstrap={"col-12 col-md-2"}
          label={"Referencia de Pago 2 *"}
          type={"text"}
          name={"reference2"}
          register={register}
          placeholder={""}
          errors={errors?.reference2?.message}
          disabled={true}
        />
        {/* )} */}
        {validateReference(paymentImage2, 2) && (
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Metodo de Pago 2 *"}
            type={"select"}
            name={"method2"}
            register={register}
            options={paymemtMethodOptions}
            placeholder={""}
            errors={errors?.method2?.message}
            disabled={true}
          />
        )}
        <div className="col-12 col-md-3">
          {paymentImage2?.name && <FileComponent file={paymentImage2} />}
        </div>{" "}
        {watch("paymentMethodsQuantity") &&
          !paymentImage2 &&
          !watch("reference2") &&
          (watch("reference1") ||
            isPaymentMethodZelleOrPaypal(
              watch("method1"),
              paymemtMethodOptions
            )) &&
          (watch("amount2") || watch("amountBsS2")) && (
            <PaymentMethodsComponent
              selectPaymentMethod={selectPaymentMethod}
              paymentNumber={2}
              openStripeModal={openStripeModal}
            />
          )}
      </div>
    );
  } else {
    return null;
  }
};
