import { filterParams } from '../assets/helpers/funciones';
import { authInstance } from './axios';
let url = process.env.REACT_APP_API_URL;

export const ErrorFilesService = {
  getErrorFiles: async (params) => {
    const response = await authInstance
      .get(
        `${url}/errorFiles/list?` + new URLSearchParams(filterParams(params))
      )
      .then(async (response) => {
        return response;
      })
      .catch(function (error) {
        console.log('error', error);
        error.data = [];
        return error;
      });
    return response;
  },
};
