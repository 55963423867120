import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  FileComponent,
  SalesDropzone,
  FileDropzone,
} from "../../../common/Dropzone/Dropzone";
import { ActionButton, AltButton } from "../../../common/Buttons/Buttons";
import { MembershipImage } from "./LandingComponents";
import {
  paymentMethods,
  initialOptions,
  banksOptions,
  cashBakOptions,
  genericPayments,
  bankTransferList,
  pagoMovilList,
  salesAgentPhoneNumber,
} from "../../../../assets/helpers/options";
import paymentMethodCashea from "../../../../images/paymentMethodCashea.svg";
import zelleLogo from "../../../../images/zelle.png";
import mercantil from "../../../../images/mercantil.png";
import banesco from "../../../../images/banesco.png";
import paypal from "../../../../images/paymentMethodPaypal.svg";
import pagoMovil from "../../../../images/paymentMethodPagoMovil.svg";
import binancePayMethod from "../../../../images/binancePayMethod.svg";
import {
  capitalize,
  filterById,
  isPaymentMethodZelleOrPaypal,
  returnIGTF,
} from "../../../../assets/helpers/funciones";
import { Input } from "../../../common/Forms/Input/Input";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "./LandingModals.scss";
import { notify } from "../../../../assets/helpers/toast";
import authService from "../../../../services/authService";

export const AccountsSelect = (props) => {
  const { handleGenericPaymentMethods, payment, methods, register, errors } =
    props;

  let options = [];
  options = methods;

  for (let i = 0; i < methods?.length; i++) {
    if (
      ![
        paymentMethods.CASH_BS,
        paymentMethods.CASH_USD,
        ...genericPayments,
      ].includes(methods[i]?.name)
    ) {
      return null;
    }
  }
  return (
    <Input
      bootstrap={"col-12 col-md-6"}
      label={"Opciones de pago"}
      type={"select"}
      name={"paymentOption"}
      register={register}
      placeholder={""}
      options={options}
      errors={errors?.paymentOption?.message}
      disabled={!authService?.getUser()}
      onChange={() => {
        handleGenericPaymentMethods(payment?.paymentNumber);
      }}
    />
  );
};

export const RefComponent = (props) => {
  const {
    methods,
    paymentModal,
    payment,
    handleReferenceChange,
    handleOwnerChange,
  } = props;

  for (let i = 0; i < methods?.length; i++) {
    if ([paymentMethods.CASH_BS].includes(methods[i]?.name)) {
      return null;
    }
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {![paymentMethods?.ZELLE, paymentMethods?.PAYPAL].includes(
          payment?.methods[0]?.name
        ) && (
            <div className={`input-component-container col-12 col-md-6`}>
              <label style={{ textAlign: "center" }}>{`${payment?.methods[0]?.name === paymentMethods?.PAGO_EN_COMERCIO
                ? "Número de Factura"
                : "Referencia de Pago"
                }  ${paymentModal?.paymentNumber > 1 ? paymentModal?.paymentNumber : ""
                }`}</label>
              <div>
                <div className="input-sublabel-container">
                  <input
                    name={`reference`}
                    value={payment?.reference}
                    onChange={handleReferenceChange}
                  />
                </div>
              </div>
            </div>
          )}
        {[paymentMethods?.ZELLE, paymentMethods?.PAYPAL].includes(
          payment?.methods[0]?.name
        ) && (
            <div className={`input-component-container col-12 col-md-6`}>
              <label style={{ textAlign: "center" }}>
                {"Titular de la cuenta"}
              </label>
              <div>
                <div className="input-sublabel-container">
                  <input
                    name={`owner`}
                    value={payment?.owner}
                    onChange={handleOwnerChange}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
};

export const InitialComponent = (props) => {
  const {
    payment,
    handleCasheaInitial,
    paymentImage,
    removeImage,
    getRootProps,
    getInputProps,
    options,
    watch
  } = props;

  return (
    <>
      <div className={`input-component-container col-12 col-md-6`}>
        <label style={{ textAlign: 'center' }}>{`Monto USD`}</label>
        <div>
          <div className="input-sublabel-container">
            <input
              type="number"
              name={`amount`}
              value={payment?.amount}
              onChange={handleCasheaInitial}
              disabled={watch("paymentMethodsQuantity") === "1" || (watch("paymentMethodsQuantity") === "2" && watch("amount1") && payment?.paymentNumber === 2)}
            ></input>
          </div>
        </div>
      </div>
      <div className={`input-component-container col-12 col-md-6`}>
        <label style={{ textAlign: "center" }}>{`Método de Pago`}</label>
        <select
          value={payment?.metodo}
          onChange={handleCasheaInitial}
          name="metodo"
        >
          <option value=""></option>
          {options?.paymemtMethodOptions?.map((o, i) => (
            <option key={o?._id} value={o?._id}>
              {capitalize(o?.name)}
            </option>
          ))}
        </select>
      </div>
      {!isPaymentMethodZelleOrPaypal(
        payment.metodo,
        options.paymemtMethodOptions
      ) && (
          <div className={`input-component-container col-12 col-md-6`}>
            <label
              style={{ textAlign: "center" }}
            >{`Referencia pago inicial`}</label>
            <div>
              <div className="input-sublabel-container">
                <input
                  type="text"
                  name={`transactionId`}
                  value={payment?.transactionId}
                  onChange={handleCasheaInitial}
                ></input>
              </div>
            </div>
          </div>
        )}
      <div className={`input-component-container col-12 col-md-6`}>
        <label style={{ textAlign: "center" }}>{`Referencia Cashea`}</label>
        <div>
          <div className="input-sublabel-container">
            <input
              type="number"
              name={`referenceCashea`}
              value={payment?.referenceCashea}
              onChange={handleCasheaInitial}
              disabled={(watch("paymentMethodsQuantity") === "2" && watch("amount1") && payment?.paymentNumber === 2)}
            ></input>
          </div>
        </div>
      </div>
      {/* FOR ZELLE PAYMENTS */}
      {isPaymentMethodZelleOrPaypal(
        payment.metodo,
        options.paymemtMethodOptions
      ) && (
          <div className={`input-component-container col-12 col-md-6`}>
            <label
              style={{ textAlign: "center" }}
            >{`Titular de la cuenta`}</label>
            <div>
              <div className="input-sublabel-container">
                <input
                  type="text"
                  name={`owner`}
                  value={payment?.owner}
                  onChange={handleCasheaInitial}
                ></input>
              </div>
            </div>
          </div>
        )}
      {/* {![paymentMethods?.PUNTO_DE_VENTA, paymentMethods?.TDC]?.includes(
        payment?.methods[0]?.name
      ) && ( */}
      <div className="buttons-center-container">
        {paymentImage ? (
          <FileComponent
            file={paymentImage}
            remove={() => removeImage(payment?.paymentNumber)}
          />
        ) : (
          <SalesDropzone
            instructions={'Adjuntar Comprobante *'}
            maxWeight={'(JPG, PNG, PDF, máx. 3MB)'}
            getRootProps={getRootProps}
            getInputProps={getInputProps}
            message="Arrastrar o buscar Documento"
          />
        )}
      </div >
      {/* )} */}
    </>
  );
};

export const PaymentMethodDetails = (props) => {
  const { method, methods, watch } = props;

  const filteredMethod = methods?.filter(
    (m) =>
      m?._id === method?._id &&
      ![paymentMethods.CASH_BS, paymentMethods.CASH_USD]?.includes(m?.name)
  );
  const genericMethods = methods?.filter((m) =>
    [...bankTransferList, ...pagoMovilList]?.includes(m?.name)
  );

  if (genericMethods?.length > 0 && !watch("paymentOption")) {
    return null;
  }

  if (
    genericMethods?.length > 0 &&
    watch("paymentOption") &&
    method?._id !== watch("paymentOption")
  ) {
    return null;
  }

  if (filteredMethod?.length > 0) {
    return (
      <p style={{ margin: "24px 12px 24px 12px" }}>
        {method?.details?.account && (
          <>
            Cuenta: <b>{method?.details?.account}</b> <br />
          </>
        )}
        {method?.details?.accountNumber && (
          <>
            Numero de Cuenta: <b>{method?.details?.accountNumber}</b> <br />
          </>
        )}
        {method?.details?.bank && (
          <>
            Banco: <b>{method?.details?.bank}</b> <br />
          </>
        )}
        {method?.details?.phone && (
          <>
            Telefono: <b>{method?.details?.phone}</b> <br />
          </>
        )}
        {method?.details?.rif && (
          <>
            RIF: <b>{method?.details?.rif}</b> <br />
          </>
        )}
      </p>
    );
  } else return null;
};

export const AmounComponent = (props) => {
  const { paymentModal, amount, amountBsS } = props;

  let bs = false;
  let dollars = false;

  for (let i = 0; i < paymentModal?.methods?.length; i++) {
    if (paymentModal?.methods[i]?.currency === "BsS") {
      bs = true;
    }
    if (paymentModal?.methods[i]?.currency === "USD") {
      dollars = true;
    }
  }

  if (!amountBsS) {
    return null;
  }
  return (
    <div className="ammount-container">
      <h5>Monto</h5>
      <h6>
        {" "}
        {paymentModal?.initial && (
          <>
            {" "}
            {amount?.toFixed(2)} {"USD"}
          </>
        )}
      </h6>
      <h6>
        {bs && (
          <>
            {amountBsS?.toFixed(2) + " "}
            {"Bs" + "     "}
          </>
        )}
        {dollars && (
          <>
            {amount?.toFixed(2)} {"USD"}
          </>
        )}
      </h6>
    </div>
  );
};

export const MethodLogoContainer = (props) => {
  const { methods } = props;
  if (methods?.length > 0) {
    return (
      <div className="payment-method-logos-container">
        {methods?.map((m, i) => (
          <MethodLogo key={m?.name + i} name={m?.name} />
        ))}
      </div>
    );
  } else return null;
};

export const MethodLogo = (props) => {
  const { name } = props;

  let src = "";
  switch (name) {
    case paymentMethods.CASHEA:
      src = paymentMethodCashea;
      break;
    case paymentMethods.ZELLE:
      src = zelleLogo;
      break;
    case paymentMethods.TRANSFERENCIA_MERCANTIL:
      src = mercantil;
      break;
    case paymentMethods.TRANSFERENCIA_BANESCO:
      src = banesco;
      break;
    case paymentMethods.PAYPAL:
      src = paypal;
      break;
    case paymentMethods.PAGO_MÓVIL:
      src = pagoMovil;
      break;
    case paymentMethods.PAGO_MÓVIL_BANCAMIGA:
      src = pagoMovil;
      break;
    case paymentMethods.BINANCE:
      src = binancePayMethod;
      break;

    default:
  }
  if (src) {
    return <img src={src} alt="src" />;
  } else return null;
};

export const IGTF = (props) => {
  const { methods } = props;

  let isDollar = false;

  for (let i = 0; i < methods.length; i++) {
    if (methods[i].currency === "USD") {
      isDollar = true;
    }
  }

  if (!isDollar) {
    return null;
  }

  return (
    <p className="igtf-message-container">
      Este monto incluye el <b>IGTF ({returnIGTF()}%)</b> por utilizar un método
      de pago de une monede distinta a la de curso legal en Venezuela. Para más
      información, consulte la <b>Gaceta Oficial N°6.687</b>
    </p>
  );
};

export const NewPaymentModal = (props) => {
  const {
    getRootProps,
    getInputProps,
    cancelPayment,
    paymentImage,
    removeImage,
    acceptPayment,
    watch,
    dollarRate,
    register,
    errors,
    payment,
    handleReferenceChange,
    handleOwnerChange,
    control,
    handleGenericPaymentMethods,
  } = props;

  // Check if the paymet method is PM or bank transfer
  const genericMethods = payment?.methods?.filter((m) =>
    [...bankTransferList, ...pagoMovilList]?.includes(m?.name)
  );

  return (
    <Modal
      size="lg"
      centered
      show={payment?.isOpen}
      onHide={() => cancelPayment(payment?.paymentNumber)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <MembershipImage
          membership={{ code: payment?.selectedMembership?.code }}
        />
        <div className="landing-payment-modal-header">
          <AmounComponent
            selectedMembership={payment?.selectedMembership}
            paymentModal={payment}
            dollarRate={dollarRate}
            amount={payment?.amount}
            amountBsS={payment?.amountBsS}
          />

          <MethodLogoContainer methods={payment?.methods} />
        </div>
        <div className="landing-payment-modal-body">
          <h6 className="landing-payment-modal-title">Datos de Pago</h6>
          {payment?.methods?.map((p) => (
            <PaymentMethodDetails
              key={p?.details?.account}
              method={p}
              paymentOption={watch("paymentOption")}
              methods={payment?.methods}
              watch={watch}
            />
          ))}
          <div
            style={{ width: "100%" }}
            className="row landing-payment-modals-form"
          >
            <AccountsSelect
              register={register}
              errors={errors}
              methods={payment?.methods}
              payment={payment}
              handleGenericPaymentMethods={handleGenericPaymentMethods}
            />

            <AmountCashReceived
              paymentModal={payment}
              register={register}
              errors={errors}
              watch={watch}
              methods={payment?.methods}
              payment={payment}
            />
          </div>
          <div
            style={{ width: "100%" }}
            className="row landing-payment-modals-form"
          >
            <RefComponent
              paymentModal={payment}
              register={register}
              errors={errors}
              methods={payment?.methods}
              payment={payment}
              handleReferenceChange={handleReferenceChange}
              handleOwnerChange={handleOwnerChange}
            />
          </div>

          {filterById(payment?.methods, watch("paymentOption"))?.name ===
            paymentMethods?.PAGO_MÓVIL_BANCAMIGA && (
              <div style={{ width: "100%" }} className="row">
                <Input
                  bootstrap={"col-12 col-md-6"}
                  label={"Nro. Teléfono asociado a pago móvil"}
                  type={"number"}
                  name={`phone${payment?.paymentNumber}`}
                  placeholder={"4143579865"}
                  register={register}
                  errors={errors?.[`phone${payment?.paymentNumber}`]?.message}
                  disabled={false}
                />
                <Input
                  bootstrap={"col-12 col-md-6"}
                  label={"Fecha de Pago"}
                  type={"date"}
                  name={`date${payment?.paymentNumber}`}
                  register={register}
                  errors={errors?.[`date${payment?.paymentNumber}`]?.message}
                  control={control}
                  disabled={false}
                />
                <Input
                  bootstrap={"col-12 col-md-6"}
                  label={"¿Qué banco utilizaste?"}
                  type={"select"}
                  name={`bank${payment?.paymentNumber}`}
                  register={register}
                  placeholder={""}
                  options={banksOptions}
                  errors={errors?.[`bank${payment?.paymentNumber}`]?.message}
                  disabled={false}
                />
              </div>
            )}
          <div className="buttons-center-container">
            {paymentImage ? (
              <FileComponent
                file={paymentImage}
                remove={() => removeImage(payment?.paymentNumber)}
              />
            ) : (
              <SalesDropzone
                instructions={
                  payment?.methods[0]?.name === paymentMethods?.PAGO_EN_COMERCIO
                    ? `Adjuntar Factura`
                    : `Adjuntar Comprobante *`
                }
                maxWeight={"(JPG, PNG, PDF, máx. 3MB)"}
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                message="Arrastrar o buscar Documento"
              />
            )}
          </div>
          {genericMethods?.length > 0 && (
            <p style={{ textAlign: "center" }}>
              De existir un problema por favor ponte en contacto con nuestros
              asesores de venta <b>{salesAgentPhoneNumber}</b>{" "}
            </p>
          )}
        </div>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton
          label={`Cancelar `}
          action={() => cancelPayment(payment?.paymentNumber)}
        />
        <ActionButton label={`Pagar `} action={() => acceptPayment()} />
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const AmountCashReceived = (props) => {
  const { methods, register, errors } = props;

  for (let i = 0; i < methods?.length; i++) {
    if (
      ![
        paymentMethods.CASH_BS,
        paymentMethods.CASH_USD,
        paymentMethods.TRANSFERENCIA_BANESCO,
        paymentMethods.TRANSFERENCIA_MERCANTIL,
      ].includes(methods[i]?.name)
    ) {
      return null;
    }
  }

  return (
    <Input
      bootstrap={"col-12 col-md-6"}
      label={"Monto Recibido $"}
      type={"number"}
      name={"paid"}
      register={register}
      placeholder={""}
      errors={errors?.paid?.message}
      disabled={false}
    />
  );
};

export const CasheaModal = (props) => {
  const {
    cancelPayment,
    acceptPayment,
    dollarRate,
    register,
    errors,
    payment,
    handleCasheaInitial,
    removeImage,
    paymentImage,
    getRootProps,
    getInputProps,
    watch,
    options,
  } = props;

  return (
    <Modal
      size="lg"
      centered
      show={payment?.isOpen}
      onHide={() => cancelPayment(payment?.paymentNumber)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <MembershipImage
          membership={{ code: payment?.selectedMembership?.code }}
        />
        <div className="landing-payment-modal-header">
          <AmounComponent
            selectedMembership={payment?.selectedMembership}
            paymentModal={payment}
            dollarRate={dollarRate}
            amount={payment?.amount}
            amountBsS={payment?.amountBsS}
          />

          <MethodLogoContainer methods={payment?.methods} />
        </div>
        <div className="landing-payment-modal-body">
          <h6 className="landing-payment-modal-title">Datos de Pago</h6>
          <div
            style={{ width: "100%" }}
            className="row landing-payment-modals-form"
          >
            <InitialComponent
              paymentModal={payment}
              register={register}
              errors={errors}
              watch={watch}
              methods={payment?.methods}
              payment={payment}
              handleCasheaInitial={handleCasheaInitial}
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              paymentImage={paymentImage}
              removeImage={removeImage}
              options={options}
            />
          </div>
        </div>
      </Modal.Body>
      <div className="buttons-center-container">
        <AltButton
          label={`Cancelar `}
          action={() => cancelPayment(payment?.paymentNumber)}
        />
        {payment?.amount > 0 &&
          (payment?.transactionId ||
            isPaymentMethodZelleOrPaypal(
              payment.metodo,
              options.paymemtMethodOptions
            )) &&
          payment?.metodo &&
          paymentImage && (
            <ActionButton
              label={`Pagar ${payment?.amount} $`}
              action={() => acceptPayment()}
            />
          )}
      </div>
      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const StripeModal = (props) => {
  const { stripeModal, handleStipeModal, clientSecret, successfulStripe } =
    props;

  const publishableKey = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  return (
    <Modal
      size="lg"
      centered
      show={stripeModal?.isOpen}
      onHide={() => handleStipeModal("", 0, stripeModal?.paymentNumber)}
    >
      <Modal.Header closeButton className="modal-header"></Modal.Header>
      <Modal.Body className="modal-body">
        <MembershipImage
          membership={{ code: stripeModal?.selectedMembership?.code }}
        />
        <div className="landing-payment-modal-header">
          <AmounComponent
            selectedMembership={stripeModal?.selectedMembership}
            paymentModal={stripeModal}
            amount={stripeModal?.amount}
          />

          {/* <MethodLogoContainer methods={paymentModal?.methods} /> */}
        </div>
        <div className="landing-payment-modal-body">
          <h6 className="landing-payment-modal-title">Datos de Pago</h6>

          {clientSecret && publishableKey && (
            <Elements stripe={publishableKey} options={{ clientSecret }}>
              <CheckoutForm
                stripeModal={stripeModal}
                handleStipeModal={handleStipeModal}
                successfulStripe={successfulStripe}
              />
            </Elements>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-footer"></Modal.Footer>
    </Modal>
  );
};

export const CheckoutForm = (props) => {
  const { stripeModal, handleStipeModal, successfulStripe } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${window.location.origin}/completion`,
      },
      redirect: "if_required",
    });

    if (error?.type === "card_error" || error?.type === "validation_error") {
      setMessage(error.message);
    }
    if (paymentIntent?.status === "succeeded") {
      notify("Su pago ha sido registrado con éxito", "success");
      successfulStripe(stripeModal.paymentNumber);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form">
      <PaymentElement id="payment-element" />
      {message && <div id="payment-message">{message}</div>}

      {!isProcessing && (
        <div
          style={{ margin: "24px 0px" }}
          className="buttons-center-container"
        >
          <AltButton
            label={`Cancelar `}
            action={() => handleStipeModal("", 0, stripeModal.paymentNumber)}
          />

          {isProcessing || !stripe || !elements ? null : (
            <ActionButton label={`Pagar `} action={handleSubmit} />
          )}
        </div>
      )}
    </form>
  );
};

export const CashBackComponent = (props) => {
  const {
    register,
    errors,
    watch,
    options,
    onChange,
    handleDrop,
    cashBackImage,
    removeFile,
    errorsDropzone,
  } = props;
  const filterMethods = options?.paymemtMethodOptions?.filter(
    (m) => ![paymentMethods?.CASHEA, paymentMethods?.RESERVE]?.includes(m?.name)
  );

  if (watch("paid") && watch("paidBsS")) {
    return (
      <>
        <div className="col-12">
          <h5 style={{ margin: "12px 0px" }}>Vuelto</h5>
        </div>
        <div className="row">
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Monto total Recibido USD $ *"}
            type={"number"}
            name={"paid"}
            register={register}
            placeholder={""}
            errors={errors?.paid?.message}
            disabled={true}
          />
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Monto total Recibido BsS*"}
            type={"number"}
            name={"paidBsS"}
            register={register}
            placeholder={""}
            errors={errors?.paidBsS?.message}
            disabled={true}
          />
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Vuelto a entregar USD $ *"}
            type={"number"}
            name={"amountReturned"}
            register={register}
            options={options?.paymemtMethodOptions}
            placeholder={""}
            errors={errors?.amountReturned?.message}
            disabled={true}
          />
          <Input
            bootstrap={"col-12 col-md-2"}
            label={"Vuelto a entregar BsS $ *"}
            type={"number"}
            name={"amountReturnedBsS"}
            register={register}
            options={options?.paymemtMethodOptions}
            placeholder={""}
            errors={errors?.amountReturnedBsS?.message}
            disabled={true}
          />
          <div className="col-md-4"></div>
          <Input
            bootstrap={"col-12 col-md-3"}
            label={"Cantidad de métodos de vuelto *"}
            type={"select"}
            name={"cashbackMethodsQuantity"}
            register={register}
            options={cashBakOptions}
            placeholder={""}
            errors={errors?.cashbackMethodsQuantity?.message}
            onChange={() => onChange?.handleCashbackMethodsQuantity()}
          // disabled={true}
          />
          <div className="col-md-9"></div>
          {watch("cashbackMethodsQuantity") > 0 && (
            <CashBackInput
              index={1}
              register={register}
              errors={errors}
              options={{ filterMethods }}
              onChange={onChange}
              handleDrop={handleDrop}
              file={cashBackImage[`image${1}`]}
              removeFile={removeFile}
              watch={watch}
              errorsDropzone={errorsDropzone}
            />
          )}
          {watch("cashbackMethodsQuantity") > 1 && (
            <CashBackInput
              index={2}
              register={register}
              errors={errors}
              options={{ filterMethods }}
              onChange={onChange}
              handleDrop={handleDrop}
              file={cashBackImage[`image${2}`]}
              removeFile={removeFile}
              watch={watch}
              errorsDropzone={errorsDropzone}
            />
          )}
          {watch("cashbackMethodsQuantity") > 2 && (
            <CashBackInput
              index={3}
              register={register}
              errors={errors}
              options={{ filterMethods }}
              onChange={onChange}
              handleDrop={handleDrop}
              file={cashBackImage[`image${3}`]}
              removeFile={removeFile}
              watch={watch}
              errorsDropzone={errorsDropzone}
            />
          )}
        </div>
      </>
    );
  } else return null;
};

export const CashBackInput = (props) => {
  const {
    index,
    register,
    errors,
    options,
    onChange,
    file,
    removeFile,
    handleDrop,
    watch,
    errorsDropzone,
  } = props;

  return (
    <>
      <Input
        bootstrap={"col-12 col-md-2"}
        label={`Método de vuelto ${index}*`}
        type={"select"}
        name={`returnedMethod${index}`}
        register={register}
        options={options?.filterMethods}
        placeholder={""}
        errors={errors[`returnedMethod${index}`]?.message}
      />
      <Input
        bootstrap={"col-12 col-md-2"}
        label={`Monto USD Vuelto ${index} *`}
        type={"number"}
        name={`returnedAmount${index}`}
        register={register}
        placeholder={""}
        errors={errors[`returnedAmount${index}`]?.message}
        onChange={() => onChange?.handleCashbackBs(index)}
        disabled={parseFloat(watch("cashbackMethodsQuantity")) === index}
      />
      <Input
        bootstrap={"col-12 col-md-2"}
        label={`Monto Bs Vuelto ${index} *`}
        type={"number"}
        name={`returnedAmountBsS${index}`}
        register={register}
        placeholder={""}
        errors={errors[`returnedAmountBsS${index}`]?.message}
        disabled={true}
      />
      <Input
        bootstrap={"col-12 col-md-2"}
        label={`Referencia Vuelto ${index} *`}
        type={"text"}
        name={`returnedReference${index}`}
        register={register}
        placeholder={""}
        errors={errors[`returnedReference${index}`]?.message}
      />
      <div className="col-12 col-md-4">
        <FileDropzone
          handleDrop={handleDrop}
          index={index}
          file={file}
          removeFile={removeFile}
          error={errorsDropzone?.[`error${index}`]}
        />
      </div>
    </>
  );
};
