export const capitalize = (str) => {
  if (str) {
    str = str?.toLowerCase();
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  } else return '';
};

export const transformObjectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({
    name: value,
    _id: value,
  }));
};

export const phoneCodes = [
  { _id: '0212', name: '0212' },
  { _id: '0412', name: '0412' },
  { _id: '0414', name: '0414' },
  { _id: '0424', name: '0424' },
  { _id: '0416', name: '0416' },
  { _id: '0426', name: '0426' },
];

export const idEnums = {
  V: 'V',
  E: 'E',
  J: 'J',
  G: 'G',
  R: 'R',
  P: 'P',
};

export const idCodes = [
  { _id: idEnums?.V, name: idEnums?.V },
  { _id: idEnums?.E, name: idEnums?.E },
  { _id: idEnums?.J, name: idEnums?.J },
  { _id: idEnums?.G, name: idEnums?.G },
  { _id: idEnums?.R, name: idEnums?.R },
  { _id: idEnums?.P, name: idEnums?.P },
];

export const gendersEnum = {
  MALE: 'Masculino',
  FEMALE: 'Femenino',
  OTHER: 'Otro',
};

export const genderOptions = [
  { _id: gendersEnum?.MALE, name: gendersEnum?.MALE },
  { _id: gendersEnum?.FEMALE, name: gendersEnum?.FEMALE },
  { _id: gendersEnum?.OTHER, name: gendersEnum?.OTHER },
];

export const civilStateEnum = {
  SINGLE: 'Soltera/o',
  MARRIED: 'Casada/o',
  DIVORCED: 'Divorciada/o',
  WIDOW: 'Viuda/o',
  OTHER: 'Otro',
};

export const LANDING = {
  PUBLIC: 'Publica',
  PRIVATE: 'Privada',
  MARKETING: 'Marketing',
  FLEET: 'Fleet',
  SELF_RENEWAL: 'selfRenewal',
};

export const civilStateOptions = [
  { _id: civilStateEnum?.SINGLE, name: civilStateEnum?.SINGLE },
  { _id: civilStateEnum?.MARRIED, name: civilStateEnum?.MARRIED },
  { _id: civilStateEnum?.DIVORCED, name: civilStateEnum?.DIVORCED },
  { _id: civilStateEnum?.WIDOW, name: civilStateEnum?.WIDOW },
  { _id: civilStateEnum?.OTHER, name: civilStateEnum?.OTHER },
];

export const providerStatus = {
  available: 'Available',
  busy: 'Busy',
};

export const clientTypes = {
  AFFILIATED: 'Affiliated',
  PARTICULAR: 'Particular',
};

export const caseType = {
  AFFILIATED: 'Afiliado',
  PARTICULAR: 'Particular',
};

export const afiliatedServices = {
  crane: 'GRUA',
  gas: 'AUX_GAS',
  tire: 'CAMBIO_CAUCHO',
  battery: 'AUX_BAT',
  accompaniment: 'ACOMP_VIAL',
};

export const particularServices = {
  crane: 'GRUA_PART',
  gas: 'AUX_GAS_PART',
  tire1: 'INF_CAUCHO_PART',
  tire2: 'CAMB_CAUCHO_PART',
  battery: 'AUX_BAT_PART',
  // accompaniment: ',
};

export const getProvidersEnum = {
  ROAD_ATTENDANT: 'Asistente Vial',
  CRANE: 'Grua',
};

export const providersOptions = [
  { _id: getProvidersEnum?.ROAD_ATTENDANT, name: 'Motorizado' },
  { _id: getProvidersEnum?.CRANE, name: 'Gruero' },
];

export const notFound = 'not-found';

export const enumStatus = {
  INITIATED: 'Orden En Espera',
  //Moto
  AV_ASSIGNED: 'Av Asignado',
  AV_ON_ROAD: 'Av en via',
  AV_IN_ORIGIN: 'Av En Origen',
  //Grua
  SEARCHING_TOW: 'En Busqueda de Grua',
  TOW_ASSIGNED: 'Grua Asignada',
  TOW_ON_ROAD: 'Grua en Via',
  TOW_IN_ORIGIN: 'Grua En Origen',
  CUSTOMER_ON_ROAD: 'Cliente Via Destino',
  // Otros
  SCHEDULED: 'Servicio Agendado',
  FINISHED: 'Finalizada',
  CANCELED: 'Anuladas',
};

export const membershipCodes = {
  FREE: 'FREE',
  GOLD: 'GOLD',
  PLUS: 'PLUS',
  CLASSIC: 'CLASSIC',
  MOTORIDER: 'MOTORIDER',
  RCV: 'RCV',
  RCV_ELITE: 'RCV_ELITE',
  MOTORIDERAV: 'MOTORIDERAV',
  RIDERY: 'RIDERY',
  RETRO: 'RETRO',
  GOLDENP: 'GOLDENP',
  PLUSF4: 'PLUSF4',
  F2: 'F2',
};

export const addressEnum = {
  OWN: 'Propia',
  GARAGE: 'Taller',
  OTHER: 'Otra',
};

export const addressOptions = [
  { name: addressEnum?.OWN, _id: addressEnum?.OWN },
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
];

export const particularAddressOptions = [
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
];

export const afiliatedAddressOptions = [
  { name: addressEnum?.OWN, _id: addressEnum?.OWN },
  { name: addressEnum?.GARAGE, _id: addressEnum?.GARAGE },
  { name: addressEnum?.OTHER, _id: addressEnum?.OTHER },
];

export const validateCodeEnum = {
  NEW: 'new',
  RENEWAL: 'renewal',
};

export const locationEnum = {
  STREET: 'Calle',
  BASEMENT: 'Sotano',
};

export const locationOptions = [
  { name: locationEnum?.STREET, _id: false },
  { name: locationEnum?.BASEMENT, _id: true },
];

export const garageLocation = {
  state: { name: 'Distrito Capital', _id: '64695c7b447d0aa9c65969e8' },
  city: { name: 'Caracas', _id: '64695ff1a034e218fab88de5' },
  address:
    'Chacao Av. Blandín, con Calle Santa Teresa de Jesús, C.C. San Ignacio, Sotano 1, Sector este. La Castellana',
};

export const prodiverStatys = [
  { name: 'Activo', _id: true },
  { name: 'Inactivo', _id: false },
];

export const membershipStatus = {
  ACTIVO: 'ACTIVO',
  'EN ESPERA': 'EN ESPERA',
  'POR RENOVACION': 'POR RENOVACION',
};

export const KMServiceOptions = [
  // {
  //   name: '150 Km',
  //   _id: 150,
  // },
  {
    name: '200 Km',
    _id: 200,
  },
  {
    name: '300 Km',
    _id: 300,
  },
  // {
  //   name: 'KM ilimitados',
  //   _id: 999,
  // },
];

export const KMOderOptions = [
  {
    name: '10 Km',
    _id: 10,
  },
  {
    name: '25 Km',
    _id: 25,
  },
  {
    name: '50 Km',
    _id: 50,
  },
  {
    name: '150 Km',
    _id: 150,
  },
  {
    name: '200 Km',
    _id: 200,
  },
  {
    name: '300 Km',
    _id: 300,
  },
  {
    name: 'KM ilimitados',
    _id: 999,
  },
];

export const exportablesTypes = {
  PAYMENT_AV: 'Pago motorizados',
  PAYMETN_INTERNAL_TOW: 'Pagos grúas propias',
  PAYMENT_EXTERNAL_TOW: 'Pagos grúas externas',
};

export const planStatus = {
  PENDING_FOR_APPROBAL: 'PENDIENTE DE APROBACION',
  ACTIVE: 'ACTIVO',
  WAITING: 'EN ESPERA',
  TO_BE_RENEW: 'POR RENOVACION',
  EXPIRED: 'VENCIDO',
  CANCELED: 'ANULADO',
  DENIED: 'DENEGADO',
  TO_BE_EXPIRED: 'POR VENCER',
  INCOMPLETED: 'INCOMPLETO',
};

export const statusHeadervalues = [
  enumStatus.AV_ASSIGNED,
  enumStatus.AV_ON_ROAD,
  enumStatus.AV_IN_ORIGIN,
  enumStatus.SEARCHING_TOW,
  enumStatus.TOW_ASSIGNED,
  enumStatus.TOW_ON_ROAD,
  enumStatus.TOW_IN_ORIGIN,
  enumStatus.CUSTOMER_ON_ROAD,
  'Tiempo total gestión',
];

export const codeTypeEnum = {
  DISCOUNT: 'Descuento',
  GIFTCARD: 'Giftcard',
};

export const codeTypeOptions = [
  { name: 'Tarjeta', _id: codeTypeEnum.GIFTCARD },
  { name: 'Descuento', _id: codeTypeEnum.DISCOUNT },
];

export const freeCodeTypeOptions = [
  { name: 'Tarjeta', _id: codeTypeEnum.GIFTCARD },
  { name: 'Código', _id: codeTypeEnum.DISCOUNT },
];

export const paymentMethods = {
  PAGO_EN_COMERCIO: 'PAGO EN COMERCIO',
  CASH: 'EFECTIVO',
  // TDC: 'TARJETA INTERNACIONAL',
  TDC: 'STRIPE',

  BINANCE: 'BINANCE PAY(USDT)',
  CASH_BS: 'CASH(BsS)',
  CASH_USD: 'CASH(USD)',
  PAYPAL: 'PAYPAL',
  ZELLE: 'ZELLE',
  STRIPE: 'STRIPE',
  //Pago movil
  PAGO_MÓVIL: 'PAGO MÓVIL BANESCO',
  PAGO_MÓVIL_BANCAMIGA: 'PAGO MÓVIL BANCAMIGA',
  PAGO_MÓVIL_BNC: 'PAGO MÓVIL BNC',
  PAGO_MÓVIL_MERCANTIL: 'PAGO MÓVIL MERCANTIL',
  PAGO_MÓVIL_GENERICO: 'PAGO MÓVIL',
  PAGO_MOVIL_BANPLUS: 'PAGO MOVIL BANPLUS',

  RESERVE: 'RESERVE',
  CASH_EUR: 'CASH(EUR)',
  //Transferencias
  TRANSFERENCIA_MERCANTIL: 'TRANSFERENCIA MERCANTIL',
  TRANSFERENCIA_BANESCO: 'TRANSFERENCIA BANESCO',
  TRANSFERENCIA_BNC: 'TRANSFERENCIA BNC',
  TRANSFERENCIA_BANCAMIGA: 'TRANSFERENCIA BANCAMIGA',
  TRANSFERENCIA_BANPLUS: 'TRANSFERENCIA BANPLUS',
  TRANSFERENCIA_GENERICO: 'TRANSFERENCIA',
  BANK_TRANSFER: 'TRANSFERENCIA BANCARIA',

  PUNTO_DE_VENTA: 'PUNTO DE VENTA',
  CASHEA: 'CASHEA',
};

export const PagoMovilArray = [
  paymentMethods?.PAGO_MÓVIL_BANCAMIGA,
  paymentMethods?.PAGO_MÓVIL_BNC,
];

export const salesPaymentMethodOptions = [
  { name: paymentMethods?.ZELLE, code: paymentMethods?.ZELLE },
  // { name: paymentMethods?.PAGO_MÓVIL, code: paymentMethods?.PAGO_MÓVIL },
  {
    name: paymentMethods?.PAGO_MÓVIL_BANCAMIGA,
    code: paymentMethods?.PAGO_MÓVIL_BANCAMIGA,
  },
  { name: paymentMethods?.PAYPAL, code: paymentMethods?.PAYPAL },
  { name: paymentMethods?.RESERVE, code: paymentMethods?.RESERVE },
  { name: paymentMethods?.CASH_USD, code: paymentMethods?.CASH_USD },
];

export const paymentType = {
  FINANCIADO: 'Financiado',
  DE_CONTADO: 'De contado',
  PENDING: 'Cuenta por cobrar',
  COURTESY: 'Cortesia',
  CASHEA: 'Cashea',
};

export const paymentTypeOptions = [
  {
    name: paymentType?.FINANCIADO + ' (2 cuotas)',
    _id: paymentType?.FINANCIADO,
  },
  { name: paymentType?.DE_CONTADO, _id: paymentType?.DE_CONTADO },
  { name: 'Diferido (Excepción)', _id: paymentType?.PENDING },
  { name: paymentType?.COURTESY, _id: paymentType?.COURTESY },
];

export const paymentMethodsLimit = [
  { name: '1 Método de pago', _id: '1' },
  { name: '2 Métodos de pago', _id: '2' },
];

export let selectedMembership = {
  _id: '64693588ad6cef4517e7377d',
  code: 'GOLD',
  originalPrice: 40,
  armorTypeSurcharge: 20,
  classSurcharge: 60,
  finalPrice: 120,
};

export const salesMembershipStatus = {
  ACTIVO: 'ACTIVO',
  VENCIDO: 'VENCIDO',
  EN_ESPERA: 'EN ESPERA',
  POR_VENCER: 'POR VENCER',
  POR_RENOVACION: 'POR RENOVACION',
  ANULADO: 'ANULADO',
};

export const campaignContactOptions = [
  { name: 'Contactar', _id: true },
  { name: 'No Contactar', _id: false },
];

export const campaignActionOptions = [
  { name: 'Contactar', _id: 'Contactar' },
  { name: 'Agendado', _id: 'Agendado' },
  { name: 'Pago pendiente', _id: 'Pago pendiente' },
  { name: 'Cierre 1', _id: 'Cierre 1' },
  { name: 'Cierre 2', _id: 'Cierre 2' },
  { name: 'Cierre 3', _id: 'Cierre 3' },
  { name: 'Crédito', _id: 'Crédito' },
  { name: 'De viaje', _id: 'De viaje' },
  { name: 'No va a renovar', _id: 'No va a renovar' },
  { name: 'Averiado', _id: 'Averiado' },
  { name: 'revisión no aprobada', _id: 'revisión no aprobada' },
  { name: 'revisión no agendada', _id: 'revisión no agendada' },
  { name: 'No contactar', _id: 'No contactar' },
];

export const armoredOptions = [
  { name: 'Si', _id: true },
  { name: 'No ', _id: false },
];

export const cancelFleatLoadEnum = {
  DEBT_CUSTOMER: 'Cliente moroso',
  SINISTER_CUSTOMER: 'Cliente siniestro',
  POLICY_NONCOMPLIANCE: 'Incumplimiento de politicas',
  CUSTOMER_WITHDRAWS: 'Cliente se retira',
  CONTRACT_TYPE_CHANGE: 'Cambio de tipo de contrato',
  AFFILIATION_CRITERIA: 'No cumple criterios de afiliación',
  CUSTOMER_DECISION: 'Decisión del cliente',
};

export const cancelFleatLoadOptions = [
  {
    name: cancelFleatLoadEnum?.DEBT_CUSTOMER,
    _id: cancelFleatLoadEnum?.DEBT_CUSTOMER,
  },
  {
    name: cancelFleatLoadEnum?.SINISTER_CUSTOMER,
    _id: cancelFleatLoadEnum?.SINISTER_CUSTOMER,
  },
  {
    name: cancelFleatLoadEnum?.POLICY_NONCOMPLIANCE,
    _id: cancelFleatLoadEnum?.POLICY_NONCOMPLIANCE,
  },
  {
    name: cancelFleatLoadEnum?.CUSTOMER_WITHDRAWS,
    _id: cancelFleatLoadEnum?.CUSTOMER_WITHDRAWS,
  },
  {
    name: cancelFleatLoadEnum?.CONTRACT_TYPE_CHANGE,
    _id: cancelFleatLoadEnum?.CONTRACT_TYPE_CHANGE,
  },
  {
    name: cancelFleatLoadEnum?.AFFILIATION_CRITERIA,
    _id: cancelFleatLoadEnum?.AFFILIATION_CRITERIA,
  },
  {
    name: cancelFleatLoadEnum?.CUSTOMER_DECISION,
    _id: cancelFleatLoadEnum?.CUSTOMER_DECISION,
  },
];

export const rcvEnum = {
  RCV_BASIC: 'RCV BÁSICO',
  RCV_ELIT: 'RCV INTERVIAL BÁSICO',
  NA: 'N/A',
};

export const SinRCV = 'Sin RCV';

export const rcvOptions = [
  { name: 'RCV Básico', _id: rcvEnum.RCV_BASIC },
  { name: 'RCV Élite', _id: rcvEnum.RCV_ELIT },
  { name: rcvEnum.NA, _id: rcvEnum.NA },
];

export const userStatus = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  BLOCKED: 'Bloqueado',
};

export const userStatusOptions = [
  { name: userStatus?.ACTIVE, _id: userStatus?.ACTIVE },
  { name: userStatus?.INACTIVE, _id: userStatus?.INACTIVE },
  { name: userStatus?.BLOCKED, _id: userStatus?.BLOCKED },
];

export const modules = {
  SALES: 'Ventas',
  OPERATIONS: 'Operaciones',
};

export const modulesOptions = [
  { name: modules?.SALES, _id: modules?.SALES },
  { name: modules?.OPERATIONS, _id: modules?.OPERATIONS },
];

export const userType = {
  INTERNO: 'Interno',
  EXTERNO: 'Externo',
};

export const userTypeOptions = [
  { name: userType?.INTERNO, _id: userType?.INTERNO },
  { name: userType?.EXTERNO, _id: userType?.EXTERNO },
];

export const codeStatus = {
  ACTIVE: 'Activo',
  INACTIVE: 'Inactivo',
  EXPIRED: 'Vencido',
};

export const codeStatusOptions = [
  { name: codeStatus?.ACTIVE, _id: codeStatus?.ACTIVE },
  { name: codeStatus?.INACTIVE, _id: codeStatus?.INACTIVE },
  { name: codeStatus?.EXPIRED, _id: codeStatus?.EXPIRED },
];

export const discountType = {
  PERCENTAGE: 'Porcentaje',
  AMOUNT: 'Monto',
};

export const discountTypeOptions = [
  { name: discountType?.PERCENTAGE, _id: discountType?.PERCENTAGE },
  { name: discountType?.AMOUNT, _id: discountType?.AMOUNT },
];

export const giftCardType = {
  RETAIL: 'Retail',
  PARTICULAR: 'Particular',
};

export const giftCardTypeOptions = [
  { name: giftCardType?.RETAIL, _id: giftCardType?.RETAIL },
  { name: giftCardType?.PARTICULAR, _id: giftCardType?.PARTICULAR },
];

export const loadStrategy = {
  LINK: 'Link',
  ARCHIVO: 'Archivo',
};

export const loadStrategyOptions = [
  { name: loadStrategy?.ARCHIVO, _id: loadStrategy?.ARCHIVO },
  { name: loadStrategy?.LINK, _id: loadStrategy?.LINK },
];

export const fleetType = {
  COLAB_FLEET: 'Flota Colaboradores',
  TECHNICAL_FLEET: 'Flota Técnica',
};

export const fleetTypeOptions = [
  { name: 'Técnica', _id: fleetType?.TECHNICAL_FLEET },
  { name: 'Colaboradores', _id: fleetType?.COLAB_FLEET },
];

export const fleetPaymentType = {
  FINANCIADO: 'Financiado',
  DE_CONTADO: 'De contado',
};

export const fleetPaymentTypeOptions = [
  { name: fleetPaymentType?.FINANCIADO, _id: fleetPaymentType?.FINANCIADO },
  { name: fleetPaymentType?.DE_CONTADO, _id: fleetPaymentType?.DE_CONTADO },
];

export const financingType = {
  MONTHLY: 'Mensual',
  QUARTER: 'Trimestral',
  SEMESTER: 'Semestral',
};

export const financingTypeOptions = [
  { name: financingType?.MONTHLY, _id: financingType?.MONTHLY },
  { name: financingType?.QUARTER, _id: financingType?.QUARTER },
  { name: financingType?.SEMESTER, _id: financingType?.SEMESTER },
];

export const fleetLoadRCVTypes = {
  N_A: 'N/A',
  RCV_BASIC: 'RCV BÁSICO',
  RCV_INTERVIAL_BASICO: 'RCV INTERVIAL BÁSICO',
  RCV_AMPLIO: 'RCV AMPLIO',
};

export const fleetLoadRCVTypesOptions = [
  {
    name: 'Ninguno',
    _id: fleetLoadRCVTypes?.N_A,
  },
  {
    name: capitalize(fleetLoadRCVTypes?.RCV_BASIC),
    _id: fleetLoadRCVTypes?.RCV_BASIC,
  },
  {
    name: capitalize(fleetLoadRCVTypes?.RCV_INTERVIAL_BASICO),
    _id: fleetLoadRCVTypes?.RCV_INTERVIAL_BASICO,
  },
];

export const planTypes = {
  GOLD: 'GOLD',
  FREE: 'FREE',
  PLUS: 'PLUS',
  CLASSIC: 'CLASSIC',
  MOTORIDER: 'MOTORIDER',
  PLUSF4: 'PLUSF4',
  GOLDF4: 'GOLDF4',
  GOLDENP: 'GOLDENP',
  RETRO: 'RETRO',
  MOTORIDERAV: 'MOTORIDERAV',
  F2: 'F2',
};

export const planTypesOptions = [
  {
    name: 'Plan ' + capitalize(planTypes?.GOLD),
    _id: planTypes?.GOLD,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.PLUS),
    _id: planTypes?.PLUS,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.CLASSIC),
    _id: planTypes?.CLASSIC,
  },
  {
    name: 'Plan ' + capitalize(planTypes?.MOTORIDER),
    _id: planTypes?.MOTORIDER,
  },
  {
    name: 'Plan Plus F4',
    _id: planTypes?.PLUSF4,
  },
  {
    name: 'Plan Motorider AV',
    _id: planTypes?.MOTORIDERAV,
  },
];

export const sortCards = {
  ASC: 'asc',
  DESC: 'desc',
};

export const sortCardsOptions = [
  {
    name: 'Acendente',
    _id: sortCards?.ASC,
  },
  {
    name: 'Descendente',
    _id: sortCards?.DESC,
  },
];

export const filterCardByStatus = {
  ACTIVE: 'Activa',
  INACTIVE: 'Inactiva',
  EXPIRED: 'Vendida',
  CASHED: 'Canjeada',
};

export const filterCardByStatusOptions = [
  {
    name: 'Todos',
    _id: '',
  },
  {
    name: filterCardByStatus?.ACTIVE,
    _id: filterCardByStatus?.ACTIVE,
  },
  {
    name: 'Bloqueadas',
    _id: filterCardByStatus?.INACTIVE,
  },
  // {
  //   name: filterCardByStatus?.EXPIRED,
  //   _id: filterCardByStatus?.EXPIRED,
  // },
  {
    name: filterCardByStatus?.CASHED,
    _id: filterCardByStatus?.CASHED,
  },
];

export const fleetLoadPaymentStatus = {
  PAID: 'Pago realizado',
  PENDING: 'Cuenta por cobrar',
  EXPIRED: 'Pago vencido',
};

export const monthsOptions = [
  { name: 'Enero', _id: 'January' },
  { name: 'Febrero', _id: 'February' },
  { name: 'Marzo', _id: 'March' },
  { name: 'Abril', _id: 'April' },
  { name: 'Mayo', _id: 'May' },
  { name: 'Junio', _id: 'June' },
  { name: 'Julio', _id: 'July' },
  { name: 'Agosto', _id: 'August' },
  { name: 'Septiembre', _id: 'September' },
  { name: 'Octubre', _id: 'October' },
  { name: 'Noviembre', _id: 'November' },
  { name: 'Diciembre', _id: 'December' },
];

export const ANNULLATION_REASONS = {
  DEBT_CUSTOMER_SALES: 'Cliente moroso ventas',
  DEBT_CUSTOMER_WORKSHOP: 'Cliente moroso taller',
  DEBT_CUSTOMER_OPS: 'Cliente moroso operaciones',
  INCOMPLETE_DOCUMENTATION: 'Documentación incompleta',
  SINISTER_CUSTOMER: 'Cliente siniestro',
  POLICY_NONCOMPLIANCE: 'Incumplimiento de politicas',
  CONTRACT_TYPE_CHANGE: 'Cambio de tipo de contrato',
  AFFILIATION_CRITERIA: 'No cumple criterios de afiliación',
  CUSTOMER_DECISION: 'Decisión del cliente',
  VEHICLE_SOLD: 'Vehiculo vendido',
  VEHICLE_STOLEN: 'Vehículo robado',
  PLAN_RETURN: 'Devolución del plan',
  BANNED_CUSTOMER: 'Cliente vetado',
  VEHICLE_TOTAL_LOSS: 'Pérdida total del vehículo',
  TEST: 'Prueba',
};

export const membershipAnnulationOptions =
  transformObjectToArray(ANNULLATION_REASONS);

export const enumAction = {
  createBrand: 'createBrand',
  editBrand: 'editBrand',
  createModel: 'createModel',
  editModel: 'editModel',
  createSaleChannel: 'createSaleChannel',
  listSaleChannel: 'listSaleChannel',
};

export const paymentModalTypes = {
  RENOVACION: 'Renovación',
  PENDIENTE: 'Pendiente',
};

export const affiliationType = {
  NEW: 'Nuevo',
  RENEW: 'Renovación',
  RESCUE: 'Rescate',
};

export const affiliationTypeOptions = [
  {
    _id: affiliationType?.NEW,
    name: affiliationType?.NEW,
  },
  {
    _id: affiliationType?.RENEW,
    name: affiliationType?.RENEW,
  },
  {
    _id: affiliationType?.RESCUE,
    name: affiliationType?.RESCUE,
  },
];
export const vehiclesEnum = {
  AUTOMOVIL: 'AUTOMÓVIL',
  GRUA: 'GRÚA',
  AUTOBUS: 'AUTOBUS',
  MINIBUS: 'MINIBUS',
  RUSTICO: 'RÚSTICO',
  CAMION: 'CAMIÓN',
  AMBULANCIA: 'AMBULANCIA',
  MOTO: 'MOTO',
  CAMIONETA: 'CAMIONETA',
};

export const vehicleWeightEnum = {
  CHASIS_CORTO: 'Chasis corto',
  CHASIS_LARGO: 'Chasis largo',
};

export const vehicleWeightOptions = [
  {
    name: '3 a 5.9 Toneladas',
    _id: vehicleWeightEnum?.CHASIS_CORTO,
  },
  {
    name: '6 a 10 Toneladas',
    _id: vehicleWeightEnum?.CHASIS_LARGO,
  },
];

export const initialOptions = [
  { _id: '', name: '' },
  { _id: 20, name: '20%' },
  { _id: 25, name: '25%' },
  { _id: 30, name: '30%' },
  { _id: 40, name: '40%' },
  { _id: 50, name: '50%' },
  { _id: 60, name: '60%' },
];

export const cashBakOptions = [
  { _id: '1', name: '1' },
  { _id: '2', name: '2' },
  { _id: '3', name: '3' },
];
export const planFreeYoutubeURL =
  'https://www.youtube.com/embed/q_X4NK5FS9Q?controls=1&amp;rel=0&amp;playsinline=0&amp;modestbranding=0&amp;autoplay=0&amp;enablejsapi=1&amp;origin=https%3A%2F%2Ftugruero.com&amp;widgetid=1';
export const banksOptions = [
  { _id: '0001', name: 'Banco Central de Venezuela' },
  { _id: '0102', name: 'Banco de Venezuela, S.A. Banco Universal' },
  { _id: '0104', name: 'Banco Venezolano de Crédito, S.A. Banco Universal' },
  { _id: '0105', name: 'Banco Mercantil C.A., Banco Universal' },
  { _id: '0108', name: 'Banco Provincial, S.A. Banco Universal' },
  { _id: '0114', name: 'Banco del Caribe C.A., Banco Universal' },
  { _id: '0115', name: 'Banco Exterior C.A., Banco Universal' },
  { _id: '0128', name: 'Banco Caroní C.A., Banco Universal' },
  { _id: '0134', name: 'Banesco Banco Universal, C.A.' },
  { _id: '0137', name: 'Banco Sofitasa Banco Universal, C.A .' },
  { _id: '0138', name: 'Banco Plaza, Banco universal' },
  { _id: '0146', name: 'Banco de la Gente Emprendedora C.A.' },
  { _id: '0151', name: 'Banco Fondo Común, C.A Banco Universal' },
  { _id: '0156', name: '100% Banco, Banco Comercial, C.A' },
  { _id: '0157', name: 'DelSur, Banco Universal C.A.' },
  { _id: '0163', name: 'Banco del Tesoro C.A., Banco Universal' },
  { _id: '0166', name: 'Banco Agrícola de Venezuela C.A., Banco Universal' },
  { _id: '0168', name: 'Bancrecer S.A., Banco Microfinanciero' },
  { _id: '0169', name: 'Mi Banco, Banco Microfinanciero, C.A.' },
  { _id: '0171', name: 'Banco Activo C.A., Banco Universal' },
  { _id: '0172', name: 'Bancamiga Banco Universal, C.A.' },
  {
    _id: '0173',
    name: 'Banco Internacional de Desarrollo C.A., Banco Universal',
  },
  { _id: '0174', name: 'Banplus Banco Universal, C.A.' },
  { _id: '0175', name: 'Banco Bicentenario del Pueblo, Banco Universal C.A.' },
  { _id: '0177', name: 'Banco de la Fuerza Armada Nacional Bolivariana, B.U.' },
  { _id: '0178', name: 'N58 Banco Digital, Banco Microfinanciero' },
  { _id: '0191', name: 'Banco Nacional de Crédito C.A., Banco Universal' },
  { _id: '0601', name: 'Instituto Municipal de Crédito Popular' },
];

export const pagoMovilList = [
  paymentMethods.PAGO_MÓVIL_GENERICO, //Termite pagar todos los PM
  paymentMethods.PAGO_MOVIL_BANPLUS,
  paymentMethods.PAGO_MÓVIL_BANCAMIGA,
  paymentMethods.PAGO_MÓVIL_BNC,
  paymentMethods.PAGO_MÓVIL_MERCANTIL,
];

export const bankTransferList = [
  paymentMethods.TRANSFERENCIA_GENERICO, //TPermite pagar todas las transferencias
  paymentMethods.TRANSFERENCIA_BNC,
  paymentMethods.TRANSFERENCIA_MERCANTIL,
  paymentMethods.TRANSFERENCIA_BANCAMIGA,
  paymentMethods.TRANSFERENCIA_BANPLUS,
];

export const genericPayments = [...pagoMovilList, ...bankTransferList];

export const documentTypeEnum = {
  DRIVIN_LICENCE: 'drivingLicence',
  CEDULA: 'identity',
  RIF: 'rif',
  PASSPORT: 'passport',
  CIRCULATION_CARD: 'circulation',
  ORIGIN_CERTIFICATE: 'originCertificate',
  CERTIFICATE_OR_TITLE: 'registryCertifate',
  PURCHASE_DOCUMENT: 'buyCertificate',
};

export const idDocumentTypeOptions = [
  { name: 'Cédula', _id: documentTypeEnum?.CEDULA },
  { name: 'RIF', _id: documentTypeEnum?.RIF },
  { name: 'Pasaporte', _id: documentTypeEnum?.PASSPORT },
];

export const vehicleDocumentTypeOptions = [
  { name: 'Carnet de circulación', _id: documentTypeEnum?.CIRCULATION_CARD },
  { name: 'Certificado de origen', _id: documentTypeEnum?.ORIGIN_CERTIFICATE },
  {
    name: 'Titulo de propiedad',
    _id: documentTypeEnum?.CERTIFICATE_OR_TITLE,
  },
  {
    name: 'Documento de compra/venta',
    _id: documentTypeEnum?.PURCHASE_DOCUMENT,
  },
];

export const externalvehicleDocumentTypeOptions = [
  {
    name: 'Titulo de propiedad',
    _id: documentTypeEnum?.CERTIFICATE_OR_TITLE,
  },
];

export const IGTFVALUE = 3;
export const salesAgentPhoneNumber = '+58 414 9179752';

export const vehicleUsageEnum = {
  PARTICULAR: 'Particular',
  PUBLIC: 'Público',
  AMBULANCE: 'Ambulancia',
  LOAD: 'Carga',
  TOW: 'Grúa',
};

export const vehicleUsageOptions = [
  {
    name: 'Particular',
    _id: vehicleUsageEnum?.PARTICULAR,
  },
  {
    name: 'Público',
    _id: vehicleUsageEnum?.PUBLIC,
  },
  {
    name: 'Ambulancia',
    _id: vehicleUsageEnum?.AMBULANCE,
  },
  {
    name: 'Carga',
    _id: vehicleUsageEnum?.LOAD,
  },
  {
    name: 'Grúa',
    _id: vehicleUsageEnum?.TOW,
  },
];
